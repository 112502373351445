import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import TotalEvolutionComponent from '../../components/TotalEvolutionComponent/TotalEvolutionComponent'
import Parse from 'parse';
import './WalletPage.css'
import SummaryWalletComponent from '../../components/SummaryWalletComponent/SummaryWalletComponent';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import { useWindowSize } from '../../lib/responsive';

const WalletPage = () => {
    const [wallet, setWallet] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    let windowSize = useWindowSize()

    useEffect(() => {
        async function getWallet() {
            const currentUser = Parse.User.current();
            if (currentUser) {
                const Wallet = Parse.Object.extend("Wallet");
                const query = new Parse.Query(Wallet);
                query.equalTo("objectId", currentUser.get('wallet').id);
                const subscribe = await query.subscribe();
                let wallet = {}
                subscribe.on('open', async () => {
                    wallet = await query.find()
                    setWallet(wallet[0])
                    setIsLoading(false)
                })
                subscribe.on('update', (wallet) => {
                    setWallet(wallet)
                })
                subscribe.on('close', () => {
                    console.log('subscription closed');
                });
            } else {
                history.push('/signin')
            }
        }
        getWallet()
    }, [history])

    return (
        <>
            {isLoading ? <LoadingComponent height={100} /> :
                <div className='wallet-page'>
                    <div className='wallet-container'>
                        <h1>Mon portefeuille</h1>
                        <div>
                            <TotalEvolutionComponent wallet={wallet} />
                            {
                                windowSize.width > 1000 ?
                                    <SummaryWalletComponent wallet={wallet} walletPage={true} /> : <SummaryWalletComponent wallet={wallet} />
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default WalletPage