import React from 'react'
import './ResetPasswordPage.css'
import signupImg from "../../assets/images/signup.svg"
import { useHistory } from 'react-router'
import { useForm } from 'react-hook-form'
import Parse from 'parse';
import logoNav from '../../assets/icons/logo-nav.svg'
import { toast } from 'react-toastify';

const ResetPasswordPage = () => {
    const history = useHistory();
    const { register, handleSubmit } = useForm();

    function sendResetPasswordMail(value) {
        Parse.User.requestPasswordReset(value.email).then(function () {
            console.log("Password reset request was sent successfully");
            notifyValidate()
        }).catch(function (error) {
            console.log("The login failed with error: " + error.code + " " + error.message);
        });
    }

    const onSubmit = (d) => {
        sendResetPasswordMail(d)
    }

    const notifyValidate = () => toast.success('🦄 Email de changement de mot de passe a bien etait envoyé 🦄', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });


    return (
        <div className="signup flex">
            <div className='container-img-signup' style={{ width: '50%', height: '100vh' }}>
                <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt='signup img' src={signupImg} />
            </div>
            <div style={{ height: '100vh' }} className='flex-center column form-container'>
                <div className='nav-logo-mobile'>
                    <img src={logoNav} alt='logo nav' />
                </div>
                <div className="width-signin" style={{ width: '50%', marginTop: '3rem' }}>
                    <h1 style={{width: '100%'}}>Mot de passe oublié</h1>
                    <p className='blue-text-signin' style={{ textDecoration: 'none' }} onClick={() => history.push('/')}>{`< Retour`}</p>
                </div>
                <form className="width-signin" onSubmit={handleSubmit(onSubmit)} style={{ width: '50%' }}>
                    <div>
                        <span>Email</span>
                        <div className="input-module-border" style={{ width: '100%', height: '52px' }}>
                            <input type='text' {...register("email")} className='input-module' />
                        </div>
                    </div>
                    <div className='flex-center' style={{ width: '100%', marginTop: '3rem' }}>
                        <button type='submit' className='custom-btn'>Nouveau mot de passe</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ResetPasswordPage