import React from 'react'
import './ComingSoonPage.css'
import coming from '../../assets/images/comingsoon.svg'
import logoComing from '../../assets/images/logo_coming.svg'
import Parse from 'parse';
import { useHistory } from 'react-router'
import { notifyValidate,  notifyError} from '../../lib/notification';
import { checkEmailValidation } from '../../static/Kyc';

const ComingSoonPage = () => {
    const history = useHistory();

    async function oncheckEmailValidation() {
        await checkEmailValidation() ? history.push('/') : notifyError(`Votre email n'a pas encore été validé`)
    }

    // TODO mettre un potention capcha pour eviter le spam sur les renvoies de mail

    async function resendEmail() {
        var currentUser = Parse.User.current();
        currentUser.set('email', currentUser.get('email'));
        notifyValidate('Email de validation envoyé')
        currentUser.save(null, {
            success: function (user) {
                currentUser.fetch();
            },
            error: function (user, error) {
                alert('Failed to update object, with error code: ' + error.message);
            }
        });
    }

    return (
        // absolute position to hidde the navbar
        <div className="comingpage flex-center" style={{ width: '100%', position: 'absolute' }}>
            <div style={{ width: '50%', background: 'white', height: '100vh' }} className='flex justify-center column align-center container-comingsoon'>
                <div style={{ width: '65%', position: 'relative', zIndex: '1000' }} className='flex justify-center column align-center container-comingsoon'>
                    <img src={logoComing} alt='logo coming' />
                    <h1>Merci de valider votre email puis cliquez sur suivant afin d'accéder à votre espace client.</h1>
                    <p style={{fontSize: '14px'}}>Si vous n'avez pas reçu d'email <strong style={{color: '#59c8f7', cursor: 'pointer'}} onClick={() => resendEmail()}>cliquez ici pour le renvoyer. </strong> (Pensez à vérifier votre boite de courriers indésirables)</p>
                    <button onClick={() => oncheckEmailValidation()} style={{ marginTop: '3rem' }} className='custom-btn'>Suivant</button>
                </div>
            </div>
            <div className='img-windowimg-container' style={{ width: '50%' }}>
                <img className='windowimg' src={coming} alt='coming soon page' />
            </div>
        </div>
    )
}

export default ComingSoonPage