import React, { useEffect, useState } from 'react'
import './SidebarComponent.css'
import logoNav from '../../assets/icons/logo-nav.svg'
import icondashboard from '../../assets/icons/sidebar/icon-dashboard.svg'
import icondashboardactive from '../../assets/icons/sidebar/icon-dashboard-active.svg'
// import iconbonus from '../../assets/icons/sidebar/icon-bonus.svg'
import iconactivity from '../../assets/icons/sidebar/icon-activity.svg'
import iconactivityactive from '../../assets/icons/sidebar/icon-activity-active.svg'
// import icondeposit from '../../assets/icons/sidebar/icon-deposit.svg'
// import iconnotification from '../../assets/icons/sidebar/icon-notification.svg'
// import icontext from '../../assets/icons/sidebar/icon-text.svg'
import iconwallet from '../../assets/icons/sidebar/icon-wallet.svg'
import iconmarketplace from '../../assets/icons/sidebar/icon-marketplace.svg'
import iconwalletactive from '../../assets/icons/sidebar/icon-wallet-active.svg'
import iconsupport from '../../assets/icons/sidebar/icon-support.svg'
import iconsetting from '../../assets/icons/sidebar/icon-setting.svg'
import iconsettingactive from '../../assets/icons/sidebar/icon-setting-active.svg'
import iconcard from '../../assets/icons/sidebar/icon-card.svg'
import iconbutton from '../../assets/icons/sidebar/icon-button.svg'
import Parse from 'parse';
import { NavLink, useHistory } from 'react-router-dom'
import { useLocation } from "react-router-dom";
import { useWindowSize } from '../../lib/responsive'
import { SubscriptionType } from '../../static/Subscription'

const allRoutes = [
    {
        name: 'Accueil',
        route: '/',
        icon: icondashboard,
        iconActive: icondashboardactive,
    },
    // {
    //     name: 'Dépôt',
    //     route: '/deposit',
    //     icon: icondeposit,
    // },
    // {
    //     name: `Plan d'épargne`,
    //     route: '/plan',
    //     icon: icontext,
    // },
    {
        name: 'Marketplace',
        route: '/marketplace',
        icon: iconmarketplace,
        iconActive: iconwalletactive,
    },
    {
        name: 'Portefeuille',
        route: '/wallet',
        icon: iconwallet,
        iconActive: iconwalletactive,
    },
    // {
    //     name: 'Bonus',
    //     route: '/bonus',
    //     icon: iconbonus,
    // },
    {
        name: 'Mon activité',
        route: '/activity',
        icon: iconactivity,
        iconActive: iconactivityactive,
    },
    // {
    //     name: 'Notifications',
    //     route: '/notifications',
    //     icon: iconnotification,
    // },
    {
        name: 'Réglages',
        route: '/settings',
        icon: iconsetting,
        iconActive: iconsettingactive,
    },
    {
        name: 'Support',
        route: '/support',
        icon: iconsupport,
    },
]

const SidebarComponent = () => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [display, setDisplay] = useState(true);
    let location = useLocation();
    let windowSize = useWindowSize();
    const history = useHistory();
    useEffect(() => {
        const currentUser = Parse.User.current();
        if (currentUser) {
            setUser(currentUser)
            setIsLoading(false)
            setDisplay(true);
        } else {
            setDisplay(false)
        }
    }, [location])

    if (display === false) {
        return (<></>)
    }


    return (
        <>
            {
                windowSize.width < 1000 ?
                    <div className='navbar-app'>
                        <div className='flex-center' style={{ height: '100%', justifyContent: 'space-around' }}>
                            {
                                allRoutes.map((route, index) => {
                                    return (
                                        <div key={index + route.route}>
                                            {
                                                route.name === 'Support' ?
                                                <a href="mailto:contact@laverdy.io" key={index + route.route}>
                                                    <img src={route.icon} alt='icon nav' />
                                                </a> : 
                                                <NavLink exact activeStyle={{ color: '#59C8F7', background: 'rgba(89, 200, 247, 0.2)', borderRadius: '5px' }} to={route.route} key={index + route.route}>
                                                    <img src={route.icon} alt='icon nav' />
                                                </NavLink>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    : <div className='sidebar'>
                        <div className="container-logo">
                            <img className="logo" src={logoNav} alt='logo nav' />
                        </div>
                        {isLoading ? <p>...loading</p> : <p>Bienvenue <strong>{user.get('firstname')}</strong></p>}
                        <div>
                            {
                                allRoutes.map((route, index) => {
                                    return (
                                        <div key={index + route.route}>
                                            {route.name === 'Support' ?
                                                <a className='sidebar__route' href="mailto:contact@laverdy.io" >
                                                    <img src={route.icon} alt='icon nav' />
                                                    <span>{route.name}</span>
                                                </a> :
                                                <NavLink className='sidebar__route' exact activeStyle={{ color: '#59C8F7', background: 'rgba(89, 200, 247, 0.2)', borderRadius: '5px' }} to={route.route} key={index + route.route}>
                                                    <img src={route.icon} alt='icon nav' />
                                                    <span>{route.name}</span>
                                                </NavLink>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {isLoading ? <p>...loading</p> :
                        <div onClick={() => {history.push('/settings')}} style={{cursor: 'pointer'}} className={user.get('subscription') === SubscriptionType.PRENIUM ? 'user-card-prenium' : 'user-card'}>
                            {user.get('subscription') === SubscriptionType.PRENIUM ? <p>Statut: <strong>Club Platinum</strong></p> : <p>Statut: <strong>Club Classic</strong></p>}
                            <p>ID: <strong>{user.id}</strong></p>
                            <img style={{ position: 'absolute', bottom: '10px', left: '10px' }} src={iconcard} alt='logo card laverdy' />
                            <img style={{ marginTop: '34px' }} src={iconbutton} alt='logo setting' />
                        </div>
                        }
                    </div>
            }
        </>
    )
}


export default SidebarComponent