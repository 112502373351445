import React from 'react'
import './ActivityCardComponent.css'
import { listedCoins } from '../../static/Coins'
import InvoiceGeneratorComponent from '../InvoiceGeneratorComponent/InvoiceGeneratorComponent';
import { formatDate } from '../../lib/number';
import { useWindowSize } from '../../lib/responsive';
import iconWithdraw from '../../assets/icons/icon-withdraw.svg'
import { ActivityStatus, ActivityType } from '../../static/Activity';

const ActivityCardComponent = ({ activity, activityPage, currentUser, kyc}) => {
    let windowSize = useWindowSize()

    function selectCoinImage(coinSymbole) {
        if (coinSymbole === 'EUR') {
            return iconWithdraw
        }
        const found = listedCoins.find(element => element.symbol === coinSymbole);
        return found.logo
    }

    function selectActivityType(activityType) {
        if (activityType === 'buy') {
            return 'Achat'
        } else if (activityType === 'sell') {
            return 'Vente'
        } else if (activityType === 'withdraw') {
            return 'Retrait'
        } else if (activityType === 'deposit') {
            return 'Deposit'
        } else {
            return '?'
        }
    }

    function selectActivityStatusColor(activityStatus) {
        if (activityStatus === ActivityStatus.VALIDATE) {
            return '#abf9a7'
        } else if (activityStatus === ActivityStatus.REFUSED) {
            return 'red'
        } else if (activityStatus === ActivityStatus.WAITING) {
            return 'orange'
        } else {
            return '#abf9a7'
        }
    }

    function selectActivityStatus(activityStatus) {
        if (activityStatus === ActivityStatus.VALIDATE) {
            return 'validé'
        } else if (activityStatus === ActivityStatus.REFUSED) {
            return 'refusé'
        } else if (activityStatus === ActivityStatus.WAITING) {
            return 'en attente'
        } else {
            return '?'
        }
    }

    return (
        <>
            {
                windowSize.width < 1000 ?
                    <div className='activity-card'>
                        <div className='flex align-center' style={{ justifyContent: 'space-between', position: 'relative' }}>
                            <div className="flex align-center" style={{ width: '200px' }}>
                                <img style={{ marginRight: '1rem'}} src={selectCoinImage(activity.currency)} alt='crypto icon' />
                                <div>
                                    <h1>{selectActivityType(activity.type)} {activity.currency}</h1>
                                    {
                                        activity.type === ActivityType.BUY || activity.type === ActivityType.DEPOSIT ? <h2> + {activity.amount} {activity.currency}</h2> :
                                            <h2> - {activity.amount} {activity.currency}</h2>
                                    }
                                </div>
                            </div>
                            <div style={{ textAlign: 'end' }}>
                                <p className='date'>{formatDate(activity.createdAt)}</p>
                                <p className='status'>Status: <span style={{color: selectActivityStatusColor(activity.status) }} >{selectActivityStatus(activity.status)}</span></p>
                            </div>
                        </div>
                        {activityPage &&
                            <div style={{ width: '100%', height: '100%' }}>
                                <div className='download-btn' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <InvoiceGeneratorComponent activity={activity} currentUser={currentUser} kyc={kyc}></InvoiceGeneratorComponent>
                                </div>
                            </div>
                        }
                    </div> :
                    <div className='activity-card flex align-center' style={{ justifyContent: 'space-between', position: 'relative' }}>
                        <div className="flex align-center" style={{ width: '200px' }}>
                            <img style={{ marginRight: '1rem'}} src={selectCoinImage(activity.currency)} alt='crypto icon' />
                            <div>
                                <h1>{selectActivityType(activity.type)} {activity.currency}</h1>
                                {
                                    activity.type === ActivityType.BUY || activity.type === ActivityType.DEPOSIT ? <h2> + {activity.amount} {activity.currency}</h2> :
                                        <h2> - {activity.amount} {activity.currency}</h2>
                                }
                            </div>
                        </div>
                        {activityPage &&
                            <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                    <InvoiceGeneratorComponent activity={activity} currentUser={currentUser} kyc={kyc}></InvoiceGeneratorComponent>
                                </div>
                            </div>
                        }
                        <div style={{ textAlign: 'end' }}>
                            <p className='date'>{formatDate(activity.createdAt)}</p>
                            <p className='status'>Status: <span style={{color: selectActivityStatusColor(activity.status) }}>{selectActivityStatus(activity.status)}</span></p>
                        </div>
                    </div>
            }
        </>
    )
}

export default ActivityCardComponent