import React, { useEffect, useState } from 'react'
import CoinCardComponent from '../CoinCardComponent/CoinCardComponent'
import './CoinListComponent.css'
import { listedCoins } from '../../static/Coins'
import { checkAccountValidate } from '../../static/Kyc'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'

const CoinListComponent = () => {
    const [isValidate, setIsValidate] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function checkAccount() {
            let check = await checkAccountValidate();
            setIsLoading(false)
            return check
        }
        checkAccount().then((validation) => {
            setIsValidate(validation)
        })
    }, [])

    return (
        <div className='coinlist'>
            <p className='title'>Les plus demandées</p>
            {
                isLoading ? <div style={{ height: '40%' }}><LoadingComponent></LoadingComponent></div> :
                <div>
                    {
                        listedCoins.map((coin, index) => {
                            return (
                                <div key={index}>
                                    <CoinCardComponent coin={coin} validateUser={isValidate} />
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    )
}


export default CoinListComponent