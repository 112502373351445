import Parse from 'parse';

export const KycStatus = {
    VALIDATE: 'validate',
    WAITING: 'waiting',
    FALSE: 'false',
};


async function getKyc() {
    const currentUser = Parse.User.current();
    let currentKyc = {};
    if (currentUser) {
        const Kyc = Parse.Object.extend("Kyc");
        const query = new Parse.Query(Kyc);
        query.equalTo("objectId", currentUser.get('kyc').id);
        currentKyc = query.find()
        return currentKyc
    }
}

export async function checkEmailValidation() {
    const currentUser = Parse.User.current();
    if (currentUser) {
        const User = Parse.Object.extend("User");
        const query = new Parse.Query(User);
        query.equalTo("objectId", currentUser.id);
        let user = await query.find();
        return user[0].get('emailVerified')
    }
}

export async function checkAccountValidate() {
    let checkKyc = await getKyc();
    let checkEmail = await checkEmailValidation()
    let kycValidation = checkKyc[0].get('validate')
    if (checkEmail) {
        if (kycValidation === KycStatus.FALSE || kycValidation === KycStatus.WAITING) {
            // console.log('email validation', checkEmail)
            // console.log('kycvalidation', kycValidation)
            // console.log('return', false)
            return false
        } else {
            // console.log('email validation', checkEmail)
            // console.log('kycvalidation', kycValidation)
            // console.log('return', true)
            return true
        }
    } else {
        // console.log('email validation', checkEmail)
        // console.log('kycvalidation', kycValidation)
        // console.log('return', false)
        return false
    }
}