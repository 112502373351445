import React, { useEffect, useState } from 'react'
import './SummaryWalletComponent.css'
import WalletCardComponent from '../WalletCardComponent/WalletCardComponent'
import { listedCoins } from '../../static/Coins'
import CoinWalletCard from '../CoinWalletCard/CoinWalletCard'
import { checkAccountValidate } from '../../static/Kyc'
import LoadingComponent from '../LoadingComponent/LoadingComponent'
import { useHistory } from 'react-router'

const SummaryWalletComponent = ({ wallet, walletPage }) => {
    const [isValidate, setIsValidate] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        async function checkAccount() {
            let check = await checkAccountValidate()
            setIsLoading(false)
            return check
        }
        checkAccount().then((validation) => {
            setIsValidate(validation)
        })
    }, [])

    function checkIfClientOwnCrypto() {
        let cpm = 0;
        listedCoins.forEach((coin) => {
            if (wallet.get(coin.symbol) > 0) {
                cpm += 1;
            }
        })
        return cpm > 0 ? true : false
    }

    return (
        <>
            { walletPage ?
                <div className='summary-wallet'>
                    {
                        checkIfClientOwnCrypto() ?
                            <div>
                                {
                                    listedCoins.map((coin, index) => {
                                        return (
                                            <div key={index}>
                                                <CoinWalletCard wallet={wallet} validateUser={isValidate} coin={coin}></CoinWalletCard>
                                            </div>
                                        )
                                    })
                                }
                            </div> :
                            <div className='flex-center column no-crypto'>
                                <p>Vous ne possédez pas encore de cryptomonnaie</p>
                                <button onClick={() => history.push('/marketplace')} className='custom-btn'>Acheter maintenant</button>
                            </div>
                    }
                    {/* box wallet */}
                </div> :
                <div className='summary'>
                    <h2 className='title'>Mes cryptomonnaies</h2>
                    {/* box wallet */}
                    {
                        isLoading ? <div style={{ height: '60%' }}><LoadingComponent></LoadingComponent></div> :
                            <>
                                {
                                    checkIfClientOwnCrypto() ?
                                        <div>
                                            {
                                                listedCoins.map((coin, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <WalletCardComponent wallet={wallet} coin={coin} validateUser={isValidate} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div> : <div className='flex-center column no-crypto-dashboard' style={{}}>
                                            <p>Vous ne possédez pas encore de cryptomonnaie</p>
                                            <button onClick={() => history.push('/marketplace')} className='custom-btn'>Acheter maintenant</button>
                                        </div>
                                }
                            </>
                    }
                    {/* box wallet */}
                </div>
            }
        </>
    )
}


export default SummaryWalletComponent