import React, { useEffect, useState } from 'react';
import './GraphTotalWalletComponent.css'
import { Tooltip, AreaChart, Area, YAxis, ResponsiveContainer} from 'recharts';
import Parse from 'parse';
import { formatDate } from '../../lib/number';
import moment from 'moment';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload[0]) {
        return (
            <div className="custom-tooltip" style={{ background: 'rgba(255, 255, 255, 0.9)', borderRadius: '10px' }}>
                {payload && <span className="label">Prix:<span style={{ color: '#59C8F7', marginLeft: '10px' }}>{payload[0].value} €</span></span>}
                {payload[0].payload.date && <span className="label">{`Date: ${formatDate(payload[0].payload.date)}`}</span>}
                {payload[0].payload.hours && <span className="label">{`Heure: ${payload[0].payload.hours}`}</span>}
            </div>
        );
    }
    return null;
};

const GraphTotalWalletComponent = () => {
    const [data, setData] = useState([0]);

    useEffect(() => {
        getWholeWalletValues();
    }, [])

    async function getWholeWalletValues() {
        let values = [];
        var query = new Parse.Query("WholeWallet");
        query.limit(10000);
        query.equalTo("createdBy", Parse.User.current());
        query.ascending("createdAt");
        const results = await query.find();
        for (let i = 0; i < results.length; i++) {
            const object = results[i];
            let hours = moment(object.get('createdAt')).format('hh:mm')
            values.push({ price: object.get('value'), date: object.get('createdAt'), hours: hours})
        }
        setData(values);
        if (values.length <= 0) {
            setData([{price: 0}, {price: 0}])
        }
    }

    return (
        <div style={{width: '100%', height: '100%', marginTop: '2rem'}}>
            <ResponsiveContainer width="100%" height={160}>
                <AreaChart width={700} height={200} data={data}
                    margin={{ top: 50, right: 10, bottom: 0 }}>
                    <defs>
                        <linearGradient id={'color-total'} x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor='#ABF9A7' stopOpacity={0.9} />
                            <stop offset="95%" stopColor='#59C8F7' stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <Tooltip content={<CustomTooltip />} />
                    <YAxis dataKey="price" domain={['auto', 'auto']} hide />
                    <Area type="monotone" dataKey="price" stroke='#59C8F7' fillOpacity={1} fill={`url(#color-total)`} />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}

export default GraphTotalWalletComponent