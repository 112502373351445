import React, { useEffect, useState } from 'react'
import './CardTransferModal.css'
import Modal from 'react-modal';
import closeIcon from '../../assets/images/close_icon.svg'
import '../ButtonComponent/ButtonComponent.css'
import { useWindowSize } from '../../lib/responsive';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Parse from 'parse';
import validationOrderImg from '../../assets/images/validation_order.svg'
import { notifyError } from '../../lib/notification';
import { AddNewActivity } from '../../lib/parseTools';
import { ActivityStatus, ActivityType } from '../../static/Activity';


// const customStyles = {
//     content: {
//         top: '50%',
//         left: '50%',
//         right: 'auto',
//         bottom: 'auto',
//         marginRight: '-50%',
//         borderRaduis: '20px',
//         transform: 'translate(-50%, -50%)',
//         width: '371px',
//         height: '410px',
//     }
// };

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        borderRaduis: '20px',
        transform: 'translate(-50%, -50%)',
        width: '371px',
        height: '500px',
    }
};

const customStylesMobile = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        borderRaduis: '20px',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        width: '100%'
    }
};

const CardTransferModal = ({ open, close }) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [step, setStep] = useState(0);
    const [amount, setAmount] = useState(0);
    const [isProcessing, setProcessingTo] = useState(false);
    let windowSize = useWindowSize()
    const stripe = useStripe();
    const elements = useElements();
    const currentUser = Parse.User.current();

    useEffect(() => {
        setIsOpen(open)
    }, [open])

    function closeModal() {
        setIsOpen(false)
        setStep(0)
        close()
    }

    const amountTobuy = () => {
        return (
            <>
                <div className='buy-modal' style={{ justifyContent: 'space-between' }}>
                    <h1 className="amount-title">Carte Bancaire</h1>
                </div>
                <hr />
                <div className='amount-container-card'>
                    <p style={{ marginTop: '0', marginBottom: '3rem' }}>Vous allez créditer votre compte d’un montant de</p>
                    <div className='flex-center'>
                        <input onChange={(e) => setAmount(e.target.value)} className='amount amount-input' placeholder='0' type='number' /><span className='amount'>€</span>
                    </div>
                    <button onClick={() => checkAmountToCredit()} className='custom-btn flex-center padding-btn' style={{ textAlign: 'center', fontSize: '20px', marginTop: '2rem' }}>Suivant</button>
                </div>
            </>
        )
    }

    function checkAmountToCredit() {
        if (amount < 10) {
            notifyError('Impossible de créditer en dessous de 10€')
        } else if (amount > 1000) {
            notifyError('Impossible de créditer au-dessus de de 1000€')
        } else {
            setStep(1)
        }
    }

    const handleFormSubmit = async ev => {
        ev.preventDefault();

        const billingDetails = {
            name: ev.target.name.value,
            email: currentUser.get('email'),
            address: {
                // city: ev.target.city.value,
                // line1: ev.target.address.value,
                // state: ev.target.state.value,
                postal_code: ev.target.postalcode.value
            }
        };
        setProcessingTo(true);
        const cardElement = elements.getElement("card");
        console.log('billingDetails => ', billingDetails)
        try {
            let clientSecret = await Parse.Cloud.run("paymentIntents", { amount: amount * 100 }).catch(error => console.log("error =>", error.message));
            console.log('clientSecret', clientSecret)
            const paymentMethodReq = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
                billing_details: billingDetails
            });
            if (paymentMethodReq.error) {
                notifyError(`Une erreur c'est produite, la commande n'a pas ete effectué`)
                setProcessingTo(false);
                return;
            }
            const { error } = await stripe.confirmCardPayment(clientSecret.client_secret, {
                payment_method: paymentMethodReq.paymentMethod.id
            });
            if (error) {
                console.log('error: ', error.message)
                notifyError(`Une erreur c'est produite, la commande n'a pas ete effectué`)
                setProcessingTo(false);
                return;
            }
            let wallet = currentUser.get('wallet')
            let currentValueWalletEur = wallet.get('EUR')
            let total = currentValueWalletEur + parseFloat(amount)
            wallet.set('EUR',  parseFloat(total))
            try {
                await wallet.save()
            } catch(error) {
                notifyError(`Une erreur c'est produite, la commande n'a pas ete effectué`)
                setProcessingTo(false);
            }
            let euroCoin = {
                symbol: 'EUR'
            }
            await AddNewActivity(
                ActivityStatus.VALIDATE,
                currentUser,
                ActivityType.DEPOSIT,
                euroCoin,
                parseFloat(amount),
                1,
                0,
                parseFloat(amount),
                amount.toString(),
                '',
                'stripe',
            );
            setStep(step + 1);
            return
        } catch {
            return
        }
    }

    const previewOrder = () => {
        return (
            <form onSubmit={handleFormSubmit}>
                <div className='buy-modal' style={{ justifyContent: 'space-between' }}>
                    <h1 className='order-title'>Aperçu de votre commande</h1>
                </div>
                <hr />
                <div className='order-container'>
                    <div className='flex-center'>
                        <p className='order__total-coin' style={{ backgroundImage: `linear-gradient(45deg, #59C8F7, #503BDA` }}>{amount} €</p>
                    </div>
                    <div className="flex justify-space">
                        <p className='order__description'>Frais Laverdy.io</p>
                        <p className='order__value'>0 €</p>
                    </div>
                    <div className="flex justify-space">
                        <p className='order__description'>Total</p>
                        <p className='order__value'>{amount} €</p>
                    </div>
                    <div className="flex column" style={{ marginTop: '1rem' }}>
                        <input className='input-card' name='name' type='text' placeholder='Nom figurant sur la Carte' required />
                        <input className='input-card' name='postalcode' type='text' placeholder='Code postal' required />
                    </div>
                    <div style={{ marginTop: '0.3rem' }} className='card-container'>
                        <CardElement
                            options={{
                                hidePostalCode: true,
                                style: {
                                    base: {
                                        fontSize: '16px',
                                        color: '#424770',
                                        '::placeholder': {
                                            color: '#aab7c4',
                                        },
                                    },
                                    invalid: {
                                        color: '#9e2146',
                                    },
                                },
                            }}
                        />
                    </div>
                    <div className='flex-center'>
                        <button disabled={isProcessing || !stripe} type="submit" className='custom-btn flex-center padding-btn' style={{ textAlign: 'center', fontSize: '20px', marginTop: '2rem' }}>{isProcessing ? "Processing..." : `Créditer ${amount}€`}</button>
                    </div>
                </div>
            </form>
        )
    }

    const validationOrder = () => {
        return (
            <>
                <div className='buy-modal' style={{ justifyContent: 'space-between' }}>
                    <h1 className='order-title'>Votre commande est validée</h1>
                </div>
                <hr />
                <div className='validation-order-container flex-center column'>
                    <span className="validation-text">Votre compte a été crédité de</span>
                    <p className='order__total-coin' style={{ backgroundImage: `linear-gradient(45deg,#59C8F7,#503BDA)` }}>{amount} €</p>
                    <img alt='validation order' src={validationOrderImg} />
                </div>
            </>
        )
    }

    const buyAsset = () => {
        if (step === 0) {
            return (amountTobuy())
        } else if (step === 1) {
            return (previewOrder())
        }else if (step === 2) {
            return (validationOrder())
        }
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={windowSize.width > 1000 ? customStyles : customStylesMobile}
            contentLabel="Example Modal"
            overlayClassName="Overlay"
            className="Modal"
            shouldCloseOnOverlayClick={false}
        >
            <div style={{ position: 'relative', height: '100%' }}>
                <img onClick={() => closeModal()} className="close-img-modal" src={closeIcon} alt='close icon' />
                {
                    buyAsset()
                }
            </div>

        </Modal>
    )
}

export default CardTransferModal