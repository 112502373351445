import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router';
import './CoinWalletPage.css'
import { getPriceOfCoin, selectCoin } from '../../static/Coins'
import GraphCoinComponent from '../../components/GraphCoinComponent/GraphCoinComponent';
import HistoryCoinActivityComponent from '../../components/HistoryCoinActivityComponent/HistoryCoinActivityComponent';
import Parse from 'parse';
import { computeCoinToEur } from '../../lib/number';
import BuySellAssetComponent from '../../components/BuySellAssetComponent/BuySellAssetComponent';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import { useWindowSize } from '../../lib/responsive';
import BuyAssetModal from '../../components/BuyAssetModal/BuyAssetModal';
import SellAssetModal from '../../components/SellAssetModal/SellAssetModal';

const CoinWalletPage = () => {
    const [coin, setCoin] = useState(null);
    const [coinprice, setCoinPrice] = useState(null);
    const [wallet, setWallet] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    let { coinsymbol } = useParams();
    const history = useHistory();
    let windowSize = useWindowSize();


    useEffect(() => {
        async function getWallet() {
            const currentUser = Parse.User.current();
            if (currentUser) {
                const Wallet = Parse.Object.extend("Wallet");
                const query = new Parse.Query(Wallet);
                query.equalTo("objectId", currentUser.get('wallet').id);
                const subscribe = await query.subscribe();
                let wallet = {}
                subscribe.on('open', async () => {
                    wallet = await query.find()
                    setWallet(wallet[0])
                    setIsLoading(false)
                })
                subscribe.on('update', (wallet) => {
                    setWallet(wallet)
                })
                subscribe.on('close', () => {
                    console.log('subscription closed');
                });
            } else {
                history.push('/signin')
            }
        }

        async function updateCoin() {
            let coin = selectCoin(coinsymbol);
            setCoin(coin);
            let coinpricevalue = await getPriceOfCoin(coin);
            setCoinPrice(coinpricevalue);
        }
        updateCoin()
        getWallet();
    }, [coinsymbol, history])

    const [openBuy, setOpenBuy] = useState(false);
    const [openSell, setOpenSell] = useState(false);
    function closeModal() {
        setOpenBuy(false)
        setOpenSell(false)
    }

    return (
        <>

            {
                isLoading ? <LoadingComponent height={100} /> :
                    < div className='coin-page' >
                        <h1>Mon portefeuille - {coin.symbol}</h1>
                        <div className="flex justify-space">

                            {/* coin-wallet-graph */}

                            <div className='coin-wallet-graph'>
                                <div className='flex justify-space'>
                                    <div className='flex-center'>
                                        <img style={{ marginRight: '1rem' }} src={coin.logo} alt='coin name' />
                                        <div>
                                            <p style={{ color: `${coin.color.end}` }} className='title-coin'>{coin.symbol}</p>
                                            <p className='coinname'>{coin.name}</p>
                                        </div>
                                    </div>
                                    {coinprice.price && <h2 style={{ color: `${coin.color.end}` }}>{coinprice.price} €</h2>}
                                </div>
                                <GraphCoinComponent coin={coin} width={800} height={windowSize.width > 1000 ? 350 : 200} showSettings={true} />
                            </div>

                            {/* coin-wallet-graph */}

                            {/* BuySellComponent */}
                            {windowSize.width > 1000 && <BuySellAssetComponent coin={coin} />}
                            {/* BuySellComponent */}
                        </div>
                        <div>
                            <div className='flex justify-space solde-card-wallet align-center'>
                                <div className='flex-center'>
                                    <img style={{ marginRight: '1rem' }} src={coin.logo} alt='coin name' />
                                    <div>
                                        <p style={{ color: `${coin.color.end}` }} className='title-coin'>{coin.symbol}</p>
                                        <p className='coinname'>{coin.name}</p>
                                    </div>
                                </div>
                                <p className='solde-wallet'>{wallet.get(coin.symbol)} {coin.symbol} ≈ {computeCoinToEur(coinprice.price, wallet.get(coin.symbol))}€</p>
                            </div>
                        </div>
                        {windowSize.width < 1000 &&
                            <>
                                <div className='flex solde-card-wallet align-center responsivewalletbutton' style={{ justifyContent: 'space-around' }}>
                                    <button onClick={() => setOpenBuy(true)} className='buy-button' style={{ background: `linear-gradient(270deg, ${coin.color.start} 0%, ${coin.color.end} 100%),  ${coin.color.start}` }}>Acheter</button>
                                    <button onClick={() => setOpenSell(true)} className='sell-button' style={{ background: `linear-gradient(270deg, ${coin.color.start} 0%, ${coin.color.end} 100%),  ${coin.color.start}` }}>Vendre</button>
                                </div>
                                <BuyAssetModal open={openBuy} coin={coin} close={closeModal}></BuyAssetModal>
                                <SellAssetModal open={openSell} coin={coin} close={closeModal}></SellAssetModal>
                            </>
                        }
                        <div className='history-coin-container'>
                            <HistoryCoinActivityComponent coinsymbol={coin.symbol} eurValueWallet={computeCoinToEur(coinprice.price, wallet.get(coin.symbol))} />
                        </div>
                    </div >
            }
        </>
    )
}

export default CoinWalletPage