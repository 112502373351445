import React, { useEffect } from 'react'
import './VerificationLevelComponent.css'
import iconValidate from '../../assets/icons/icon-validate.svg'
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import ModalKycComponent from '../ModalKycComponent/ModalKycComponent'
import { notifyValidate } from '../../lib/notification'
import Parse from 'parse';

const VerificationLevelComponent = () => {
    const [kycStatus, setKycStatus] = React.useState('');
    const [openModal, setOpenModal] = React.useState('');

    useEffect(() => {

        async function getKyc() {
            const currentUser = Parse.User.current();
            if (currentUser) {
                const Kyc = Parse.Object.extend("Kyc");
                const query = new Parse.Query(Kyc);
                query.equalTo("objectId", currentUser.get('kyc').id);
                const subscribe = await query.subscribe();
                let kyc = {}
                subscribe.on('open', async () => {
                    kyc = await query.find()
                    setKycStatus(kyc[0].get('validate'));
                    // setIsLoading(false)
                })
                subscribe.on('update', (kyc) => {
                    setKycStatus(kyc.get('validate'));
                })
                subscribe.on('close', () => {
                    console.log('subscription closed');
                });
            }
        }
        getKyc();
    }, [])

    function selectKycButton() {
        if (kycStatus === 'waiting') {
            return(<button className='btn-kyc-waiting'>En attente de validation</button>)
        } else if (kycStatus === 'false') {
            return (
                <>
                    <ButtonComponent onClickCustom={() => setOpenModal(true)} text={'Commencer'}></ButtonComponent>
                    <ModalKycComponent openFromVerification={openModal}></ModalKycComponent>
                </>
            )
        } else if (kycStatus === 'validate') {
            return (<button className='btn-kyc-validate'>Validé</button>)
        }
    }

    return (
        <div className="flex verification-container">
            <div className="verification-lvl-card">
                <h1>Niveau 1</h1>
                <p>Investissez jusqu'à 10 000€</p>
                <hr />
                <div style={{ height: "20vh" }} className='flex column justify-center'>
                    <div className="flex align-center">
                        <img style={{ marginRight: '1rem' }} src={iconValidate} alt='validation icon' />
                        <span className="verif-step">Selfie avec document d’identité</span>
                    </div>
                    <div className="flex align-center" style={{ marginTop: '0.7rem' }}>
                        <img style={{ marginRight: '1rem' }} src={iconValidate} alt='validation icon' />
                        <span className="verif-step">Pièce d’identité valide</span>
                    </div>
                    <div className="flex align-center" style={{ marginTop: '0.7rem' }}>
                        <img style={{ marginRight: '1rem' }} src={iconValidate} alt='validation icon' />
                        <span className="verif-step">Justificatif de domicile</span>
                    </div>
                </div>
                <div className="flex justify-space">
                    <span>Plafonds d'achat</span>
                    <span><strong>10 000€</strong></span>
                </div>
                <div className="flex justify-space" style={{marginTop: '0.5rem'}}>
                    <span>Plafonds d'achat CB</span>
                    <span><strong>1500€</strong></span>
                </div>
                <div style={{marginTop: '3rem'}} className='flex justify-center'>
                    {
                        selectKycButton()
                    }
                </div>
            </div>

            <div className="verification-lvl-card">
                <h1>Niveau 2</h1>
                <p>Plafond Personnalisé</p>
                <hr />
                <div style={{ height: "20vh" }} className='flex column justify-center'>
                    <div className="flex align-center">
                        <span style={{ marginRight: '1rem' }}>❌</span>
                        <span className="verif-step">Questionnaire d'achat</span>
                    </div>
                    <div className="flex align-center">
                        <span style={{ marginRight: '1rem' }}>❌</span>
                        <span className="verif-step">Justificatif de revenus</span>
                    </div>
                </div>
                <div className="flex justify-space">
                    <span>Plafonds d'achat</span>
                    <span><strong>Personnalisés</strong></span>
                </div>
                <div className="flex justify-space" style={{marginTop: '0.5rem'}}>
                    <span>Plafonds d'achat CB</span>
                    <span><strong>5000€</strong></span>
                </div>
                <div style={{marginTop: '3rem'}} className='flex justify-center'>
                    <ButtonComponent onClickCustom={() => notifyValidate(`Merci de bien vouloir nous contacter à l'email suivant: contact@laverdy.io`)} text={'Passer au niveau 2'}></ButtonComponent>
                </div>
            </div>
        </div>
    )
}

export default VerificationLevelComponent