import React, { useEffect, useState } from 'react'
import './FiatBagComponent.css'
import Parse from 'parse';
import DepositFundsModal from '../DepositFundsModal/DepositFundsModal'
import WithdrawModal from '../WithdrawModal/WithdrawModal';
import { notifyError } from '../../lib/notification';
import { checkAccountValidate } from '../../static/Kyc'

const FiatBagComponent = () => {
    const [wallet, setWallet] = useState({});
    const [openDeposit, setOpenDeposit] = useState(false);
    const [openWithdraw, setOpenWithdraw] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isValidate, setIsValidate] = useState(false);
    useEffect(() => {
        async function checkAccount() {
            let check = await checkAccountValidate();
            setIsValidate(check);
        }
        getWallet();
        checkAccount();
    }, [])

    async function getWallet() {
        const currentUser = Parse.User.current();
        if (currentUser) {
            const Wallet = Parse.Object.extend("Wallet");
            const query = new Parse.Query(Wallet);
            query.equalTo("objectId", currentUser.get('wallet').id);
            const subscribe = await query.subscribe();
            let wallet = {}
            subscribe.on('open', async () => {
                wallet = await query.find()
                setWallet(wallet[0])
                setIsLoading(false)
            })
            subscribe.on('update', (wallet) => {
                setWallet(wallet)
            })
            subscribe.on('close', () => {
                console.log('subscription closed');
            });
        } else {
            console.log('not connected')
        }
    }

    return (
        <>
            <div className='fiat-container'>
                <div className='flex fiat-responsive-container' style={{ height: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <p>Mon solde</p>
                    {isLoading ? <h1>0 €</h1> : <h1>{wallet.get('EUR')} €</h1>}
                    {
                        isValidate ?
                            <div className='flex align-center' style={{ justifyContent: 'space-around' }}>
                                <button onClick={() => setOpenDeposit(true)} className='button-fiat'>Dépot</button>
                                <button onClick={() => setOpenWithdraw(true)} className='button-fiat'>Retrait</button>
                            </div> : <div className='flex align-center' style={{ justifyContent: 'space-around' }}>
                                <button style={{ background: '#666666' }} onClick={() => notifyError('Vous ne pouvez pas effectuer cette action, votre compte est en cours de vérification.')} className='button-fiat'>Dépot</button>
                                <button  style={{ background: '#666666' }}onClick={() => notifyError('Vous ne pouvez pas effectuer cette action, votre compte est en cours de vérification.')} className='button-fiat'>Retrait</button>
                            </div>
                    }
                </div>
            </div>
            <DepositFundsModal open={openDeposit} close={() => setOpenDeposit(false)}></DepositFundsModal>
            <WithdrawModal open={openWithdraw} close={() => setOpenWithdraw(false)}></WithdrawModal>
        </>
    )
}

export default FiatBagComponent