import './App.css';
import { BrowserRouter, BrowserRouter as Router, Route, Switch } from 'react-router-dom'; // eslint-disable-line no-unused-vars
import SignupPage from './pages/SignupPage/SignupPage';
import SigninPage from './pages/SigninPage/SigninPage';
import ComingSoonPage from './pages/ComingSoonPage/ComingSoonPage';
import Parse from 'parse';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import Modal from 'react-modal';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import WalletPage from './pages/WalletPage/WalletPage';
import CoinWalletPage from './pages/CoinWalletPage/CoinWalletPage';
import SidebarComponent from './components/SidebarComponent/SidebarComponent';
import ActivityPage from './pages/ActivityPage/ActivityPage';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage';
import { useWindowSize } from './lib/responsive';
import PolicyPage from './pages/PolicyPage/PolicyPage';
import CGVpage from './pages/CGVpage/CGVpage';
import MarketplacePage from './pages/MarketplacePage/MarketplacePage';
import TestStripe from './pages/TestStripe/TestStripePage';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

function App() {
  Parse.initialize(process.env.REACT_APP_PARSE_APP_ID, process.env.REACT_APP_PARSE_APP_JS_ID);//PASTE HERE YOUR Back4App APPLICATION ID AND YOUR JavaScript KEY
  // Parse.initialize(process.env.REACT_APP_PARSE_APP_TEST_ID, process.env.REACT_APP_PARSE_APP_JS_TEST_ID); //PASTE HERE YOUR Back4App APPLICATION ID AND YOUR JavaScript KEY
  Parse.serverURL = 'https://livelaverdyio.b4a.io';
  // Parse.serverURL = 'https://testlaverdyio.b4a.io';
  // Parse.serverURL = "https://parseapi.back4app.com/";
  Modal.setAppElement('#root');
  let windowSize = useWindowSize();
  const stripePromise = loadStripe('pk_live_51H2yLNC0IdRB2N8RM3OVGawGkikeF4xJRbA0jNliV2m6uLg5UdRZyXg1xyUucfQ1q5HDKpoqHiNzaJYRZy5VZ0ZM006K75YpeC');
  return (
    <div>
      <Elements stripe={stripePromise}>
        <Router>
          <div style={{ display: 'flex', width: '100%', height: '100%', position: 'relative', paddingBottom: windowSize.width < 1000 && "5rem" }}>
            <SidebarComponent />
            <Switch>
              <Route exact path='/' component={DashboardPage} />
              <Route exact path='/signup' component={SignupPage} />
              <Route exact path='/test' component={TestStripe} />
              <Route exact path='/signin' component={SigninPage} />
              <Route exact path='/policy' component={PolicyPage} />
              <Route exact path='/CGV' component={CGVpage} />
              <Route exact path='/home' component={ComingSoonPage} />
              <Route exact path='/wallet' component={WalletPage} />
              <Route exact path='/wallet/:coinsymbol' component={CoinWalletPage} />
              <Route exact path='/activity' component={ActivityPage} />
              <Route exact path='/marketplace' component={MarketplacePage} />
              <Route exact path='/settings' component={SettingsPage} />
              <Route exact path='/password' component={ResetPasswordPage} />
              <Route exact path='/emailvalidation' component={ComingSoonPage} />
            </Switch>
          </div>
        </Router>
        <ToastContainer />
      </Elements>
    </div>
  );
}

export default App;
