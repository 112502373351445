import React, { useEffect, useState } from 'react'
import './CoinWalletCard.css'
import { useHistory } from 'react-router';
import { roundDecimal, computeCoinToEur } from '../../lib/number'
import GraphCoinComponent from '../GraphCoinComponent/GraphCoinComponent'
import { notifyError } from '../../lib/notification';

const CoinWalletCard = ({ coin, wallet, validateUser }) => {
    const [pair, setPair] = useState({
        price: 0
    });

    const history = useHistory();

    useEffect(() => {
        let currentCoin = coin
        function extractTickers(json) {
            var tickers = Object.keys(json.result).map(key => createTicker(key, json.result[key]))
            tickers.sort((a, b) => b.last24TradeCount - a.last24TradeCount)
            return tickers
        }

        function createTicker(key, value) {
            return {
                assetPair: assetPair(key),
                price: roundDecimal(value.c[0], 2)
            }
        }

        function assetPair(s) {
            return s.replace(/[XZ]([A-Z]{3})[XZ]([A-Z]{3})/g, '$1/$2')
        }

        fetch(`https://api.kraken.com/0/public/Ticker?pair=${currentCoin.krakenId}EUR`)
            .then(response => response.json())
            .then(data => {
                setPair(extractTickers(data))
            });
    }, [coin])

    return (
        <>
            { wallet.get(coin.symbol) > 0 &&
                <div className='coin-wallet'>
                    <div className='flex' style={{ position: 'relative' }}>
                        {
                            pair[0] && <p className='current-asset-price'>{pair[0].price}€</p>
                        }
                        <div style={{ width: '10%' }}>
                            <div className='flex'>
                                <img onClick={() => { history.push(`/wallet/${coin.symbol}`) }} style={{ marginRight: '1rem', cursor: 'pointer' }} src={coin.logo} alt='eth icon' />
                                <div>
                                    <h1 onClick={() => { history.push(`/wallet/${coin.symbol}`) }} style={{ color: coin.color.end, cursor: 'pointer' }}>{coin.symbol}</h1>
                                    <h2 onClick={() => { history.push(`/wallet/${coin.symbol}`) }} style={{ cursor: 'pointer' }}>{coin.name}</h2>
                                </div>
                            </div>
                            <div className='align-price-wallet'>
                                {
                                    pair[0] && <p className='value' style={{ color: coin.color.end }}>{computeCoinToEur(pair[0].price, wallet.get(coin.symbol))} €</p>
                                }
                                <p className='nbrofcurrency'>{wallet.get(coin.symbol)} {coin.symbol}</p>
                            </div>
                        </div>
                        <div className="flex justify-center" style={{ marginTop: '1rem', width: '70%', overflow: 'hidden' }}>
                            {/* <GraphTotalWalletComponent coin={listedCoins[0]} ></GraphTotalWalletComponent> */}
                            <GraphCoinComponent coin={coin} width={1000} height={150} />
                        </div>
                        <div className='flex-center' style={{ width: '20%' }}>
                            {
                                !validateUser ? <button onClick={() => { notifyError('Vous ne pouvez pas effectuer cette action, votre compte est en cours de vérification.') }} className='button-wallet-gestion' style={{ background: '#666666' }}>Gérer mon portefeuille</button> :
                                    <button onClick={() => { history.push(`/wallet/${coin.symbol}`) }} className='button-wallet-gestion' style={{ background: `linear-gradient(270deg, ${coin.color.start} 0%, ${coin.color.end} 100%),  ${coin.color.start}` }}>Gérer mon portefeuille</button>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default CoinWalletCard