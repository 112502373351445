export const ActivityType = {
    BUY: 'buy',
    SELL: 'sell',
    DEPOSIT: 'deposit',
    WITHDRAW: 'withdraw'
};

export const ActivityStatus = {
    VALIDATE: 'validate',
    WAITING: 'waiting',
    REFUSED: 'refused'
};