import React, { useEffect } from 'react'
import './SubscriptionPlanComponent.css'
import iconValidate from '../../assets/icons/icon-validate.svg'
import { notifyError } from '../../lib/notification';
import Parse from 'parse';
import { SubscriptionType } from '../../static/Subscription';
import LoadingComponent from '../LoadingComponent/LoadingComponent';

const SubscriptionPlanComponent = () => {
    const [currentUser, setCurrentUser] = React.useState({});
    const [stripe, setStripe] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        async function getUserAndStripe() {
            const User = new Parse.User();
            const query = new Parse.Query(User);
            let user = await query.get(Parse.User.current().id).catch(error => {
                notifyError(`Une erreur s'est produite`)
                console.error('Error while fetching user', error);
            })
            setCurrentUser(user)
            const Stripe = Parse.Object.extend('Stripe');
            const queryStripe = new Parse.Query(Stripe);
            queryStripe.equalTo('createdBy', user);
            let currentStripe = await queryStripe.first().catch(err => {
                notifyError(`Une erreur s'est produite`)
                console.log('error', err);
            });
            setStripe(currentStripe);
            setIsLoading(false)
        }
        
        getUserAndStripe();
    }, [])

    async function testStripe() {
        let currentUser = Parse.User.current()
        if (currentUser.get('stripe')) {
            await currentUser.get('stripe').destroy()
            // console.log('on destroy')
        }
        let user = {
            id: currentUser.id,
            firstname: currentUser.get('firstname'),
            lastname: currentUser.get('lastname'),
            email: currentUser.get('email'),
        }
        let session = await Parse.Cloud.run("createCheckoutSession", { priceId: 'price_1JDqs0C0IdRB2N8RxryQEsp5', user: user }).catch(error => console.log("error =>", error));
        window.location.href = session.session.url;
    }

    async function onClickCustomerPortal() {
        let customerPortal = await Parse.Cloud.run("customerPortal", { customerId: stripe.get('customerId') }).catch(error => console.log("error =>", error));
        window.location.href = customerPortal.result.url;
        // console.log('click sur le customer portal', customerPortal)
    }

    return (
        <>
            {
                isLoading ? <LoadingComponent /> :
                    <div>
                        <h1 className="title-verification">Rejoindre le Club Platinum</h1>
                        <div className="flex justify-space container-sub" style={{ padding: '2rem 3rem' }}>
                            <div className='subscription' style={{ backgroundColor: '#503BDA' }}>
                                <h2>Club Classic</h2>
                                <p>Notre solution gratuite pour débuter</p>
                                <div style={{ padding: '1rem', minHeight: '50vh' }}>
                                    <div className="flex align-center" style={{ marginTop: '1rem' }}>
                                        <img style={{ marginRight: '1rem' }} src={iconValidate} alt='validation icon' />
                                        <span className="verif-step">Achetez et vendez facilement</span>
                                    </div>
                                    <div className="flex align-center" style={{ marginTop: '1rem' }}>
                                        <img style={{ marginRight: '1rem' }} src={iconValidate} alt='validation icon' />
                                        <span className="verif-step">Service client en ligne par mail</span>
                                    </div>
                                    <div className="flex align-center" style={{ marginTop: '1rem' }}>
                                        <img style={{ marginRight: '1rem' }} src={iconValidate} alt='validation icon' />
                                        <span className="verif-step">Frais de transaction fixes à 5%</span>
                                    </div>
                                    <div className="flex align-center" style={{ marginTop: '1rem' }}>
                                        <span style={{ marginRight: '1rem' }}>❌</span>
                                        <span className="verif-step">Service téléphonique 1H par mois avec un conseiller</span>
                                    </div>
                                    <div className="flex align-center" style={{ marginTop: '1rem' }}>
                                        <span style={{ marginRight: '1rem' }}>❌</span>
                                        <span className="verif-step">2 transferts de vos cryptomonnaies vers notre service de coffre-fort ultra sécurisé par mois inclus</span>
                                    </div>
                                    <div className="flex align-center" style={{ marginTop: '1rem' }}>
                                        <span style={{ marginRight: '1rem' }}>❌</span>
                                        <span className="verif-step">Accès à plus de 30 crypto-actifs sur notre Marketplace</span>
                                    </div>
                                    <div className="flex align-center" style={{ marginTop: '1rem' }}>
                                        <span style={{ marginRight: '1rem' }}>❌</span>
                                        <span className="verif-step">Votre déclaration d'impôt en 1 clic</span>
                                    </div>
                                    <div className="flex align-center" style={{ marginTop: '1rem' }}>
                                        <span style={{ marginRight: '1rem' }}>❌</span>
                                        <span className="verif-step">Mail d'information hebdomadaire, nouveaux projets, étude des marchés et actualité</span>
                                    </div>
                                    <div className="flex align-center" style={{ marginTop: '1rem' }}>
                                        <span style={{ marginRight: '1rem' }}>❌</span>
                                        <span className="verif-step">Mail d'alerte de variation des cours</span>
                                    </div>
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <h3>Gratuit</h3>
                                </div>
                                <div className='flex-center'>
                                {currentUser.get('subscription') === SubscriptionType.PRENIUM ? <button  style={{background: 'grey', cursor: 'initial'}} className='subscription-btn'>Free plan</button> : <button className='current-subscription-btn'>Mon statut actuel</button>}
                                </div>
                            </div>

                            <div className='subscription' style={{ backgroundColor: '#4C4C4D' }}>
                                <h2>Club Platinum</h2>
                                <p>Pour investir efficacement</p>
                                <div style={{ padding: '1rem', minHeight: '50vh' }}>
                                    <div className="flex align-center" style={{ marginTop: '1rem' }}>
                                        <img style={{ marginRight: '1rem' }} src={iconValidate} alt='validation icon' />
                                        <span className="verif-step">Achetez et vendez en ligne rapidement</span>
                                    </div>
                                    <div className="flex align-center" style={{ marginTop: '1rem' }}>
                                        <img style={{ marginRight: '1rem' }} src={iconValidate} alt='validation icon' />
                                        <span className="verif-step">Service client en ligne par mail</span>
                                    </div>
                                    <div className="flex align-center" style={{ marginTop: '1rem' }}>
                                        <img style={{ marginRight: '1rem' }} src={iconValidate} alt='validation icon' />
                                        <span className="verif-step">Frais de transaction fixes à 2.5%</span>
                                    </div>
                                    <div className="flex align-center" style={{ marginTop: '1rem' }}>
                                        <img style={{ marginRight: '1rem' }} src={iconValidate} alt='validation icon' />
                                        <span className="verif-step">Service téléphonique 1H par mois avec un conseiller</span>
                                    </div>
                                    <div className="flex align-center" style={{ marginTop: '1rem' }}>
                                        <img style={{ marginRight: '1rem' }} src={iconValidate} alt='validation icon' />
                                        <span className="verif-step">2 transferts de vos cryptomonnaies vers notre service de coffre-fort ultra sécurisé par mois inclus</span>
                                    </div>
                                    <div className="flex align-center" style={{ marginTop: '1rem' }}>
                                        <img style={{ marginRight: '1rem' }} src={iconValidate} alt='validation icon' />
                                        <span className="verif-step">Accès à plus de 30 crypto-actifs sur notre Marketplace</span>
                                    </div>
                                    <div className="flex align-center" style={{ marginTop: '1rem' }}>
                                        <img style={{ marginRight: '1rem' }} src={iconValidate} alt='validation icon' />
                                        <span className="verif-step">Votre déclaration d'impôt en 1 clic</span>
                                    </div>
                                    <div className="flex align-center" style={{ marginTop: '1rem' }}>
                                        <img style={{ marginRight: '1rem' }} src={iconValidate} alt='validation icon' />
                                        <span className="verif-step">Mail d'information hebdomadaire, nouveaux projets, étude des marchés et actualité</span>
                                    </div>
                                    <div className="flex align-center" style={{ marginTop: '1rem' }}>
                                        <img style={{ marginRight: '1rem' }} src={iconValidate} alt='validation icon' />
                                        <span className="verif-step">Mail d'alerte de variation des cours</span>
                                    </div>
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <h3>à partir de 27€/mois sans engagement</h3>
                                </div>

                                <div className='flex-center'>
                                    {/* <button onClick={() => { notifyError(`Nos services d'abonnement ne sont pas encore disponibles.`)}} className='subscription-btn'>Devenir Platinum</button> */}
                                    {currentUser.get('subscription') === SubscriptionType.PRENIUM ? <button onClick={() => onClickCustomerPortal()} className='subscription-btn'>Gestion abbonement</button> : <button onClick={() => testStripe()} className='subscription-btn'>Devenir Platinum</button>}
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default SubscriptionPlanComponent