import React from 'react'
import MarketplaceListComponent from '../../components/MarketplaceListComponent/MarketplaceListComponent'
import './MarketplacePage.css'

const MarketplacePage = () => {
    return (
        <div className='marketplace-container' style={{width: '100%'}}>
            <h1 style={{marginTop: '0'}}>Marketplace</h1>
            <div>
                <MarketplaceListComponent/>
            </div>
        </div>
    )
}

export default MarketplacePage