import React, { useState } from 'react'
import BuyAssetComponent from '../BuyAssetComponent/BuyAssetComponent';
import SellAssetComponent from '../SellAssetComponent/SellAssetComponent';
import './BuySellAssetComponent.css'

const BuySellAssetComponent = ({coin}) => {

    const [selection, setSelection] = useState('buy');
    const [currentInterval, setCurrentInterval] = useState(null);


    function changeSelectionAction(action) {
        setSelection(action)
        if (currentInterval) {
            clearInterval(currentInterval);
            setCurrentInterval(null)
        }
    }

    return (
        <div className='buysell-component'>
            <div className='flex'>
                <div onClick={() => changeSelectionAction('buy')} className={selection === 'buy' ? 'buy-sell-selector-selected' : 'buy-sell-selector'}>
                    <p>Acheter du {coin.name}</p>
                </div>
                <div onClick={() => changeSelectionAction('sell')} className={selection === 'sell' ? 'buy-sell-selector-selected' : 'buy-sell-selector'}>
                    <p>Vendre du {coin.name}</p>
                </div>
            </div>
            {
                selection === 'buy' ? <BuyAssetComponent coin={coin} setCurrentInterval={setCurrentInterval} /> : <SellAssetComponent coin={coin} setCurrentInterval={setCurrentInterval} />
            }
        </div>
    )
}

export default BuySellAssetComponent