import React, { useEffect, useState } from 'react'
import './BuyAssetModal.css'
import Modal from 'react-modal';
import closeIcon from '../../assets/images/close_icon.svg'
import validationOrderImg from '../../assets/images/validation_order.svg'
import '../ButtonComponent/ButtonComponent.css'
import { calculateFee, formatDate, roundDecimal } from '../../lib/number'
import Parse from 'parse';
import { ActivityType, ActivityStatus } from '../../static/Activity'
import { AddNewActivity } from '../../lib/parseTools'
import { getPriceOfCoin } from '../../static/Coins';
import { notifyError } from '../../lib/notification';
import { useWindowSize } from '../../lib/responsive';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        borderRaduis: '20px',
        transform: 'translate(-50%, -50%)',
        width: '371px',
        height: '389px',
    }
};

const customStylesMobile = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        borderRaduis: '20px',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        width: '100%'
    }
};

const BuyAssetModal = ({ open, coin, close }) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [step, setStep] = useState(0);
    const [pair, setPair] = useState({
        price: 0
    });
    const [amount, setAmount] = useState(0);
    const [wallet, setWallet] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(true);
    const [refresh, setRefresh] = useState(null);
    const [minimalBuy, setMinimalBuy] = useState(25);
    let windowSize = useWindowSize()

    useEffect(() => {
        async function getPriceCoin() {
            let coinpricevalue = await getPriceOfCoin(coin);
            setPair(coinpricevalue);
            setMinimalBuy(roundDecimal(coin.orderMin * coinpricevalue.price, 2) + 1)
        }
        getWallet()
        setIsOpen(open)
        getPriceCoin()
    }, [open, coin])


    useEffect(() => {
        if (step === 1) {
            let interval = setInterval(async () => {
                let coinpricevalue = await getPriceOfCoin(coin);
                setPair(coinpricevalue);
            }, 1000);
            setRefresh(interval);
        }
    }, [step, coin])

    async function getWallet() {
        const currentUser = Parse.User.current();
        if (currentUser) {
            setCurrentUser(currentUser);
            const Wallet = Parse.Object.extend("Wallet");
            const query = new Parse.Query(Wallet);
            query.equalTo("objectId", currentUser.get('wallet').id);
            const results = await query.find();
            setWallet(results[0])
            setIsLoading(false)
        } else {
            console.log('pas connecter')
        }
    }

    function closeModal() {
        clearInterval(refresh);
        setIsOpen(false)
        setStep(0)
        close()
    }

    function amountToWantbuy() {
        const eurWallet = wallet.get('EUR')
        if (amount <= 0) {
            notifyError('❌ Achat insuffisant ❌');
            return 
        } if (amount > eurWallet) {
            notifyError('❌ Fond insuffisant ❌');
            return
        } if (amount < minimalBuy) {
            notifyError('❌ Achat insuffisant ❌');
            return
        }
        else {
            setStep(step + 1)
            finishInterval()
        }
    }

    function finishInterval() {
        if (step === 2) {
            clearInterval(refresh);
        } else { 
            clearInterval(refresh);
        }
    }

    async function subtractToWallet() {
        const eurWallet = wallet.get('EUR')
        let newAmountWallet = eurWallet - amount;
        let fee = calculateFee(amount, Parse.User.current().get('subscription'));
        let buy = amount - fee;
        let coinAmount = roundDecimal(buy / pair.price, 7)

        /////
        let transaction = await Parse.Cloud.run('KrakenBuy', {messages: 'salut les potes', pair: coin.krakenPair, volume: coinAmount})
        .catch(error => {
            notifyError('une erreur est survenue !')
            closeModal()
            console.log('Error update wallet: ', error)
            throw new Error('Une erreur est survenue')
        });
        /////

        wallet.set('EUR', roundDecimal(newAmountWallet, 2))
        wallet.set(coin.symbol, roundDecimal(coinAmount + wallet.get(coin.symbol), 7))
        await wallet.save().catch(error => console.log('Error update wallet: ', error));
        finishInterval()
        setStep(step + 1)
        let eurAmount = buy;
        let totalAmount = amount; 
        let activity = await AddNewActivity(
            ActivityStatus.VALIDATE,
            currentUser,
            ActivityType.BUY,
            coin,
            coinAmount,
            pair.price,
            fee,
            eurAmount,
            totalAmount,
            transaction.buy.result.txid[0],
        );
        Parse.Cloud.run("emailTransaction", {
            activityId: activity.id,
            currency: activity.get('currency'),
            payment: 'SOLDE',
            date: formatDate(activity.get('createdAt')),
            totalAmount: activity.get('totalAmount'),
            type: activity.get('type'),
            fee: fee,
            eurAmount: activity.get('eurAmount'),
            email: currentUser.get('email')
        }).then(test => 
            console.log('Email send')
        ).catch(error => console.log("error =>", error));
    }

    const amountTobuy = () => {
        return (
            <>
                <div className='buy-modal' style={{ justifyContent: 'space-between'}}>
                    <h1 className="amount-title">Acheter du {coin.name}</h1>
                    <div className='flex-center'>
                        <img alt='logo coin' style={{ paddingRight: '1rem' }} src={coin.logo} />
                        <div>
                            <h2 style={{ color: `${coin.color.end}` }}>{coin.symbol}</h2>
                            <h3>{coin.name}</h3>
                        </div>
                    </div>
                </div>
                <hr />
                <div className='amount-container'>
                    <div className='flex-center'>
                        <input onChange={(e) => setAmount(e.target.value)} className='amount amount-input' placeholder='0' type='number' /><span className='amount'>€</span>
                    </div>
                    <p>Vous pouvez acheter jusqu’à {isLoading ? ' ...' : wallet.get('EUR')}€</p>
                    <p style={{marginTop: '0', marginBottom: '0'}}>achat minimum {minimalBuy} €  ≈ {coin.orderMin} {coin.symbol}</p>
                    <button onClick={() => amountToWantbuy()} className='custom-btn flex-center padding-btn' style={{ textAlign: 'center', fontSize: '20px', marginTop: '2rem' }}>Suivant</button>
                </div>
            </>
        )
    }

    const previewOrder = () => {
        let buy = amount - calculateFee(amount, Parse.User.current().get('subscription'))
        return (
            <>
                <div className='buy-modal' style={{ justifyContent: 'space-between' }}>
                    <h1 className='order-title'>Aperçu de votre commande</h1>
                </div>
                <hr />
                <div className='order-container'>
                    <div className='flex-center'>
                        <p className='order__total-coin' style={{ backgroundImage: `linear-gradient(45deg,${coin.color.start}, ${coin.color.end})` }}>{roundDecimal(buy / pair.price, 7)}{coin.symbol}</p>
                    </div>
                    <div className="flex justify-space">
                        <p className='order__description'>Payer avec</p>
                        <p className='order__value'>{wallet.get('EUR')} €</p>
                    </div>
                    <div className="flex justify-space">
                        <p className='order__description'>Prix</p>
                        <p className='order__value'>{pair.price} € / {coin.symbol}</p>
                    </div>
                    <div className="flex justify-space">
                        <p className='order__description'>Achat</p>
                        <p className='order__value'>{buy} €</p>
                    </div>
                    <div className="flex justify-space">
                        <p className='order__description'>Frais Laverdy.io</p>
                        <p className='order__value'>{calculateFee(amount, Parse.User.current().get('subscription'))} €</p>
                    </div>
                    <div className="flex justify-space">
                        <p className='order__description'>Total</p>
                        <p className='order__value'>{amount} €</p>
                    </div>
                    <div className='flex-center'>
                        <button onClick={() => subtractToWallet()} className='custom-btn flex-center padding-btn' style={{ textAlign: 'center', fontSize: '20px', marginTop: '2rem' }}>Acheter maintenant</button>
                    </div>
                </div>
            </>
        )
    }

    const validationOrder = () => {
        let buy = amount - calculateFee(amount, Parse.User.current().get('subscription'))
        return (
            <>
                <div className='buy-modal' style={{ justifyContent: 'space-between' }}>
                    <h1 className='order-title'>Votre commande est validée</h1>
                </div>
                <hr />
                <div className='validation-order-container flex-center column'>
                    <span className="validation-text">Votre compte a été crédité de</span>
                    <p className='order__total-coin' style={{ backgroundImage: `linear-gradient(45deg,${coin.color.start}, ${coin.color.end})` }}>{roundDecimal(buy / pair.price, 7)}{coin.symbol}</p>
                    <img alt='validation order' src={validationOrderImg} />
                </div>
            </>
        )
    }

    const buyAsset = () => {
        if (step === 0) {
            return (amountTobuy())
        } else if (step === 1) {
            return (previewOrder())
        } else if (step === 2) {
            return (validationOrder())
        }
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={windowSize.width > 1000 ? customStyles : customStylesMobile}
            contentLabel="Example Modal"
            overlayClassName="Overlay"
            className="Modal"
            shouldCloseOnOverlayClick={false}
        >
            <div style={{ position: 'relative', height: '100%' }}>
                <img onClick={() => closeModal()} className="close-img-modal" src={closeIcon} alt='close icon' />
                {
                    buyAsset()
                }
            </div>
        </Modal>
    )
}

export default BuyAssetModal