import React, { useEffect, useState } from 'react'
import './SignupPage.css'
import signupImg from "../../assets/images/signup.svg"
import handImg from "../../assets/images/hand.svg"
import { useForm } from 'react-hook-form'
import Parse from 'parse';
import logoNav from '../../assets/icons/logo-nav.svg'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify';
import { notifyError } from '../../lib/notification';
import eyeicon from '../../assets/icons/sidebar/icon-eye.svg'
import eyeiconclose from '../../assets/icons/sidebar/icon-eye-close.svg'

const SignupPage = () => {
    const [isSign, setIsSign] = useState(false);
    const { register, handleSubmit } = useForm();
    const history = useHistory();
    const [passwordShown, setPasswordShown] = useState(false);

    useEffect(() => {
        if (Parse.User.current()) {
            history.push('/')
        }
    }, [history])

    async function signUp(value) {
        const user = new Parse.User();
        const Wallet = Parse.Object.extend("Wallet");
        const wallet = new Wallet();
        const Kyc = Parse.Object.extend("Kyc");
        const kyc = new Kyc();
        user.set("username", value.email.toLowerCase());
        user.set("firstname", value.name);
        user.set("lastname", value.lastname);
        user.set("password", value.password);
        user.set("email", value.email);
        user.set("wallet", wallet);
        user.set("kyc", kyc);
        try {
            await user.signUp();
            notify()
            history.push('/emailvalidation')
            setIsSign(false);
        } catch (error) {
            console.log('Error', error)
            let errormail = 'Error: Account already exists for this username.'
            if (errormail.localeCompare(error)) {
                notifyError(`🦄 Cette adresse email est déjà utilisée `)
            } else {
                notifyError(`🦄 Une erreur s'est produite`)
            }
        }
    }

    const onSubmit = (d) => {
        signUp(d)
    }

    const notify = () => toast('₿ Bienvenue chez Laverdy.io ₿', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    return (
        <div className="signup flex">
            <div className='container-img-signup' style={{ width: '50%', height: '100vh' }}>
                <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt='signup img' src={signupImg} />
            </div>
            {
                isSign ?
                    <div className="signup__handcontainer">
                        <img className='slidein' src={handImg} alt='hand position' />
                    </div>
                    :
                    <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100vh' }} className='flex-center column form-container'>
                        <div className='nav-logo-mobile'>
                            <img src={logoNav} alt='logo nav' />
                        </div>
                        <div className="width-signin" style={{ width: '50%', marginTop: '1rem' }}>
                            <h1>Rejoignez le club</h1>
                            <p className='blue-text-signin' style={{ textDecoration: 'none' }} onClick={() => history.push('/')}>{`< Retour`}</p>
                        </div>
                        <div style={{ width: '50%' }} className="width-signin">
                            <div className="flex-center container-personal-input" style={{ justifyContent: 'space-between' }}>
                                <div className='personal-input-signup' style={{ width: '48%' }}>
                                    <span>Nom</span>
                                    <div className="input-module-border" style={{ width: '100%', height: '52px' }}>
                                        <input required {...register("lastname")} className='input-module' />
                                    </div>
                                </div>
                                <div className='personal-input-signup' style={{ width: '48%' }}>
                                    <span>Prenom</span>
                                    <div className="input-module-border" style={{ width: '100%', height: '52px' }}>
                                        <input required {...register("name")} className='input-module' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <span>Email</span>
                                <div className="input-module-border" style={{ width: '100%', height: '52px' }}>
                                    <input autoComplete="username" required type='email' {...register("email")} className='input-module' />
                                </div>
                            </div>
                            <div>
                                <span>Mot de passe</span>
                                <div className="input-module-border" style={{ width: '100%', height: '52px', position: 'relative' }}>
                                    <input autoComplete="current-password" required type={passwordShown ? "text" : "password"} {...register("password")} className='input-module' />
                                    <img style={{position: 'absolute', right: '1rem'}} alt='eye' onClick={() => setPasswordShown(!passwordShown)} src={passwordShown ? eyeicon : eyeiconclose}/>
                                </div>
                            </div>
                            <div className='flex justify-center align-center'>
                                    <input style={{width: '25px', height: '25px', marginRight: '20px'}} required type="checkbox"/>
                                    <p style={{fontSize: '12px'}}>Je certifie être âgé de 18 ans ou plus et j'accepte le <a href="/CGV" target="_blank" style={{color: '#59c8f7', cursor: 'pointer'}}>Contrat d'utilisateur</a> et la <a href="/policy" target="_blank" style={{color: '#59c8f7', cursor: 'pointer'}}>Politique de confidentialité</a>.</p>
                            </div>
                                <div className='flex-center' style={{ width: '100%', marginTop: '1rem' }}>
                                    <button type='submit' className='custom-btn'>Rejoindre</button>
                                </div>
                                <div className='flex-center column' style={{ width: '100%', marginTop: '1rem' }}>
                                    <p className='black-text-signin'>Vous faites déjà partie du club?</p>
                                    <p className='blue-text-signin' onClick={() => history.push('/signin')}>Se connecter</p>
                                </div>
                            </div>
                    </form>
            }
        </div>
    )
}

export default SignupPage