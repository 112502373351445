import React, { useEffect, useState } from 'react'
import './CoinCardComponent.css'
import { roundDecimal } from '../../lib/number'
import BuyAssetModal from '../BuyAssetModal/BuyAssetModal';
import SellAssetModal from '../SellAssetModal/SellAssetModal';
import { notifyError } from '../../lib/notification';
import { useWindowSize } from '../../lib/responsive';
import { useHistory } from 'react-router';


const CoinCardComponent = ({ coin, validateUser }) => {
    const [pair, setPair] = useState({
        price: 0
    });
    const [openBuy, setOpenBuy] = useState(false);
    const [openSell, setOpenSell] = useState(false);
    const [isValidate, setIsValidate] = useState(false);
    let windowSize = useWindowSize()
    const history = useHistory();

    useEffect(() => {
        setIsValidate(validateUser)
        function extractTickers(json) {
            var tickers = Object.keys(json.result).map(key => createTicker(key, json.result[key]))
            tickers.sort((a, b) => b.last24TradeCount - a.last24TradeCount)
            return tickers
        }

        function assetPair(s) {
            return s.replace(/[XZ]([A-Z]{3})[XZ]([A-Z]{3})/g, '$1/$2')
        }

        function createTicker(key, value) {
            return {
                assetPair: assetPair(key),
                price: roundDecimal(value.c[0], 2)
            }
        }

        fetch(`https://api.kraken.com/0/public/Ticker?pair=${coin.krakenId}EUR`)
            .then(response => response.json())
            .then(data => setPair(extractTickers(data)));
    }, [coin, validateUser])

    function closeModal() {
        setOpenBuy(false)
        setOpenSell(false)
    }

    return (
        <>
            {
                windowSize.width > 600 ?
                    <div className='coin-card flex align-center' style={{ justifyContent: 'space-around' }}>
                        <div className='flex' style={{ width: '20%' }}>
                            <img onClick={() => history.push(`/wallet/${coin.symbol}`)} style={{ marginRight: '1rem', cursor: 'pointer' }} src={coin.logo} alt='eth icon' />
                            <div>
                                <h1 style={{cursor: 'pointer'}} onClick={() => history.push(`/wallet/${coin.symbol}`)}>{coin.symbol}</h1>
                                <h2  style={{cursor: 'pointer'}} onClick={() => history.push(`/wallet/${coin.symbol}`)}>{coin.name}</h2>
                            </div>
                        </div>
                        <div style={{ width: '40%' }} className='flex-center'>
                            {pair[0] && <p className='price' style={{ color: coin.color.end }}>{pair[0].price}€</p>}
                        </div>
                        {
                            isValidate ? <div style={{ display: 'flex', width: '40%', justifyContent: 'space-around' }}>
                                <button onClick={() => setOpenBuy(true)} className='buy-button' style={{ background: `linear-gradient(270deg, ${coin.color.start} 0%, ${coin.color.end} 100%),  ${coin.color.start}` }}>Acheter</button>
                                <button onClick={() => setOpenSell(true)} className='sell-button' style={{ background: `linear-gradient(270deg, ${coin.color.start} 0%, ${coin.color.end} 100%),  ${coin.color.start}` }}>Vendre</button>
                            </div> : <div style={{ display: 'flex', width: '40%', justifyContent: 'space-around' }}>
                                <button onClick={() => notifyError('Vous ne pouvez pas effectuer cette action, votre compte est en cours de vérification.')} className='buy-button' style={{ background: '#666666' }}>Acheter</button>
                                <button onClick={() => notifyError('Vous ne pouvez pas effectuer cette action, votre compte est en cours de vérification.')} className='sell-button' style={{ background: '#666666' }}>Vendre</button>
                            </div>
                        }
                        <BuyAssetModal open={openBuy} coin={coin} close={closeModal}></BuyAssetModal>
                        <SellAssetModal open={openSell} coin={coin} close={closeModal}></SellAssetModal>
                    </div> :
                    <div className='coin-card align-center' style={{ justifyContent: 'space-around' }}>
                        <div className='flex justify-space'>
                            <div className='flex-center'>
                                <img style={{ marginRight: '1rem', cursor: 'pointer'}} onClick={() => history.push(`/wallet/${coin.symbol}`)} src={coin.logo} alt='eth icon' />
                                <div>
                                    <h1 style={{cursor: 'pointer'}} onClick={() => history.push(`/wallet/${coin.symbol}`)}>{coin.symbol}</h1>
                                    <h2 style={{cursor: 'pointer'}} onClick={() => history.push(`/wallet/${coin.symbol}`)}>{coin.name}</h2>
                                </div>
                            </div>
                            <div className='flex-center'>
                                {pair[0] && <p className='price' style={{ color: coin.color.end }}>{pair[0].price}€</p>}
                            </div>
                        </div>
                        {
                            isValidate ? <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <button onClick={() => setOpenBuy(true)} className='buy-button' style={{ background: `linear-gradient(270deg, ${coin.color.start} 0%, ${coin.color.end} 100%),  ${coin.color.start}` }}>Acheter</button>
                                <button onClick={() => setOpenSell(true)} className='sell-button' style={{ background: `linear-gradient(270deg, ${coin.color.start} 0%, ${coin.color.end} 100%),  ${coin.color.start}` }}>Vendre</button>
                            </div> : <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
                                <button onClick={() => notifyError('Vous ne pouvez pas effectuer cette action, votre compte est en cours de vérification.')} className='buy-button' style={{ background: '#666666' }}>Acheter</button>
                                <button onClick={() => notifyError('Vous ne pouvez pas effectuer cette action, votre compte est en cours de vérification.')} className='sell-button' style={{ background: '#666666' }}>Vendre</button>
                            </div>
                        }
                        <BuyAssetModal open={openBuy} coin={coin} close={closeModal}></BuyAssetModal>
                        <SellAssetModal open={openSell} coin={coin} close={closeModal}></SellAssetModal>
                    </div>
        }
        </>
    )
}

export default CoinCardComponent