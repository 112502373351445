import React from 'react'
import FiatBagComponent from '../FiatBagComponent/FiatBagComponent'
import GraphTotalWalletComponent from '../GraphTotalWalletComponent/GraphTotalWalletComponent'
import './TotalEvolutionComponent.css'
import { listedCoins } from '../../static/Coins'
import WholeValueWalletComponent from '../WholeValueWalletComponent/WholeValueWalletComponent'
import { useWindowSize } from '../../lib/responsive'

const TotalEvolutionComponent = (wallet) => {
    let windowSize = useWindowSize()
    return (
        <>
            {
                windowSize.width > 1000 ?
                    <div className='evolution flex'>
                        <div style={{ width: '30%' }}>
                            <FiatBagComponent />
                        </div>
                        <div className='evolution__container'>
                            <div className='evolution__stats-container'>
                                <WholeValueWalletComponent />
                                <GraphTotalWalletComponent coin={listedCoins[0]} />
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        <div style={{ width: '100%' }}>
                            <FiatBagComponent />
                        </div>
                        <div className='evolution__responsive-container'>
                            <div className='evolution__stats-container'>
                                <WholeValueWalletComponent />
                                <GraphTotalWalletComponent coin={listedCoins[0]} />
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}


export default TotalEvolutionComponent