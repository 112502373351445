export function selectDate(selection) {
    let date = new Date();
    let day = date.getDate();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let test = year + '-' + month + '-' + day
    console.log('start =>', test)
    if (selection === 'day') {
        console.log('je rentre day')
        // console.log('day', result)
        // return (result);
    } else if (selection === 'week') {
        console.log('je rentre week')
        day = day - 7;
    } else if (selection === 'month') {
        console.log('je rentre month')
        month = month - 1;
    } else if (selection === 'year') {
        console.log('je rentre years')
        year = year - 1;
    }
    if (day < 10) {
        day = '0' + day;
    }
    if (month < 10) {
        month = '0' + month;
    }
    let result = year + '-' + month + '-' + day
    console.log(`${selection}`, result)
    return (result);
}