import React from 'react'
import './LoadingComponent.css'
import loadingGif from '../../assets/loading/loadinglaverdy.gif'

const LoadingComponent = ({height}) => {
    return (
        <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: `${height}vh`}}>
            <img alt='loader gif' style={{width: '20%'}} src={loadingGif}/>
        </div>
    )
}

export default LoadingComponent