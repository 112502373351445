import React, { useEffect, useState } from 'react';
import './GraphCoinComponent.css'
import { Tooltip, AreaChart, Area, YAxis, ResponsiveContainer } from 'recharts';
import { roundDecimal } from '../../lib/number'
import moment from 'moment';
import { useWindowSize } from '../../lib/responsive';
import { selectDate } from '../../lib/date';

const CustomTooltipCoin = ({ active, payload, color }) => {
    if (active) {
        if (!payload[0]) {
            return null;
        }
        let date = moment(payload[0].payload.timestamp).utc().format('DD/MM/YYYY')
        let hours = moment(payload[0].payload.timestamp).utc().format('hh:mm')
        return (
            <div className="custom-tooltip" style={{ background: 'rgba(255, 255, 255, 0.9)', borderRadius: '10px', borderColor: color }}>
                <span className="label">Prix:<span style={{ color: color, marginLeft: '10px' }}>{payload[0].value} €</span></span>
                <span className="label">{`Date: ${date}`}</span>
                <span className="label">{`Heure: ${hours}`}</span>
            </div>
        );
    }
    return null;
};

const GraphCoinComponent = ({ coin, width, height, showSettings }) => {
    const [data, setData] = useState([0]);
    const [maxValue, setMaxValue] = useState('auto');
    // const [maxValueOfCoin, setMaxValue] = useState('auto');
    const [dataModel, setDataModel] = useState('');
    let windowSize = useWindowSize();
    useEffect(() => {
        const manipulateDataCoin = (object) => {
            if (object.error)
                return
            object.map(e => e.price = roundDecimal(e.price * 0.82, 2).toString())
            setData(object)
        }
        setMaxValue('auto')
        // fetch(`https://api.coinpaprika.com/v1/tickers/${coin.paprikaId}/historical?start=1616240116&interval=12h`)
        fetch(`https://api.coinpaprika.com/v1/tickers/${coin.paprikaId}/historical?start=${selectDate('day')}&interval=5m`)
            .then(response => response.json())
            .then(data => {
                data.error ?
                    console.log('error:', data.error) :
                    manipulateDataCoin(data)
            });
    }, [coin])

    function changeDataTime(datestart, time, model, maxValueChange) {
        let maxVal = 0;
        const manipulateDataCoin = (object) => {
            if (object.error)
                return
            object.map(e => e.price = roundDecimal(e.price * 0.82, 2).toString())
            object.forEach((e) => {
                if (parseFloat(e.price) > maxVal) {
                    maxVal = parseFloat(e.price);
                }
            })
            maxValueChange ? setMaxValue(maxVal * 1.5) : setMaxValue('auto');
            setData(object)
        }
        setDataModel(model)

        fetch(`https://api.coinpaprika.com/v1/tickers/${coin.paprikaId}/historical?start=${datestart}&interval=${time}`)
            .then(response => response.json())
            .then(data => {
                data.error ?
                    console.log('error:', data.error) :
                    manipulateDataCoin(data)
            });
    }

    function showBtnDataManipulation(widthscreen) {
        if (widthscreen < 1000) {
            return (
                <div className='flex-center' style={{ justifyContent: 'space-around' }}>
                    <span style={{ color: dataModel === 'day' ? `${coin.color.start}`:`${coin.color.end}` }} onClick={() => { changeDataTime(selectDate('day'), '5m', 'day', false) }}>1J</span>
                    <span style={{ color: dataModel === 'week' ? `${coin.color.start}`:`${coin.color.end}` }} onClick={() => { changeDataTime(selectDate('week'), '1h', 'week', false) }}>1S</span>
                    <span style={{ color: dataModel === 'month' ? `${coin.color.start}`:`${coin.color.end}` }} onClick={() => { changeDataTime(selectDate('month'), '1h', 'month', false) }}>1M</span>
                    <span style={{ color: dataModel === 'year' ? `${coin.color.start}`:`${coin.color.end}` }} onClick={() => { changeDataTime(selectDate('year'), '1d', 'year', true) }}>1A</span>
                </div>
            )
        } else {
            return (
                <div className='flex-center' style={{ justifyContent: 'space-around' }}>
                    <button className='buy-button' style={{ background: dataModel === 'day' ? `linear-gradient(270deg, ${coin.color.start} 0%, ${coin.color.end} 100%),  ${coin.color.start}` : `${coin.color.start}`}} onClick={() => { changeDataTime(selectDate('day'), '5m', 'day', false) }}>jour</button>
                    <button className='buy-button' style={{ background: dataModel === 'week' ? `linear-gradient(270deg, ${coin.color.start} 0%, ${coin.color.end} 100%),  ${coin.color.start}` : `${coin.color.start}`}} onClick={() => { changeDataTime(selectDate('week'), '1h', 'week', false) }}>semaine</button>
                    <button className='buy-button' style={{ background: dataModel === 'month' ? `linear-gradient(270deg, ${coin.color.start} 0%, ${coin.color.end} 100%),  ${coin.color.start}` : `${coin.color.start}`}} onClick={() => { changeDataTime(selectDate('month'), '1h', 'month', false) }}>mois</button>
                    <button className='buy-button' style={{ background: dataModel === 'year' ? `linear-gradient(270deg, ${coin.color.start} 0%, ${coin.color.end} 100%),  ${coin.color.start}` : `${coin.color.start}`}} onClick={() => { changeDataTime(selectDate('year'), '1d', 'year', true) }}>année</button>
                </div>)
        }
    }

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <ResponsiveContainer width="100%" height={height}>
                <AreaChart width={width} height={height} data={data}>
                    <defs>
                        <linearGradient id={`color-${coin.name}`} x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor={coin.color.start} stopOpacity={0.9} />
                            <stop offset="95%" stopColor={coin.color.end} stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <Tooltip content={<CustomTooltipCoin color={coin.color.end} />} />
                    {/* <YAxis domain={['auto', 'auto']} hide /> */}
                    <YAxis domain={['auto', maxValue]} hide />
                    <Area type="monotone" dataKey="price" stroke={coin.color.end} fillOpacity={1} fill={`url(#color-${coin.name})`} />
                </AreaChart>
            </ResponsiveContainer>
            {
                showSettings &&
                <div>
                    {
                        showBtnDataManipulation(windowSize.width)
                    }
                </div>
            }
        </div>
    )
    // }
}

export default GraphCoinComponent
