import React from 'react'
import './InvoiceGeneratorComponent.css'
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image, } from '@react-pdf/renderer';
import { formatDate, formatHours, roundDecimal } from '../../lib/number';
import { ActivityType } from '../../static/Activity';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        width: '100%',
        padding: 30,
    },
    section: {
        margin: 10,
        padding: 10,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
    },
    sectionfooter: {
        margin: 10,
        padding: 10,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        borderTopColor: 'black',
        borderTopWidth: 1,
        position: 'absolute',
        left: 10,
        bottom: 0,
    },
    sectionTitle: {
        margin: 10,
        padding: 10,
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        borderBottomColor: 'black',
        borderBottomWidth: 1,
    },
    logoheader: {
        width: 100,
        height: 50
    },
    logofooter: {
        width: 130,
        height: 13,
        marginTop: 10
    },
    titleTable: {
        fontWeight: '900',
        fontSize: 12,
        width: '25%'
    },
    sectiontable: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    },
    feesection: {
        marginTop: 0,
        marginRight: 10,
        marginLeft: 10,
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    feesectionTotal: {
        marginTop: 20,
        marginRight: 10,
        marginLeft: 10,
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    itemTable: {
        fontSize: 10,
        width: '25%'
    },
    sectionhalf: {
        width: '50%',
    },
    sectionfooter1: {
        width: '35%',
    }, sectionfooter2: {
        width: '65%',
        paddingRight: 30
    },
    address: {
        width: 150,
        fontSize: 12,
    },
    description: {
        fontSize: 12,
    },
    facture: {
        fontSize: 12,
    },
    exo: {
        fontSize: 8,
        width: '25%'
    },
    footertext: {
        fontSize: 8,
        width: '100%'
    }
});

const MyInvoice = ({ activity, currentUser, kyc }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.sectionhalf}>
                        <Image style={styles.logoheader} src='https://parsefiles.back4app.com/1T3JKo7xqdMmgi3lchkInQxalczmi0j8gTsE4s8S/3e2f97d5bf7ca7082fc8904887173d44_logoLaverdy.jpg'></Image>
                    </View>
                    <View style={styles.sectionhalf}>
                        <Text style={styles.facture}>FACTURE</Text>
                        <Text style={styles.facture}>Numéro de facture: {activity.id}</Text>
                        <Text style={styles.facture}>Date: {formatDate(activity.createdAt)} {formatHours(activity.createdAt)}</Text>
                    </View>
                </View>
                <View style={styles.section}>
                    <View style={styles.sectionhalf}>
                        <Text style={styles.address}>Laverdy Capital</Text>
                        <Text style={styles.address}>
                            10 Rue de penthièvre</Text>
                        <Text style={styles.address}>
                            75008 Paris</Text>
                        <Text style={styles.address}>
                            info@laverdy.io</Text>
                    </View>
                    <View style={styles.sectionhalf}>
                        <Text style={styles.description}>{currentUser.get('firstname')} {currentUser.get('lastname')}</Text>
                        <Text style={styles.description}>{kyc.get('address')}</Text>
                        <Text style={styles.description}>{kyc.get('phonenumber')}</Text>
                        <Text style={styles.description}>{kyc.get('postal_code')} {kyc.get('city')}</Text>
                        <Text style={styles.description}>{kyc.get('country')}</Text>
                        <Text style={styles.description}>{currentUser.get('email')}</Text>
                        <Text style={styles.description}>memberId: {currentUser.id}</Text>
                    </View>
                </View>
                <View style={styles.sectiontable}>
                    <View style={styles.sectionTitle}>
                        <Text style={styles.titleTable}>Désignation</Text>
                        <Text style={styles.titleTable}>Prix unitaire**</Text>
                        <Text style={styles.titleTable}>Quantité</Text>
                        <Text style={styles.titleTable}>Montant</Text>
                    </View>
                    <View style={styles.feesection}>
                        {
                            activity.type === 'buy' ? <Text style={styles.itemTable}>Achat {activity.currency}</Text> : <Text style={styles.itemTable}>Vente {activity.currency}</Text>
                        }
                        <Text style={styles.itemTable}>{activity.pairPrice} EUR / {activity.currency}</Text>
                        <Text style={styles.itemTable}>{activity.amount} {activity.currency}</Text>
                        <Text style={styles.itemTable}>{activity.eurAmount} €</Text>
                    </View>
                    <View style={styles.feesection}>
                        <Text style={styles.itemTable}>Commission</Text>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}>{activity.fee} €</Text>
                    </View>
                    <View style={styles.feesectionTotal}>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}>Total à payer</Text>
                        <Text style={styles.itemTable}>{activity.totalAmount} €</Text>
                    </View>
                    <View style={styles.feesection}>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}>TVA 0%*</Text>
                        <Text style={styles.itemTable}>0 €</Text>
                    </View>
                    <View style={styles.feesection}>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.exo}>*Exonération Art. 261 C du CGI ** Montant peut être arrondi</Text>
                        <Text style={styles.itemTable}></Text>
                    </View>
                </View>
                <View style={styles.sectionfooter}>
                    <View style={styles.sectionfooter1}>
                        <Image style={styles.logofooter} src='https://parsefiles.back4app.com/1T3JKo7xqdMmgi3lchkInQxalczmi0j8gTsE4s8S/c7666b4ada85eae1cac122c2e2031bcd_logoLine.jpg'></Image>
                    </View>
                    <View style={styles.sectionfooter2}>
                        <Text style={styles.footertext}>Laverdy.io est une plateforme exploitée par Laverdy Capital, siret: 83490021900017 enregistré</Text>
                        <Text style={styles.footertext}>au tribunal de commerce de Paris B 834 900 219 au capital de 5500,00€.</Text>
                        <Text style={styles.footertext}>info@laverdy.io</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

const MyInvoiceSell = ({ activity, currentUser, kyc }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.sectionhalf}>
                        <Image style={styles.logoheader} src='https://parsefiles.back4app.com/1T3JKo7xqdMmgi3lchkInQxalczmi0j8gTsE4s8S/3e2f97d5bf7ca7082fc8904887173d44_logoLaverdy.jpg'></Image>
                    </View>
                    <View style={styles.sectionhalf}>
                        <Text style={styles.facture}>FACTURE</Text>
                        <Text style={styles.facture}>Numéro de facture: {activity.id}</Text>
                        <Text style={styles.facture}>Date: {formatDate(activity.createdAt)} {formatHours(activity.createdAt)}</Text>
                    </View>
                </View>
                <View style={styles.section}>
                    <View style={styles.sectionhalf}>
                        <Text style={styles.address}>Laverdy Capital</Text>
                        <Text style={styles.address}>
                            10 Rue de penthièvre</Text>
                        <Text style={styles.address}>
                            75008 Paris</Text>
                        <Text style={styles.address}>
                            info@laverdy.io</Text>
                    </View>
                    <View style={styles.sectionhalf}>
                        <Text style={styles.description}>{currentUser.get('firstname')} {currentUser.get('lastname')}</Text>
                        <Text style={styles.description}>{kyc.get('address')}</Text>
                        <Text style={styles.description}>{kyc.get('phonenumber')}</Text>
                        <Text style={styles.description}>{kyc.get('postal_code')} {kyc.get('city')}</Text>
                        <Text style={styles.description}>{kyc.get('country')}</Text>
                        <Text style={styles.description}>{currentUser.get('email')}</Text>
                        <Text style={styles.description}>memberId: {currentUser.id}</Text>
                    </View>
                </View>
                <View style={styles.sectiontable}>
                    <View style={styles.sectionTitle}>
                        <Text style={styles.titleTable}>Désignation</Text>
                        <Text style={styles.titleTable}>Prix unitaire**</Text>
                        <Text style={styles.titleTable}>Quantité</Text>
                        <Text style={styles.titleTable}>Montant</Text>
                    </View>
                    <View style={styles.feesection}>
                        {
                            activity.type === 'buy' ? <Text style={styles.itemTable}>Achat {activity.currency}</Text> : <Text style={styles.itemTable}>Vente {activity.currency}</Text>
                        }
                        <Text style={styles.itemTable}>{activity.pairPrice} EUR / {activity.currency}</Text>
                        <Text style={styles.itemTable}>{activity.amount} {activity.currency}</Text>
                        <Text style={styles.itemTable}>{activity.totalAmount} €</Text>
                    </View>
                    <View style={styles.feesection}>
                        <Text style={styles.itemTable}>Commission</Text>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}>{activity.fee} €</Text>
                    </View>
                    <View style={styles.feesectionTotal}>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}>Total à recevoir</Text>
                        <Text style={styles.itemTable}>{roundDecimal(activity.totalAmount - activity.fee, 2)} €</Text>
                    </View>
                    <View style={styles.feesection}>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}>TVA 0%*</Text>
                        <Text style={styles.itemTable}>0 €</Text>
                    </View>
                    <View style={styles.feesection}>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.exo}>*Exonération Art. 261 C du CGI ** Montant peut être arrondi</Text>
                        <Text style={styles.itemTable}></Text>
                    </View>
                </View>
                <View style={styles.sectionfooter}>
                    <View style={styles.sectionfooter1}>
                        <Image style={styles.logofooter} src='https://parsefiles.back4app.com/1T3JKo7xqdMmgi3lchkInQxalczmi0j8gTsE4s8S/c7666b4ada85eae1cac122c2e2031bcd_logoLine.jpg'></Image>
                    </View>
                    <View style={styles.sectionfooter2}>
                        <Text style={styles.footertext}>Laverdy.io est une plateforme exploitée par Laverdy Capital, siret: 83490021900017 enregistré</Text>
                        <Text style={styles.footertext}>au tribunal de commerce de Paris B 834 900 219 au capital de 5500,00€.</Text>
                        <Text style={styles.footertext}>info@laverdy.io</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

const MyInvoiceDeposit = ({ activity, currentUser, kyc }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.sectionhalf}>
                        <Image style={styles.logoheader} src='https://parsefiles.back4app.com/1T3JKo7xqdMmgi3lchkInQxalczmi0j8gTsE4s8S/3e2f97d5bf7ca7082fc8904887173d44_logoLaverdy.jpg'></Image>
                    </View>
                    <View style={styles.sectionhalf}>
                        <Text style={styles.facture}>FACTURE</Text>
                        <Text style={styles.facture}>Numéro de facture: {activity.id}</Text>
                        <Text style={styles.facture}>Date: {formatDate(activity.createdAt)} {formatHours(activity.createdAt)}</Text>
                    </View>
                </View>
                <View style={styles.section}>
                    <View style={styles.sectionhalf}>
                        <Text style={styles.address}>Laverdy Capital</Text>
                        <Text style={styles.address}>
                            10 Rue de penthièvre</Text>
                        <Text style={styles.address}>
                            75008 Paris</Text>
                        <Text style={styles.address}>
                            info@laverdy.io</Text>
                    </View>
                    <View style={styles.sectionhalf}>
                        <Text style={styles.description}>{currentUser.get('firstname')} {currentUser.get('lastname')}</Text>
                        <Text style={styles.description}>{kyc.get('address')}</Text>
                        <Text style={styles.description}>{kyc.get('phonenumber')}</Text>
                        <Text style={styles.description}>{kyc.get('postal_code')} {kyc.get('city')}</Text>
                        <Text style={styles.description}>{kyc.get('country')}</Text>
                        <Text style={styles.description}>{currentUser.get('email')}</Text>
                        <Text style={styles.description}>memberId: {currentUser.id}</Text>
                    </View>
                </View>
                <View style={styles.sectiontable}>
                    <View style={styles.sectionTitle}>
                        <Text style={styles.titleTable}>Désignation</Text>
                        <Text style={styles.titleTable}>Prix unitaire**</Text>
                        <Text style={styles.titleTable}>Quantité</Text>
                        <Text style={styles.titleTable}>Montant</Text>
                    </View>
                    <View style={styles.feesection}>
                        {
                            activity.type === 'buy' ? <Text style={styles.itemTable}>Achat {activity.currency}</Text> : <Text style={styles.itemTable}>Dépot EUR</Text>
                        }
                        <Text style={styles.itemTable}>{activity.pairPrice} EUR / {activity.currency}</Text>
                        <Text style={styles.itemTable}>{activity.amount} {activity.currency}</Text>
                        <Text style={styles.itemTable}>{activity.eurAmount} €</Text>
                    </View>
                    <View style={styles.feesection}>
                        <Text style={styles.itemTable}>Commission</Text>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}>{activity.fee} €</Text>
                    </View>
                    <View style={styles.feesectionTotal}>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}>Total déposé</Text>
                        <Text style={styles.itemTable}>{activity.totalAmount} €</Text>
                    </View>
                    <View style={styles.feesection}>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}>TVA 0%*</Text>
                        <Text style={styles.itemTable}>0 €</Text>
                    </View>
                    <View style={styles.feesection}>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.exo}>*Exonération Art. 261 C du CGI ** Montant peut être arrondi</Text>
                        <Text style={styles.itemTable}></Text>
                    </View>
                </View>
                <View style={styles.sectionfooter}>
                    <View style={styles.sectionfooter1}>
                        <Image style={styles.logofooter} src='https://parsefiles.back4app.com/1T3JKo7xqdMmgi3lchkInQxalczmi0j8gTsE4s8S/c7666b4ada85eae1cac122c2e2031bcd_logoLine.jpg'></Image>
                    </View>
                    <View style={styles.sectionfooter2}>
                        <Text style={styles.footertext}>Laverdy.io est une plateforme exploitée par Laverdy Capital, siret: 83490021900017 enregistré</Text>
                        <Text style={styles.footertext}>au tribunal de commerce de Paris B 834 900 219 au capital de 5500,00€.</Text>
                        <Text style={styles.footertext}>info@laverdy.io</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

const MyInvoiceWithdraw = ({ activity, currentUser, kyc }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.sectionhalf}>
                        <Image style={styles.logoheader} src='https://parsefiles.back4app.com/1T3JKo7xqdMmgi3lchkInQxalczmi0j8gTsE4s8S/3e2f97d5bf7ca7082fc8904887173d44_logoLaverdy.jpg'></Image>
                    </View>
                    <View style={styles.sectionhalf}>
                        <Text style={styles.facture}>FACTURE</Text>
                        <Text style={styles.facture}>Numéro de facture: {activity.id}</Text>
                        <Text style={styles.facture}>Date: {formatDate(activity.createdAt)} {formatHours(activity.createdAt)}</Text>
                    </View>
                </View>
                <View style={styles.section}>
                    <View style={styles.sectionhalf}>
                        <Text style={styles.address}>Laverdy Capital</Text>
                        <Text style={styles.address}>
                            10 Rue de penthièvre</Text>
                        <Text style={styles.address}>
                            75008 Paris</Text>
                        <Text style={styles.address}>
                            info@laverdy.io</Text>
                    </View>
                    <View style={styles.sectionhalf}>
                        <Text style={styles.description}>{currentUser.get('firstname')} {currentUser.get('lastname')}</Text>
                        <Text style={styles.description}>{kyc.get('address')}</Text>
                        <Text style={styles.description}>{kyc.get('phonenumber')}</Text>
                        <Text style={styles.description}>{kyc.get('postal_code')} {kyc.get('city')}</Text>
                        <Text style={styles.description}>{kyc.get('country')}</Text>
                        <Text style={styles.description}>{currentUser.get('email')}</Text>
                        <Text style={styles.description}>memberId: {currentUser.id}</Text>
                    </View>
                </View>
                <View style={styles.sectiontable}>
                    <View style={styles.sectionTitle}>
                        <Text style={styles.titleTable}>Désignation</Text>
                        <Text style={styles.titleTable}>Prix unitaire**</Text>
                        <Text style={styles.titleTable}>Quantité</Text>
                        <Text style={styles.titleTable}>Montant</Text>
                    </View>
                    <View style={styles.feesection}>
                        {
                            activity.type === 'buy' ? <Text style={styles.itemTable}>Achat {activity.currency}</Text> : <Text style={styles.itemTable}>Retrait EUR</Text>
                        }
                        <Text style={styles.itemTable}>{activity.pairPrice} EUR / {activity.currency}</Text>
                        <Text style={styles.itemTable}>{activity.amount} {activity.currency}</Text>
                        <Text style={styles.itemTable}>{activity.eurAmount} €</Text>
                    </View>
                    <View style={styles.feesection}>
                        <Text style={styles.itemTable}>Commission</Text>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}>{activity.fee} €</Text>
                    </View>
                    <View style={styles.feesectionTotal}>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}>Total à recevoir</Text>
                        <Text style={styles.itemTable}>{activity.totalAmount} €</Text>
                    </View>
                    <View style={styles.feesection}>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}>TVA 0%*</Text>
                        <Text style={styles.itemTable}>0 €</Text>
                    </View>
                    <View style={styles.feesection}>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.itemTable}></Text>
                        <Text style={styles.exo}>*Exonération Art. 261 C du CGI ** Montant peut être arrondi</Text>
                        <Text style={styles.itemTable}></Text>
                    </View>
                </View>
                <View style={styles.sectionfooter}>
                    <View style={styles.sectionfooter1}>
                        <Image style={styles.logofooter} src='https://parsefiles.back4app.com/1T3JKo7xqdMmgi3lchkInQxalczmi0j8gTsE4s8S/c7666b4ada85eae1cac122c2e2031bcd_logoLine.jpg'></Image>
                    </View>
                    <View style={styles.sectionfooter2}>
                        <Text style={styles.footertext}>Laverdy.io est une plateforme exploitée par Laverdy Capital, siret: 83490021900017 enregistré</Text>
                        <Text style={styles.footertext}>au tribunal de commerce de Paris B 834 900 219 au capital de 5500,00€.</Text>
                        <Text style={styles.footertext}>info@laverdy.io</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}


const InvoiceGeneratorComponent = ({ activity, currentUser, kyc }) => {
    function selectInvoiceType(activityType) {
        switch (activityType) {
            case ActivityType.BUY:
                return (
                    <PDFDownloadLink document={<MyInvoice activity={activity} currentUser={currentUser} kyc={kyc} />} fileName={`Laverdy-io-${activity.id}`}>
                        {({ blob, url, loading, error }) =>
                            loading ? 'Loading document...' : 'Télécharger ma facture'
                        }
                    </PDFDownloadLink>
                )
            case ActivityType.DEPOSIT:
                return (
                    <PDFDownloadLink document={<MyInvoiceDeposit activity={activity} currentUser={currentUser} kyc={kyc} />} fileName={`Laverdy-io-${activity.id}`}>
                        {({ blob, url, loading, error }) =>
                            loading ? 'Loading document...' : 'Télécharger ma facture'
                        }
                    </PDFDownloadLink>
                )
            case ActivityType.WITHDRAW:
                return (
                    <PDFDownloadLink document={<MyInvoiceWithdraw activity={activity} currentUser={currentUser} kyc={kyc} />} fileName={`Laverdy-io-${activity.id}`}>
                        {({ blob, url, loading, error }) =>
                            loading ? 'Loading document...' : 'Télécharger ma facture'
                        }
                    </PDFDownloadLink>
                )
            case ActivityType.SELL:
                return (
                    <PDFDownloadLink document={<MyInvoiceSell activity={activity} currentUser={currentUser} kyc={kyc} />} fileName={`Laverdy-io-${activity.id}`}>
                        {({ blob, url, loading, error }) =>
                            loading ? 'Loading document...' : 'Télécharger ma facture'
                        }
                    </PDFDownloadLink>
                )
            default:
                return (
                    <PDFDownloadLink document={<MyInvoice activity={activity} currentUser={currentUser} kyc={kyc} />} fileName={`Laverdy-io-${activity.id}`}>
                        {({ blob, url, loading, error }) =>
                            loading ? 'Loading document...' : 'Télécharger ma facture'
                        }
                    </PDFDownloadLink>
                )
        }
    }

    return (
        <>
            {
                selectInvoiceType(activity.type)
            }
        </>
    );
}

export default InvoiceGeneratorComponent