import React from 'react'
import './SignOutComponent.css'
import Parse from 'parse';
import { useHistory } from 'react-router';
import { notifyValidate } from '../../lib/notification';

const SignOutComponent = () => {
    const history = useHistory();
    const signOut = () => {
        Parse.User.logOut().then(() => {
            Parse.User.current();  // this will now be null
            notifyValidate('Vous êtes bien déconnecté')
            history.push('/signin')
        });
    }

    return (
        <div>
            <button id='btn' type="button" name="button_signout"  style={{ marginTop: '3rem' }} onClick={() => signOut()} className='custom-btn'>Déconnexion</button>
        </div>
    )
}

export default SignOutComponent