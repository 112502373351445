import React, { useEffect, useState } from 'react'
import './ModalKycComponent.css'
import Modal from 'react-modal';
import document from '../../assets/images/document.svg'
import passport from '../../assets/images/passport.svg'
import identityPassport from '../../assets/images/identity-passport.svg'
import validation from '../../assets/images/validation_kyc.svg'
import closeIcon from '../../assets/images/close_icon.svg'
import { useForm } from 'react-hook-form'
import Parse from 'parse';
import { KycStatus } from '../../static/Kyc';
import { useWindowSize } from '../../lib/responsive';
import Dropzone from 'react-dropzone';
import { notifyError } from '../../lib/notification';
import { formatDateBirthday } from '../../lib/number';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        borderRaduis: '20px',
        transform: 'translate(-50%, -50%)'
    }
};

// const customStylesMobile = {
//     content: {
//         top: '50%',
//         left: '50%',
//         right: 'auto',
//         bottom: 'auto',
//         marginRight: '-50%',
//         borderRaduis: '20px',
//         transform: 'translate(-50%, -50%)',
//         height: '100%',
//         width: '100%'
//     }
// };

const customStylesMobile = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        borderRaduis: '20px',
        transform: 'translate(-50%, -50%)',
        height: '90vh',
        width: '100%',
        paddingTop: '4rem',
        paddingBottom: '8rem',
        overflowX: 'scroll',
    }
};


function ModalKycComponent({ openFromVerification }) {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [kycPageNbr, setKycPageNbr] = React.useState(0);
    const [kycStatus, setKycStatus] = React.useState('');
    const { register, formState: { errors }, handleSubmit } = useForm();
    const [isLoading, setIsLoading] = useState(true);
    let windowSize = useWindowSize()
    const [fileJustification, setFileJustification] = useState([]);
    const [filePassPort, setFilePassPort] = useState([]);
    const [fileIdentity, setFileIdentity] = useState([]);
    const [currentKyc, setCurrentKyc] = useState({
        address: '',
        city: '',
        postal_code: '',
        country: '',
        phonenumber: '',
        birthdate: '',
        birthlocation: '',
    })

    useEffect(() => {
        function selectStatus(status, alreadybegin) {
            setKycStatus(status);
            if (status === KycStatus.FALSE) {
                setIsOpen(true);
                if (!alreadybegin) {
                    setKycPageNbr(0);
                }
            } else if (status === KycStatus.WAITING) {
                setIsOpen(true);
            } else {
                setIsOpen(false);
            }
        }

        async function getKyc() {
            const currentUser = Parse.User.current();
            if (currentUser) {
                const Kyc = Parse.Object.extend("Kyc");
                const query = new Parse.Query(Kyc);
                query.equalTo("objectId", currentUser.get('kyc').id);
                const subscribe = await query.subscribe();
                let kyc = {};
                subscribe.on('open', async () => {
                    kyc = await query.find();
                    selectStatus(kyc[0].get('validate'));
                    setCurrentKyc({
                        address: kyc[0].get('address'),
                        city: kyc[0].get('city'),
                        postal_code: kyc[0].get('postal_code'),
                        country: kyc[0].get('country'),
                        phonenumber: kyc[0].get('phonenumber'),
                        birthdate: kyc[0].get('birthdate'),
                        birthlocation: kyc[0].get('birthlocation'),
                    });
                    setIsLoading(false);
                })
                subscribe.on('update', (kyc) => {
                    if (kyc.get('address')) {
                        //to let the modal open when kyc are already begin
                        return
                    }
                    selectStatus(kyc.get('validate'));
                })
                subscribe.on('close', () => {
                    console.log('subscription closed');
                });
            }
        }
        if (openFromVerification === true) {
            setIsOpen(true);
        }
        getKyc();
    }, [openFromVerification])

    useEffect(() => {
        if (kycStatus === KycStatus.WAITING) {
            windowSize.width < 1000 ? setKycPageNbr(4) : setKycPageNbr(3)
        }
    }, [windowSize, kycStatus])

    function closeModal() {
        setIsOpen(false);
    }

    function addOne() {
        setKycPageNbr(kycPageNbr + 1)
    }

    function kyc_info(value) {
        if (windowSize.width > 1000) {
            if (kycPageNbr > 2) {
                closeModal();
            }
            if (kycPageNbr === 0) {
                add_kyc_firstpart(value)
            }
            if (kycPageNbr === 2) {
                add_kyc(value)
            }
        } else {
            if (kycPageNbr > 3) {
                closeModal();
            }
            if (kycPageNbr === 0) {
                add_kyc_firstpart(value)
            }
            if (kycPageNbr === 3) {
                add_kyc(value)
            }
        }
        addOne();
    }

    async function add_kyc(value) {
        const kyc = Parse.User.current().get('kyc');
        const parseFileDoc = new Parse.File('justificatif-domicile', fileJustification[0].file);
        const parseFilePassport = new Parse.File('passport', filePassPort[0].file);
        const parsePictureIdentityPassport = new Parse.File('identity-passport', fileIdentity[0].file);
        kyc.set("address", value.address);
        kyc.set("birthdate", value.birthdate);
        kyc.set("birthlocation", value.birthlocation);
        kyc.set("country", value.country);
        kyc.set("postal_code", value.postal_code);
        kyc.set("phonenumber", value.phonenumber);
        kyc.set("city", value.city);
        kyc.set("document_file", parseFileDoc);
        kyc.set("passport_file", parseFilePassport);
        kyc.set("identity_file", parsePictureIdentityPassport);
        kyc.set("validate", 'waiting');
        await kyc.save().catch((error) => notifyError('une erreur est survenue'))
    }

    async function add_kyc_firstpart(value) {
        const kyc = Parse.User.current().get('kyc');
        kyc.set("address", value.address);
        kyc.set("birthdate", value.birthdate);
        kyc.set("birthlocation", value.birthlocation);
        kyc.set("country", value.country);
        kyc.set("postal_code", value.postal_code);
        kyc.set("phonenumber", value.phonenumber);
        kyc.set("city", value.city);
        await kyc.save().catch((error) => notifyError('une erreur est survenue'))
    }

    const infoKycForm = () => {
        return (
            <>
                <div className='container-kyc-info'>
                    <div style={{ width: windowSize.width > 1000 ? '40%' : '100%' }}>
                        <span className="input-description">Adresse</span>
                        <div className="input-module-border" style={{ width: '100%', height: '52px' }}>
                            <input {...register("address", { required: true })} placeholder='Adresse' className='input-module' onChange={(e) => setCurrentKyc({...currentKyc, address: e.target.value})} value={currentKyc.address || ''} />
                            {errors.address && "Adresse est obligatoire"}
                        </div>
                        <span className="input-description">Ville</span>
                        <div className="input-module-border" style={{ width: '100%', height: '52px' }}>
                            <input value={currentKyc.city} {...register("city", { required: true })} onChange={(e) => setCurrentKyc({...currentKyc, city: e.target.value})} placeholder='Ville' className='input-module' />
                            {errors.city && "La ville  est obligatoire"}
                        </div>
                        <span className="input-description">Code postal</span>
                        <div className="input-module-border" style={{ width: '100%', height: '52px' }}>
                            <input value={currentKyc.postal_code} {...register("postal_code", { required: true })} onChange={(e) => setCurrentKyc({...currentKyc, postal_code: e.target.value})} placeholder='Code postal' className='input-module' />
                            {errors.postal_code && "Le code postal est obligatoire"}
                        </div>
                        <span className="input-description">Pays</span>
                        <div className="input-module-border" style={{ width: '100%', height: '52px' }}>
                            <input value={currentKyc.country} {...register("country", { required: true })} onChange={(e) => setCurrentKyc({...currentKyc, country: e.target.value})} placeholder='Pays' className='input-module' />
                            {errors.country && "Le pays est obligatoire"}
                        </div>
                    </div>
                    <div style={{ width: windowSize.width > 1000 ? '40%' : '100%' }}>
                        <span className="input-description">Téléphone</span>
                        <div className="input-module-border" style={{ width: '100%', height: '52px' }}>
                            <input value={currentKyc.phonenumber} {...register("phonenumber", { required: true })} onChange={(e) => setCurrentKyc({...currentKyc, phonenumber: e.target.value})} placeholder='Téléphone' className='input-module' />
                            {errors.phonenumber && "Le numero de telephone est obligatoire"}
                        </div>
                        <span className="input-description">Date de naissance</span>
                        <div className="input-module-border" style={{ width: '100%', height: '52px' }}>
                            <input type="date" max={formatDateBirthday()} value={currentKyc.birthdate} {...register("birthdate", { required: true })} onChange={(e) => setCurrentKyc({...currentKyc, birthdate: e.target.value})} placeholder='Date de naissance' className='input-module' />
                            {errors.date && "La date de naissance est obligatoire"}
                        </div>
                        <span className="input-description">Lieu de naissance</span>
                        <div className="input-module-border" style={{ width: '100%', height: '52px' }}>
                            <input value={currentKyc.birthlocation}  {...register("birthlocation", { required: true })} onChange={(e) => setCurrentKyc({...currentKyc, birthlocation: e.target.value})} placeholder='Lieu de naissance' className='input-module' />
                            {errors.birthlocation && "Le lieu de naissance est obligatoire"}
                        </div>
                    </div>
                </div>
                <div className='flex-center'>
                    <button className='custom-btn' type='submit'>Suivant</button>
                </div>
            </>
        )
    }

    const validationKycForm = () => {
        return (
            <>
                <div className='container-kyc-info'>
                    <div style={{ textAlign: 'center' }}>
                        <p className="validation-kyc">Nous avons bien reçu vos documents, ils sont en cours de vérification par nos services.</p>
                        <img src={validation} alt='validation img' />
                    </div>
                </div>
                <div className='flex-center'>
                    <button className='custom-btn' type='submit'>Terminer</button>
                </div>
            </>
        )
    }

    const kycPage = () => {
        if (windowSize.width > 1000) {
            if (kycPageNbr === 0) {
                return (infoKycForm())
            } else if (kycPageNbr === 1) {
                return (infoKycDocument())
            } else if (kycPageNbr === 2) {
                return (identityKycDocument())
            } else if (kycPageNbr === 3) {
                return (validationKycForm())
            }
        } else {
            if (kycPageNbr === 0) {
                return (infoKycForm())
            } else if (kycPageNbr === 1) {
                return (infoKycDocumentModilePassPort())
            } else if (kycPageNbr === 2) {
                return (infoKycDocumentModileDomicile())
            } else if (kycPageNbr === 3) {
                return (identityKycDocument())
            } else if (kycPageNbr === 4) {
                return (validationKycForm())
            }
        }
    }

    const handleDrop = (acceptedFiles) => {
        let files = acceptedFiles[0];
        setFileJustification([{ file: files }]);
    }

    const handleDropPassPort = (acceptedFiles) => {
        let files = acceptedFiles[0];
        setFilePassPort([{ file: files }]);
    }

    const handleDropIdentity = (acceptedFiles) => {
        let files = acceptedFiles[0];
        setFileIdentity([{ file: files }]);
    }

    const infoKycDocument = () => {
        return (
            <>
                <div className='container-kyc-info'>
                    <div style={{ width: '40%' }}>
                        <h2 className='title-document-kyc'>Scannez votre passeport</h2>
                        <div style={{ height: '60px' }}>
                            <p className="text-document-kyc">Prenez votre passeport ou carte d’identité en photo de manière a ce qu’il soit bien lisible. Votre document doit être en cours de validité.</p>
                        </div>
                        <div className="upload-zone" style={{ textAlign: 'center' }}>
                            <Dropzone
                                onDrop={handleDropPassPort}
                                accept="image/*, application/pdf"
                                minSize={1024}
                                // maxSize={3072000}
                                maxFiles={1}
                            >
                                {({
                                    getRootProps,
                                    getInputProps,
                                    isDragActive,
                                    isDragAccept,
                                    isDragReject
                                }) => {
                                    const additionalClass = isDragAccept
                                        ? "accept"
                                        : isDragReject
                                            ? "reject"
                                            : "";

                                    return (
                                        <div
                                            {...getRootProps({
                                                className: `dropzone ${additionalClass}`
                                            })}
                                        >
                                            <input {...getInputProps()} />
                                            <div className='flex-center' style={{ height: '200px' }}>
                                                <img src={passport} alt='passport' />
                                            </div>
                                            {/* <span>{isDragActive ? "📂" : "📁"}</span> */}
                                            <p>Glissez vos fichiers directement dans cette zone ou cliquez sur l'icône ci-dessus</p>
                                        </div>
                                    );
                                }}
                            </Dropzone>
                            <div className='flex-center' style={{height: '20px'}}>
                                <span>Justificatif:</span>
                                <ul>
                                    {filePassPort.map(file => (
                                        file.file.name ? <li key={file.file.name}>{file.file.name}</li> : <li>picture</li>
                                        // <li key={file.file.name}>{file.file.name}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '40%' }}>
                        <h2 className='title-document-kyc'>Justificatif de domicile</h2>
                        <div style={{ height: '60px' }}>
                            <p className="text-document-kyc">Quittance de loyer, taxe foncière, facture EDF ou téléphonique à votre nom et de moins de 3 mois.</p>
                        </div>
                        <div className="upload-zone" style={{ textAlign: 'center' }}>
                            <Dropzone
                                onDrop={handleDrop}
                                accept="image/*, application/pdf"
                                minSize={1024}
                                // maxSize={3072000}
                                maxFiles={1}
                            >
                                {({
                                    getRootProps,
                                    getInputProps,
                                    isDragActive,
                                    isDragAccept,
                                    isDragReject
                                }) => {
                                    const additionalClass = isDragAccept
                                        ? "accept"
                                        : isDragReject
                                            ? "reject"
                                            : "";

                                    return (
                                        <div
                                            {...getRootProps({
                                                className: `dropzone ${additionalClass}`
                                            })}
                                        >
                                            <input {...getInputProps()} />
                                            <div className='flex-center' style={{ height: '200px' }}>
                                                <img src={document} alt='document' />
                                            </div>
                                            {/* <span>{isDragActive ? "📂" : "📁"}</span> */}
                                            <p>Glissez vos fichiers directement dans cette zone ou cliquez sur l'icône ci-dessus</p>
                                        </div>
                                    );
                                }}
                            </Dropzone>
                            <div className='flex-center' style={{height: '20px'}}>
                                <span>Justificatif:</span>
                                <ul>
                                    {fileJustification.map(file => (
                                        file.file.name ? <li key={file.file.name}>{file.file.name}</li> : <li>picture</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex-center'>
                    <button disabled={(filePassPort.length > 0 && fileJustification.length > 0) ? false : true} style={{ marginTop: '1rem' }} className='custom-btn' type='submit'>Suivant</button>
                </div>
            </>
        )
    }


    const infoKycDocumentModilePassPort = () => {
        return (
            <>
                <div className='container-kyc-info'>
                    <div style={{ width: '100%' }}>
                        <h2 className='title-document-kyc'>Scannez votre passeport</h2>
                        <p className="text-document-kyc">Prenez votre passeport ou carte d’identité en photo de manière a ce qu’il soit bien lisible. Votre document doit être en cours de validité.</p>
                        <div className="upload-zone" style={{ textAlign: 'center' }}>
                            <Dropzone
                                onDrop={handleDropPassPort}
                                // accept="image/*, application/pdf"
                                accept="image/*, application/pdf"
                                minSize={1024}
                                // maxSize={3072000}
                                maxFiles={1}
                            >
                                {({
                                    getRootProps,
                                    getInputProps,
                                    isDragActive,
                                    isDragAccept,
                                    isDragReject
                                }) => {
                                    const additionalClass = isDragAccept
                                        ? "accept"
                                        : isDragReject
                                            ? "reject"
                                            : "";

                                    return (
                                        <div
                                            {...getRootProps({
                                                className: `dropzone ${additionalClass}`
                                            })}
                                        >
                                            <input {...getInputProps()} />
                                            <div className='flex-center' style={{ height: '200px' }}>
                                                <img src={passport} alt='passport' />
                                            </div>
                                            {/* <span>{isDragActive ? "📂" : "📁"}</span> */}
                                            <p>Glissez vos fichiers directement dans cette zone ou cliquez sur l'icône ci-dessus</p>
                                        </div>
                                    );
                                }}
                            </Dropzone>
                            <div className='flex-center'>
                                <span>Passeport:</span>
                                <ul>
                                    {filePassPort.map(file => (
                                        file.file.name ? <li key={file.file.name}>{file.file.name}</li> : <li>picture</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex-center'>
                    <button disabled={filePassPort.length > 0 ? false : true} style={{ marginTop: '3rem' }} className='custom-btn' type='submit'>Suivant</button>
                </div>
            </>
        )
    }

    const infoKycDocumentModileDomicile = () => {
        return (
            <>
                <div className='container-kyc-info'>
                    <div style={{ width: '100%' }}>
                        <h2 className='title-document-kyc'>Justificatif de domicile</h2>
                        <p className="text-document-kyc">Quittance de loyer, taxe foncière, facture EDF ou téléphonique à votre nom et de moins de 3 mois.</p>
                        <div className="upload-zone" style={{ textAlign: 'center' }}>
                            <Dropzone
                                onDrop={handleDrop}
                                accept="image/*, application/pdf"
                                minSize={1024}
                                // maxSize={3072000}
                                maxFiles={1}
                            >
                                {({
                                    getRootProps,
                                    getInputProps,
                                    isDragActive,
                                    isDragAccept,
                                    isDragReject
                                }) => {
                                    const additionalClass = isDragAccept
                                        ? "accept"
                                        : isDragReject
                                            ? "reject"
                                            : "";

                                    return (
                                        <div
                                            {...getRootProps({
                                                className: `dropzone ${additionalClass}`
                                            })}
                                        >
                                            <input {...getInputProps()} />
                                            <div className='flex-center' style={{ height: '200px' }}>
                                                <img src={document} alt='document' />
                                            </div>
                                            {/* <span>{isDragActive ? "📂" : "📁"}</span> */}
                                            <p>Glissez vos fichiers directement dans cette zone ou cliquez sur l'icône ci-dessus</p>
                                        </div>
                                    );
                                }}
                            </Dropzone>
                            <div className='flex-center'>
                                <span>Justificatif:</span>
                                <ul>
                                    {fileJustification.map(file => (
                                        file.file.name ? <li key={file.file.name}>{file.file.name}</li> : <li>picture</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex-center'>
                    <button disabled={fileJustification.length > 0 ? false : true} style={{ marginTop: '3rem' }} className='custom-btn' type='submit'>Suivant</button>
                </div>
            </>
        )
    }

    const identityKycDocument = () => {
        return (
            <>
                <div className='container-kyc-info'>
                    <div style={{ width: '100%' }} className='container-identity'>
                        <h2 className='title-document-kyc'>Selfie avec le passeport/carte d’identité en main</h2>
                        <p className="text-document-kyc">Prenez vous en photo en tenant votre pièce d’identité face caméra</p>
                        <div className="upload-zone container-identity" style={{ textAlign: 'center' }}>
                            <Dropzone
                                onDrop={handleDropIdentity}
                                // accept="image/*, application/pdf"
                                accept="image/*, application/pdf"
                                minSize={1024}
                                // maxSize={3072000}
                                maxFiles={1}
                            >
                                {({
                                    getRootProps,
                                    getInputProps,
                                    isDragActive,
                                    isDragAccept,
                                    isDragReject
                                }) => {
                                    const additionalClass = isDragAccept
                                        ? "accept"
                                        : isDragReject
                                            ? "reject"
                                            : "";

                                    return (
                                        <div
                                            {...getRootProps({
                                                className: `dropzone ${additionalClass}`
                                            })}
                                        >
                                            <input {...getInputProps()} />
                                            <div className='flex-center' style={{ height: '200px' }}>
                                                <img style={{ height: '200px' }} src={identityPassport} alt='identity' />
                                            </div>
                                            <p>Glissez vos fichiers directement dans cette zone ou cliquez sur l'icône ci-dessus</p>
                                        </div>
                                    );
                                }}
                            </Dropzone>
                            <div className='flex-center' style={{height: '20px'}}>
                                <span>Identity:</span>
                                <ul>
                                    {fileIdentity.map(file => (
                                        file.file.name ? <li key={file.file.name}>{file.file.name}</li> : <li>picture</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex-center'>
                    <button disabled={fileIdentity.length > 0 ? false : true} style={{ marginTop: '3rem' }} className='custom-btn' type='submit'>Suivant</button>
                </div>
            </>
        )
    }

    const onSubmit = (d) => {
        kyc_info(d)
    }

    return (
        <div>
            {
                !isLoading && <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={windowSize.width > 1000 ? customStyles : customStylesMobile}
                    contentLabel="Example Modal"
                    overlayClassName="Overlay"
                    className="Modal"
                    shouldCloseOnOverlayClick={false}
                >
                    <div style={{ position: 'relative', padding: windowSize.width && '1rem' }}>
                        <img onClick={() => closeModal()} className='close-img-modal' src={closeIcon} alt='close icon' />
                        <div className='kyc-header'>
                            <h1>KYC (Know Your Customer)</h1>
                            <p>En conformité avec la règlementation Française et avant toute transaction, vous devez vérifier votre identité. Pour ce faire merci de remplir le formulaire ci-dessous.</p>
                        </div>
                        <hr />
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {
                                kycPage()
                            }
                        </form>
                    </div>
                </Modal>
            }
        </div>
    );
}

export default ModalKycComponent