import React, { useEffect } from "react";

export function useWindowSize() {

    // const isClient = typeof window === 'object';
    const [windowSize, setWindowSize] = React.useState(0);


    useEffect(() => {
        const isClient = typeof window === 'object';
        function getSize() {
            return {
                width: isClient ? window.innerWidth : undefined,
                height: isClient ? window.innerHeight : undefined,
            };
        }
        setWindowSize(getSize)
        if (!isClient) {
            return false;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowSize;
}
