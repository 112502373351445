import React, { useEffect, useState } from 'react'
import './SellAssetModal.css'
import Modal from 'react-modal';
import closeIcon from '../../assets/images/close_icon.svg'
import validationOrderImg from '../../assets/images/validation_order.svg'
import '../ButtonComponent/ButtonComponent.css'
import { calculateFee, computeAmountWithPercentage, formatDate, roundDecimal } from '../../lib/number'
import Parse from 'parse';
import { ActivityType, ActivityStatus } from '../../static/Activity'
import { AddNewActivity } from '../../lib/parseTools'
import { getPriceOfCoin } from '../../static/Coins';
// import { notifyError } from '../../lib/notification';
import { useWindowSize } from '../../lib/responsive';
import { notifyError } from '../../lib/notification';
// import { toast } from 'react-toastify';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        borderRaduis: '20px',
        transform: 'translate(-50%, -50%)',
        width: '371px',
        height: '389px',
    }
};

const customStylesStep1 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        borderRaduis: '20px',
        transform: 'translate(-50%, -50%)',
        width: '371px',
        height: '461px',
    }
};

const customStylesMobile = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        borderRaduis: '20px',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        width: '100%'
    }
};

const SellAssetModal = ({ open, coin, close }) => {
    const [modalIsOpen, setIsOpen] = useState(open);
    const [step, setStep] = useState(0);
    const [pair, setPair] = useState({ price: 0 });
    const [amount, setAmount] = useState(0);
    const [wallet, setWallet] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(true);
    const [refresh, setRefresh] = useState(null);
    let windowSize = useWindowSize()

    useEffect(() => {
        async function getWallet() {
            const currentUser = Parse.User.current();
            if (currentUser) {
                setCurrentUser(currentUser);
                const Wallet = Parse.Object.extend("Wallet");
                const query = new Parse.Query(Wallet);
                query.equalTo("objectId", currentUser.get('wallet').id);
                const results = await query.find();
                setWallet(results[0])
            } else {
                console.log('pas connecter')
            }
        }

        async function syncWalletAndTicker() {
            let coinpricevalue = await getPriceOfCoin(coin);
            setPair(coinpricevalue);
            await getWallet()
            setIsLoading(false)
        }
        syncWalletAndTicker()
        setIsOpen(open)
    }, [open, coin])

    useEffect(() => {
        async function updateInterval() {
            let interval = setInterval(async () => {
                let coinpricevalue = await getPriceOfCoin(coin);
                setPair(coinpricevalue);
            }, 1000);
            setRefresh(interval);
        }
        if (step === 1) {
            updateInterval()
        }
    }, [step, coin])


    function closeModal() {
        clearInterval(refresh);
        setIsOpen(false);
        setStep(0);
        close();
    }

    function amountToWantSell() {
        if (amount <= 0) {
            notifyError('❌ Fond insuffisant ❌');
            return
        } if (amount > wallet.get(coin.symbol)) {
            notifyError('❌ Fond insuffisant ❌');
            return
        }
        // if (amount < minimalBuy) {
        //     notifyError('❌ Achat insuffisant ❌');
        //     return
        // }
        else {
            setStep(step + 1)
            finishInterval()
        }
    }

    function finishInterval() {

        if (step === 2) {
            clearInterval(refresh);
        } else { 
            clearInterval(refresh);
        }
    }

    async function subtractToWallet() {
        //TODO faire attention quand reconnection a LAPI
        const eurWallet = wallet.get('EUR');
        const coinWallet = wallet.get(coin.symbol);
        let eurSell = roundDecimal(amount * pair.price, 2);
        let feeEuro = calculateFee(eurSell, Parse.User.current().get('subscription'));
        console.log('FeeEuro ===>', feeEuro)
        let newEurCredited = eurSell - feeEuro;
        let newAmountEurWallet = eurWallet + newEurCredited;
        let newAmountCoinWallet = coinWallet - amount;
        let coinAmount = amount;
        ////
        let transaction = await Parse.Cloud.run('KrakenSell', {messages: 'salut les potes', pair: coin.krakenPair, volume: coinAmount}).catch(error => {
            notifyError('une erreur est survenue !')
            closeModal()
            console.log('Error update wallet: ', error)
            throw new Error('Une erreur est survenue')
        });
        ////
        wallet.set('EUR', roundDecimal(newAmountEurWallet, 2));
        wallet.set(coin.symbol, roundDecimal(newAmountCoinWallet, 7));
        await wallet.save().catch(error => console.log('Error update wallet: ', error));
        // let fee = calculateFee(amount, Parse.User.current().get('subscription'))
        let sell = roundDecimal(amount, 7)
        console.log('SELLL ---->', sell);
        console.log('Pair', pair.price)
        console.log('result', roundDecimal(sell * pair.price, 2))
        setStep(step + 1);
        finishInterval()
        let activity = await AddNewActivity(
            ActivityStatus.VALIDATE,
            currentUser,
            ActivityType.SELL,
            coin,
            parseFloat(coinAmount),
            pair.price,
            feeEuro,
            roundDecimal((sell * pair.price) - feeEuro, 2),
            eurSell.toString(),
            transaction.sell.result.txid[0],
        );
        Parse.Cloud.run("emailTransaction", {
            activityId: activity.id,
            currency: activity.get('currency'),
            payment: 'SOLDE',
            date: formatDate(activity.get('createdAt')),
            totalAmount: activity.get('totalAmount'),
            type: activity.get('type'),
            fee: feeEuro,
            eurAmount: activity.get('eurAmount'),
            email: currentUser.get('email')
        }).then(test => 
            console.log('Email sent', test)
        ).catch(error => console.log("error =>", error));
    }

    function preSetPercentageAmount(percentage) {
        //TODO round decimal 7 car 0.8 round egale zero sur calcul du btc
        setAmount(roundDecimal(computeAmountWithPercentage(wallet.get(coin.symbol), percentage), 7))
    }

    const amountToSell = () => {
        let amountOfCurrency;
        let fiatPriceCurrency;
        let amountCanSell;

        if (!isLoading) {
            amountOfCurrency = wallet.get(coin.symbol);
            fiatPriceCurrency = pair.price;
            amountCanSell = roundDecimal(amountOfCurrency * fiatPriceCurrency);
        }

        return (
            <>
                <div className='buy-modal' style={{ justifyContent: 'space-between' }}>
                    <h1 className="amount-title">Vendre du {coin.name}</h1>
                    <div className='flex-center'>
                        <img alt='logo coin' style={{ paddingRight: '1rem' }} src={coin.logo} />
                        <div>
                            <h2 style={{ color: `${coin.color.end}` }}>{coin.symbol}</h2>
                            <h3>{coin.name}</h3>
                        </div>
                    </div>
                </div>
                <hr />
                <div className='amount-container' style={{ paddingBottom: '1rem' }}>
                    <div className='flex-center' style={{ position: 'relative' }}>
                        <input onChange={(e) => setAmount(e.target.value)} value={amount} className='amount amount-input' placeholder='0' type='number' /><span className='amount'>{coin.symbol}</span>
                    </div>
                    <p>Apercu de la vente {isLoading ? ' ...' : `${amount} = ${roundDecimal(amount * pair.price, 2)}`}€</p>
                    <p style={{ marginTop: '0' }}>Vous pouvez vendre maximum {isLoading ? ' ...' : `${wallet.get(coin.symbol)} = ${amountCanSell}`}€</p>
                    <div className='flex-center justify-space' style={{ backgroundColor: 'rgb(172, 172, 172, 0.2)', borderRadius: '7px' }}>
                        <button className='percentage-sell-button' onClick={() => preSetPercentageAmount(25)}>25%</button>
                        <button className='percentage-sell-button' onClick={() => preSetPercentageAmount(50)}>50%</button>
                        <button className='percentage-sell-button' onClick={() => preSetPercentageAmount(75)}>75%</button>
                        <button className='percentage-sell-button' onClick={() => preSetPercentageAmount(100)}>100%</button>
                    </div>
                    <button onClick={() => amountToWantSell()} className='custom-btn flex-center padding-btn' style={{ textAlign: 'center', fontSize: '20px', marginTop: '2rem' }}>Suivant</button>
                </div>
            </>
        )
    }

    const previewOrder = () => {
        let fee = roundDecimal(amount * 0.05, 7)
        let sell = roundDecimal(amount - fee, 7)
        return (
            <>
                <div className='buy-modal' style={{ justifyContent: 'space-between' }}>
                    <h1 className='order-title'>Aperçu de votre vente</h1>
                </div>
                <hr />
                <div className='order-container'>
                    <div className='flex-center'>
                        <p className='order__total-coin' style={{ backgroundImage: `linear-gradient(45deg,${coin.color.start}, ${coin.color.end})` }}>{sell} {coin.symbol}</p>
                    </div>
                    <div className="flex justify-space">
                        <p className='order__description'>Prix</p>
                        <p className='order__value'>{pair.price} € / {coin.symbol}</p>
                    </div>
                    <div className="flex justify-space">
                        <p className='order__description'>Vente</p>
                        <p className='order__value'>{sell} {coin.symbol} / {roundDecimal(sell * pair.price, 2)} €</p>
                    </div>
                    <div className="flex justify-space">
                        <p className='order__description'>Frais Laverdy.io</p>
                        <p className='order__value'>{fee} {coin.symbol} €</p>
                    </div>
                    <div className="flex justify-space">
                        <p className='order__description'>Total</p>
                        <p className='order__value'>{amount} €</p>
                    </div>
                    <div className='flex-center'>
                        <button onClick={() => subtractToWallet()} className='custom-btn flex-center padding-btn' style={{ textAlign: 'center', fontSize: '20px', marginTop: '2rem' }}>Vendre maintenant</button>
                    </div>
                </div>
            </>
        )
    }

    const validationOrder = () => {
        let eurSell = roundDecimal(amount * pair.price, 2);
        let fee = calculateFee(eurSell, Parse.User.current().get('subscription'))
        let sell =  roundDecimal(eurSell - fee, 2);
        return (
            <>
                <div className='buy-modal' style={{ justifyContent: 'space-between' }}>
                    <h1 className='order-title'>Votre commande est validée</h1>
                </div>
                <hr />
                <div className='validation-order-container flex-center column'>
                    <span className="validation-text">Votre solde a été crédité de</span>
                    <p className='order__total-coin' style={{ backgroundImage: `linear-gradient(45deg,${coin.color.start}, ${coin.color.end})` }}>{sell} €</p>
                    <img style={{ width: '35%' }} alt='validation order' src={validationOrderImg} />
                </div>
            </>
        )
    }

    const sellAsset = () => {
        if (step === 0) {
            return (amountToSell())
        } else if (step === 1) {
            return (previewOrder())
        } else if (step === 2) {
            return (validationOrder())
        }
    }

    function selectStyleOfModal() {
        if (windowSize.width < 1000) {
            return customStylesMobile
        }
        else if (step === 0) {
            return customStylesStep1
        } else {
            return customStyles
        }
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={selectStyleOfModal()}
            contentLabel="Example Modal"
            overlayClassName="Overlay"
            className="Modal"
            shouldCloseOnOverlayClick={false}
        >
            <div style={{ position: 'relative', height: '100%' }}>
                <img onClick={() => closeModal()} className='close-img-modal' src={closeIcon} alt='close icon' />
                {
                    sellAsset()
                }
            </div>
        </Modal>
    )
}

export default SellAssetModal