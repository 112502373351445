import React, { useEffect, useState } from 'react'
import './WithdrawModal.css'
import Modal from 'react-modal';
import closeIcon from '../../assets/images/close_icon.svg'
import validationOrderImg from '../../assets/images/validation_order.svg'
import '../ButtonComponent/ButtonComponent.css'
import Parse from 'parse';
import { notifyError, notifyValidate } from '../../lib/notification';
import { useWindowSize } from '../../lib/responsive';
import { useForm } from 'react-hook-form';
import { roundDecimal } from '../../lib/number';
import { AddNewActivity, AddWithdraw } from '../../lib/parseTools';
import { ActivityStatus, ActivityType } from '../../static/Activity';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        borderRaduis: '20px',
        transform: 'translate(-50%, -50%)',
        width: '371px',
        height: '389px',
    }
};

const customStylesModalInfo = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        borderRaduis: '20px',
        transform: 'translate(-50%, -50%)',
        width: '371px',
        height: '632px',
    }
};

const customStylesMobile = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        borderRaduis: '20px',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        width: '100%'
    }
};

const WithdrawModal = ({ open, close }) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [step, setStep] = useState(0);
    const [amount, setAmount] = useState(0);
    const [wallet, setWallet] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(true);
    let windowSize = useWindowSize()
    const { register, handleSubmit } = useForm();

    useEffect(() => {
        getWallet()
        setIsOpen(open)
    }, [open])

    async function getWallet() {
        const currentUser = Parse.User.current();
        if (currentUser) {
            setCurrentUser(currentUser);
            const Wallet = Parse.Object.extend("Wallet");
            const query = new Parse.Query(Wallet);
            query.equalTo("objectId", currentUser.get('wallet').id);
            const results = await query.find();
            setWallet(results[0])
            setIsLoading(false)
        } else {
            console.log('pas connecter')
        }
    }

    function closeModal() {
        setIsOpen(false)
        setStep(0)
        close()
    }

    function amountToWantbuy() {
        const eurWallet = wallet.get('EUR')
        if (amount <= 0) {
            notifyError('❌ Une erreur est survenue ❌');
            return 
        } if (amount > eurWallet) {
            notifyError('❌ Fond insuffisant ❌');
            return
        } 
        else {
            setStep(step + 1)
        }
    }


    const onSubmit = (info) => {
        subtractToWallet(info)
    }

    async function subtractToWallet(info) {
        const eurWallet = wallet.get('EUR')
        let newAmountWallet = eurWallet - amount;
        wallet.set('EUR', roundDecimal(newAmountWallet, 2))
        await wallet.save().catch(error => console.log('Error update wallet: ', error));
        setStep(step + 1)
        let euroCoin = {
            symbol: 'EUR'
        }
        let activity = await AddNewActivity(
            ActivityStatus.WAITING,
            currentUser,
            ActivityType.WITHDRAW,
            euroCoin,
            parseFloat(amount),
            1,
            0,
            parseFloat(amount),
            amount,
            '',
        );
        await AddWithdraw(
            currentUser,
            amount,
            info.holder,
            info.iban,
            info.bic,
            activity
        )
        notifyValidate('Ca a bien marché')
    }

    const amountToWithdraw = () => {
        return (
            <>
                <div className='buy-modal' style={{ justifyContent: 'space-between' }}>
                    <h1 className="amount-title">Montant du retrait</h1>
                </div>
                <hr />
                <div className='flex-center'>
                    <span className="validation-text">indiquez le montant que vous souhaitez retirer</span>
                </div>
                <div className='amount-container' style={{ paddingTop: '3rem' }}>
                    <div className='flex-center'>
                        <input onChange={(e) => setAmount(e.target.value)} className='amount amount-input' placeholder='0' type='number' /><span className='amount'>€</span>
                    </div>
                    <p>Vous pouvez retirer jusqu’à {isLoading ? ' ...' : wallet.get('EUR')}€</p>
                    <button onClick={() => amountToWantbuy()} className='custom-btn flex-center padding-btn' style={{ textAlign: 'center', fontSize: '20px', marginTop: '2rem' }}>Suivant</button>
                </div>
            </>
        )
    }

    const informationBank = () => {
        return (
            <>
                <div className='buy-modal' style={{ justifyContent: 'space-between' }}>
                    <h1 className='order-title'>Information Bancaire</h1>
                </div>
                <hr />
                <form onSubmit={handleSubmit(onSubmit)} className='flex-center column' style={{padding: '1rem'}}>
                    <span className="validation-text" style={{textAlign: 'left'}}>Attention le nom du détenteur du compte doit exactement correspondre avec vos informations KYC enregistrées.</span>
                    <span className="validation-text" style={{textAlign: 'left'}}>Vous recevrez votre virement dans un délais de 15 jours ouvrés. 25€ de frais de gestion seront imputés si vous n'avez pas effectué achat ou si les informations bancaires sont erronées. Gratuit dans tous les autres cas.</span>
                    <div style={{width: '100%', marginTop: '0.5rem'}}>
                        <span>IBAN</span>
                        <div className="input-module-border" style={{ width: '100%', height: '52px' }}>
                            <input placeholder='FR 76' required {...register("iban")} className='input-module' />
                        </div>
                    </div>
                    <div style={{width: '100%'}}>
                        <span>BIC</span>
                        <div className="input-module-border" style={{ width: '100%', height: '52px' }}>
                            <input placeholder='XXXX' required {...register("bic")} className='input-module' />
                        </div>
                    </div>
                    <div style={{width: '100%'}}>
                    <span>Titulaire</span>
                        <div className="input-module-border" style={{ width: '100%', height: '52px' }}>
                            <input placeholder="Nom Prenom" required {...register("holder")} className='input-module' />
                        </div>
                    </div>
                    <button type='submit' className='custom-btn flex-center padding-btn' style={{ textAlign: 'center', fontSize: '20px', marginTop: '1rem' }}>Je confirme mon retrait</button>
                </form>
            </>
        )
    }

    const validationWithdraw = () => {
        return (
            <>
                <div className='buy-modal' style={{ justifyContent: 'space-between' }}>
                    <h1 className='order-title'>Demande de retrait confirmée</h1>
                </div>
                <hr />
                <div className='validation-order-container flex-center column'>
                    <span style={{ marginBottom: '2rem', marginTop: '1rem' }} className="validation-text">Merci, votre demande de virement est bien prise en compte par nos services.</span>
                    <img alt='validation order' src={validationOrderImg} />
                </div>
            </>
        )
    }

    const withdrawAsset = () => {
        if (step === 0) {
            return (amountToWithdraw())
        } else if (step === 1) {
            return (informationBank())
        } else if (step === 2) {
            return (validationWithdraw())
        }
    }

    function getRigthModalSize() {
        if (windowSize.width < 1000) {
            return customStylesMobile
        } else if (step === 1) {
            return customStylesModalInfo
        } else if (windowSize.width > 1000) {
            return customStyles
        }
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={getRigthModalSize()}
            contentLabel="Example Modal"
            overlayClassName="Overlay"
            className="Modal"
            shouldCloseOnOverlayClick={false}
        >
            <div style={{ position: 'relative', height: '100%' }}>
                <img onClick={() => closeModal()} className="close-img-modal" src={closeIcon} alt='close icon' />
                {
                    withdrawAsset()
                }
            </div>
        </Modal>
    )
}

export default WithdrawModal