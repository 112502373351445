import React, { useEffect, useState } from 'react'
import './WholeValueWalletComponent.css'
import { roundDecimal } from '../../lib/number'
import { listedCoins } from '../../static/Coins'
import Parse from 'parse';

const WholeValueWalletComponent = () => {
    const [wholeWalletValue, setWholeWalletValue] = useState(0);

    useEffect(() => {
        function extractTickers(json) {
            var tickers = Object.keys(json.result).map(key => createTicker(key, json.result[key]))
            tickers.sort((a, b) => b.last24TradeCount - a.last24TradeCount)
            return tickers
        }
        
        async function getAllValue(wallet) {
            let tableAllValue = []
            for (const coin of listedCoins) {
                const jsonValue = await fetch(`https://api.kraken.com/0/public/Ticker?pair=${coin.krakenId}EUR`)
                const price = await jsonValue.json()
                tableAllValue.push(extractTickers(price))
            }
            return (computeWholeWalletValue(tableAllValue, wallet))
        }

        async function getWallet() {
            const currentUser = Parse.User.current();
            if (currentUser) {
                const Wallet = Parse.Object.extend("Wallet");
                const query = new Parse.Query(Wallet);
                query.equalTo("objectId", currentUser.get('wallet').id);
                const results = await query.find();
                getAllValue(results[0])
            } else {
                console.log('pas connecter')
            }
        }

        getWallet()
    }, [])

    function computeWholeWalletValue(coinsValues, wallet) {
        let result = 0;
        let euroWallet = wallet.get('EUR')
        listedCoins.forEach(coin => {
            coinsValues.forEach(value => {
                if (value[0].assetPair === coin.krakenSymbol) {
                    let currentvaluecoin = wallet.get(coin.symbol) * value[0].price
                    result += currentvaluecoin;
                }
            })
        })
        setWholeWalletValue(roundDecimal(result + euroWallet))
        let value = roundDecimal(result + euroWallet)
        return (value)
    }

    function createTicker(key, value) {
        return {
            assetPair: key,
            price: roundDecimal(value.c[0], 2)
        }
    }
    
    return (
        <div className='evolution-number'>
            <p>Évolution de mon portefeuille</p>
            <h1 className='evolution__stats'>{wholeWalletValue}€</h1>
            {/* <span className='evolution_percentage'>14% (+7947.52€)</span> */}
        </div>
    )
}

export default WholeValueWalletComponent