import React, { useEffect } from 'react'
import './DepositFundsModal.css'
import Modal from 'react-modal';
import closeIcon from '../../assets/images/close_icon.svg'
import depositCard from '../../assets/images/deposit-card.svg'
import depositBank from '../../assets/images/deposit-bank.svg'
import BankTransferModal from '../BankTransferModal/BankTransferModal';
import CardTransferModal from '../CardTransferModal/CardTransferModal';
import { useWindowSize } from '../../lib/responsive';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        borderRaduis: '20px',
        transform: 'translate(-50%, -50%)',
        height: '75%',
    }
};

const customStylesMobile = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        borderRaduis: '20px',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        width: '100%'
    }
};

function DepositFundsModal({open, close}) {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [openBankTransfer, setOpenBankTransfer] = React.useState(false);
    const [openCardTransfer, setOpenCardTransfer] = React.useState(false);
    let windowSize = useWindowSize()

    useEffect(() => {
        setIsOpen(open)
    }, [open])

    function closeModal() {
        close()
        setIsOpen(false);
    }

    function openBankTransferModal() {
        setOpenBankTransfer(true)
        closeModal()
    }

    function openCardTransferModal() {
        setOpenCardTransfer(true)
        closeModal()
    }
    

    return (
        <>
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={windowSize.width > 1000 ? customStyles : customStylesMobile}
            contentLabel="Example Modal"
            overlayClassName="Overlay"
            className="Modal"
            shouldCloseOnOverlayClick={false}
        >
            <div className='deposit' style={{ position: 'relative' }}>
                <img onClick={() => closeModal()} className='close-img-modal' src={closeIcon} alt='close icon' />
                <div className='kyc-header'>
                    <h1>Dépôt de fonds</h1>
                    <p>En conformité avec la règlementation française, votre dépôt par virement doit être réalisé par un compte à votre nom. Tous les virements reçus d'un compte qui ne coïncide pas seront retournés.</p>
                </div>
                <hr />
                <div className='deposit'>
                    <div onClick={openCardTransferModal} className="flex-center column deposit-container">
                        <h2>Approvisionner par CB</h2>
                        <p className="deposit__subtitle">Plafond de paiement à 1000€</p>
                        <img src={depositCard} alt='argent img'/>
                        <span>Dépôt Gratuit</span>
                    </div>
                    <div onClick={openBankTransferModal} className="flex-center column deposit-container" style={{marginTop: '1rem', marginBottom: '1rem'}}>
                        <h2>Approvisionner par virement</h2>
                        <p className="deposit__subtitle">Plafond de dépôt limité à 10.000€</p>
                        <img src={depositBank} alt='rib img'/>
                        <span>Dépôt Gratuit</span>
                    </div>
                    <div className="flex-center column deposit__limit-container">
                        <p>Votre plafond de dépôt actuel est de</p>
                        <h3>0 / 10 000€</h3>
                    </div>
                    <div className="flex-center" style={{marginTop: '1rem'}}>
                        <span>lever ma limite</span>
                    </div>
                </div>
            </div>
        </Modal>
        <BankTransferModal open={openBankTransfer} close={() => setOpenBankTransfer(false)}></BankTransferModal>
        <CardTransferModal open={openCardTransfer} close={() => setOpenCardTransfer(false)}></CardTransferModal>
        </>
    );
}

export default DepositFundsModal