import React, { useState } from 'react'
import './SettingsPage.css'
import EditPersonalInfoComponent from '../../components/EditPersonalInfoComponent/EditPersonalInfoComponent'
import VerificationLevelComponent from '../../components/VerificationLevelComponent/VerificationLevelComponent';
import SubscriptionPlanComponent from '../../components/SubscriptionPlanComponent/SubscriptionPlanComponent';


const SettingsPage = () => {
    const [selectedSection, setSelectedSection] = useState(0);

    function onSelectSection(section) {
        switch (section) {
            case 0:
                return <EditPersonalInfoComponent />
            case 1:
                return (<VerificationLevelComponent />)
            case 2:
                return (<SubscriptionPlanComponent />)
            default:
                console.log(`Sorry, we are out of ${section}.`);
        }
    }

    return (
        <div className='padding-setting'>
            <h1 style={{ margin: '0' }}>Réglages</h1>
            <div className="setting-container">
                <div className='flex title-section-container'>
                    <div onClick={() => { setSelectedSection(0) }} style={{ cursor: 'pointer' }}>
                        <h1 className={selectedSection === 0 ? 'title-section-selected' : 'title-section'}>Profil</h1>
                    </div>
                    <div style={{ cursor: 'pointer' }} onClick={() => { setSelectedSection(1) }}>
                        <h1 className={selectedSection === 1 ? 'title-section-selected' : 'title-section'}>Vérifications</h1>
                    </div>
                    <div style={{ cursor: 'pointer' }} onClick={() => { setSelectedSection(2) }}>
                        <h1 className={selectedSection === 2 ? 'title-section-selected' : 'title-section'}>Abonnement</h1>
                    </div>
                </div>
                {
                    onSelectSection(selectedSection)
                }
            </div>
        </div>
    )
}

export default SettingsPage