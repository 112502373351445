import React, { useEffect, useState } from 'react'
import Parse from 'parse';
import './ActivityPage.css'
import ActivityCardComponent from '../../components/ActivityCardComponent/ActivityCardComponent';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import { getKyc } from '../../lib/parseTools';

const ActivityPage = () => {
    const [activities, setActivities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentFilter, setCurrentFilter] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [kyc, setKyc] = useState(null);
    const [page, setPage] = useState(0);
    useEffect(() => {
        async function getActivities() {
            let displayLimit = 10;
            setCurrentUser(Parse.User.current())
            setIsLoading(true)
            var query = new Parse.Query("Activity");
            query.equalTo("createdBy", Parse.User.current());
            query.descending('updatedAt');
            // query.ascending('updatedAt');
            query.limit(displayLimit);
            query.skip(page * displayLimit)
            if (currentFilter) {
                query.greaterThan("createdAt", createDate(currentFilter));
            }
            const subscription = await query.subscribe();
            let currentActivities = {};
            let activityList = []
            const printcurrentActivities = () => {
                activityList = []
                Object.keys(currentActivities).forEach(id => {
                    activityList.unshift({
                        currency: currentActivities[id].toJSON().currency,
                        id: id,
                        status: currentActivities[id].toJSON().status,
                        amount: currentActivities[id].toJSON().amount,
                        pairPrice: currentActivities[id].toJSON().pairPrice,
                        fee: currentActivities[id].toJSON().fee,
                        eurAmount: currentActivities[id].toJSON().eurAmount,
                        totalAmount: currentActivities[id].toJSON().totalAmount,
                        type: currentActivities[id].toJSON().type,
                        createdAt: currentActivities[id].toJSON().createdAt
                    })
                });
                setActivities(activityList.reverse());
            };
            subscription.on('open', async () => {
                currentActivities = {};
                currentActivities = (await query.find()).reduce((currentActivities, todo) => ({
                    ...currentActivities,
                    [todo.id]: todo
                }), currentActivities);
                printcurrentActivities();
                let value = await getKyc(Parse.User.current().get('kyc').id)
                setKyc(value)
                setIsLoading(false)
            });
            subscription.on('create', activity => {
                currentActivities[activity.id] = activity;
                printcurrentActivities();
            });
        }
        getActivities()
    }, [currentFilter, page])

    function createDate(filter) {
        var newDate = new Date(new Date().setHours(0,0,0,0))
        if (filter === 'week') {
            newDate.setDate(newDate.getDate() - 7 * 1);
            return newDate
        } else if (filter === 'month') {
            newDate.setMonth(newDate.getMonth() - 1);
            return newDate
        } else if (filter === 'year') {
            newDate.setMonth(newDate.getMonth() - 12);
            return newDate
        }
    }


    return (
        <div className='activity-page'>
            <h1 style={{ marginTop: '0' }}>Mon Activité</h1>
            <div className='flex-center' style={{ marginTop: '4rem' }}>
                <div onClick={() => setCurrentFilter('week')} className={currentFilter === 'week' ? 'activity-filter-node-select' : 'activity-filter-node'}>SEMAINE</div>
                <div onClick={() => setCurrentFilter('month')} className={currentFilter === 'month' ? 'activity-filter-node-select' : 'activity-filter-node'}>MOIS</div>
                <div onClick={() => setCurrentFilter('year')} className={currentFilter === 'year' ? 'activity-filter-node-select' : 'activity-filter-node'}>ANNÉE</div>
            </div>
            <div>
                {isLoading ? <LoadingComponent height={70}/> :
                    activities.map(
                        (activity, index) => {
                            return (
                                <div key={index}>
                                    <ActivityCardComponent activityPage={true} activity={activity} currentUser={currentUser} kyc={kyc} />
                                </div>
                            )
                        }
                    )
                }
            </div>
            <div className='flex justify-space'>
                <p style={{cursor: 'pointer'}} onClick={() => setPage(page === 0 ? 0 : page - 1)}>{`< Précédent`}</p>
                <p style={{cursor: 'pointer'}} onClick={() => setPage(page + 1)}>{`Suivant >`}</p>
            </div>
        </div>
    )
}

export default ActivityPage