import React, { useEffect, useState } from 'react'
import './WalletCardComponent.css'
import { roundDecimal, computeCoinToEur } from '../../lib/number'
import GraphCoinComponent from '../GraphCoinComponent/GraphCoinComponent'
import { useHistory } from 'react-router'
import { notifyError } from '../../lib/notification'


const WalletCardComponent = ({ coin, wallet, validateUser }) => {
    const [pair, setPair] = useState({
        price: 0
    });
    const [isValidate, setIsValidate] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setIsValidate(validateUser)
        let currentCoin = coin
        function extractTickers(json) {
            var tickers = Object.keys(json.result).map(key => createTicker(key, json.result[key]))
            tickers.sort((a, b) => b.last24TradeCount - a.last24TradeCount)
            return tickers
        }

        function createTicker(key, value) {
            return {
                assetPair: assetPair(key),
                price: roundDecimal(value.c[0], 2)
            }
        }

        function assetPair(s) {
            return s.replace(/[XZ]([A-Z]{3})[XZ]([A-Z]{3})/g, '$1/$2')
        }

        fetch(`https://api.kraken.com/0/public/Ticker?pair=${currentCoin.krakenId}EUR`)
            .then(response => response.json())
            .then(data => {
                setPair(extractTickers(data))
            });
    }, [coin, validateUser])

    return (
        <>
            {wallet.get(coin.symbol) > 0 &&
                <div className='box-wallet'>
                    <div className='flex' style={{ justifyContent: 'space-between', position: 'relative' }}>
                        {
                            pair[0] && <p className='current-asset-price'>{pair[0].price}€</p>
                        }
                        <div>
                            <div className='flex'>
                                <img onClick={() => history.push(`/wallet/${coin.symbol}`)} style={{ marginRight: '1rem', cursor: 'pointer' }} src={coin.logo} alt='eth icon' />
                                <div>
                                    <h1 onClick={() => history.push(`/wallet/${coin.symbol}`)} style={{ color: coin.color.end, cursor: 'pointer' }}>{coin.symbol}</h1>
                                    <h2 onClick={() => history.push(`/wallet/${coin.symbol}`)} style={{ cursor: 'pointer' }}>{coin.name}</h2>
                                </div>
                            </div>
                            <div>
                                {
                                    pair[0] && <p className='value' style={{ color: coin.color.end }}>{computeCoinToEur(pair[0].price, wallet.get(coin.symbol))} €</p>
                                }
                                <p className='nbrofcurrency'>{wallet.get(coin.symbol)} {coin.symbol}</p>
                            </div>
                        </div>
                        <div className="flex justify-center" style={{ marginTop: '1rem', width: '100%' }}>
                            <GraphCoinComponent coin={coin} width={250} height={100} />
                        </div>
                    </div>
                    <div>
                        {
                            !isValidate ? <p className="wallet-gestion" onClick={() => notifyError('Vous ne pouvez pas effectuer cette action, votre compte est en cours de vérification.')} style={{ color: '#666666', cursor: 'pointer' }}>Gérer mon portefeuille</p> :
                                <p onClick={() => history.push(`/wallet/${coin.symbol}`)} className="wallet-gestion" style={{ color: coin.color.end, cursor: 'pointer' }}>Gérer mon portefeuille</p>
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default WalletCardComponent