import React, { useEffect, useState } from 'react'
import './MarketplaceListComponent.css'
import { listedCoins } from '../../static/Coins'
import MarketplaceCardComponent from '../MarketplaceCardComponent/MarketplaceCardComponent'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import { useHistory } from 'react-router-dom'
import Parse from 'parse';
import { checkAccountValidate } from '../../static/Kyc'

const MarketplaceListComponent = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [isValidate, setIsValidate] = useState(false);
    useEffect(() => {
        const currentUser = Parse.User.current();
        async function checkAccount() {
            let check = await checkAccountValidate();
            setIsValidate(check);
            setIsLoading(false)
        }

        if (currentUser) {
            checkAccount();
        } else {
            history.push('/signin')
        }
    }, [history])
    return (
        <>
            {
                isLoading ? <LoadingComponent height={100} /> :
                <div className='market-list-container'>
                    <div>
                        {
                            listedCoins.map((coin, index) => {
                                return (
                                    <div key={index}>
                                        <MarketplaceCardComponent coin={coin} validateUser={isValidate} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default MarketplaceListComponent