import React, { useEffect, useState } from 'react'
import ActivityCardComponent from '../ActivityCardComponent/ActivityCardComponent'
import './ActivityComponent.css'
import Parse from 'parse';
import { useHistory } from 'react-router';
import LoadingComponent from '../LoadingComponent/LoadingComponent';

const ActivityComponent = () => {
    const [activities, setActivities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        setIsLoading(true)
        getActivities()
    }, [])

    async function getActivities() {
        var query = new Parse.Query("Activity");
        query.equalTo("createdBy", Parse.User.current());
        query.ascending("createdAt");
        const subscription = await query.subscribe();
        let currentActivities = {};
        let activityList = []
        const printcurrentActivities = () => {
            activityList = []
            Object.keys(currentActivities).forEach(id => {
                activityList.unshift({
                    currency: currentActivities[id].toJSON().currency,
                    status: currentActivities[id].toJSON().status,
                    amount: currentActivities[id].toJSON().amount,
                    type: currentActivities[id].toJSON().type,
                    createdAt: currentActivities[id].toJSON().createdAt
                })
            });
            setActivities(activityList);
        };
        subscription.on('open', async () => {
            currentActivities = {};
            currentActivities = (await query.find()).reduce((currentActivities, todo) => ({
                ...currentActivities,
                [todo.id]: todo
            }), currentActivities);
            printcurrentActivities();
            setIsLoading(false)
        });
        subscription.on('create', activity => {
            currentActivities[activity.id] = activity;
            printcurrentActivities();
        });
    }

    return (
        <div className='activity-container'>
            <h1 className='title'>Dernières activités:</h1>
            <div style={{ paddingRight: '1rem', paddingLeft: '1rem' }}>
                {isLoading ? <LoadingComponent height={50}/> :
                    activities.map(
                        (activity, index) => {
                            return (
                                <div className={index > 4 ? 'activity-none' : ''} key={index}>
                                    <ActivityCardComponent activity={activity} />
                                </div>
                            )
                        }
                    )
                }
                <p className="watch-more-activity" onClick={() => history.push('/activity')}>Voir toutes les activitées</p>
            </div>
        </div>
    )
}

export default ActivityComponent