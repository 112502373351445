import React from 'react'
import './CGVpage.css'

const CGVpage = () => {
    return (
        <div style={{ padding: '3rem' }}>
            <p><strong>Conditions G&eacute;n&eacute;rales</strong></p>
            <p>Mise &agrave; jour : 01/06/2021</p>
            <p>&nbsp;</p>
            <p>LAVERDY.IO exploit&eacute; par Laverdy Capital qui est une soci&eacute;t&eacute; par actions simplifi&eacute;e au capital de 5.500 &euro; dont le si&egrave;ge social est situ&eacute; 10 rue de penthi&egrave;vre 75008 Paris, France immatricul&eacute;e sous le num&eacute;ro 834 900 219 RCS de PARIS (ci-apr&egrave;s &laquo; LAVERDY CAPITAL &raquo;), en cours d&rsquo;enregistrement aupr&egrave;s de l&rsquo;AMF en tant que Prestataire de Services sur Actifs Num&eacute;riques, &eacute;galement appel&eacute;s Crypto-actifs, permet &agrave; une client&egrave;le compos&eacute;e de Consommateurs et de personnes morales (les &laquo;&nbsp;Clients &raquo;) d&rsquo;acc&eacute;der &agrave; des Services (tel que ce terme est d&eacute;fini ci-apr&egrave;s) relatifs aux Crypto-actifs. LAVERDY permet &agrave; ses clients de r&eacute;aliser ces Services &agrave; travers son Site Internet et par le biais de Charg&eacute; de Client&egrave;le.</p>
            <p>&nbsp;</p>
            <ol>
                <li><strong>Avertissement sur les facteurs de risques</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>Les Crypto-actifs et les technologies sur lesquelles ils reposent sont innovants et relativement r&eacute;cents. Quels qu&rsquo;en soient leurs usages (valeur d&rsquo;investissement, valeur d&rsquo;&eacute;change, repr&eacute;sentation d&rsquo;un droit, &hellip;), les Crypto-actifs et leur utilisation sont porteurs de plusieurs risques, dont sans que cette liste soit exhaustive&nbsp;: le risque de march&eacute;, de liquidit&eacute;, de volatilit&eacute;, de perte totale des fonds en cas d&rsquo;erreur de manipulation ou de perte de la cl&eacute; priv&eacute;e. Ces risques sont list&eacute;s et expliqu&eacute;s sur notre Site Internet dans la section &laquo;&nbsp;Conformit&eacute; / Compliance&nbsp;&raquo; (<a href="https://.laverdy.io/conformite/">https://.laverdy.io/conformite/</a>).&nbsp;</p>
            <p>L&rsquo;investissement dans les Crypto-actifs ne convient donc pas &agrave; tous les Clients. Il revient aux Clients de s&rsquo;informer par eux-m&ecirc;mes sur les diff&eacute;rents Crypto-actifs, les technologies sous-jacentes, et de comprendre ce qu&rsquo;est un Portefeuille (tel que ce terme est d&eacute;fini ci-apr&egrave;s), de savoir comment le manipuler et notamment comment r&eacute;aliser des d&eacute;p&ocirc;ts et retraits de Crypto-actifs. LAVERDY met gratuitement &agrave; disposition sur son Site Internet et actualise des contenus p&eacute;dagogiques et des articles r&eacute;dig&eacute;s par ses &eacute;quipes afin d&rsquo;accompagner ses Clients. La rubrique Aide/F.A.Q. regroupe des r&eacute;ponses &agrave; de tr&egrave;s nombreuses questions.&nbsp;&nbsp;&nbsp;</p>
            <p>LAVERDY ne peut en aucun cas &ecirc;tre tenu pour responsable des dommages provoqu&eacute;s par une erreur d&rsquo;adresse de Portefeuille imputable au Client.&nbsp;</p>
            <p>Sur son Site Internet et ses diff&eacute;rents supports, LAVERDY est susceptible d&rsquo;indiquer les performances pass&eacute;es de la valorisation d&rsquo;un Crypto-actif donn&eacute; (notamment son Prix tel que ce terme est d&eacute;fini ci-apr&egrave;s). LAVERDY rappelle &agrave; ses Clients que les performances pass&eacute;es ne pr&eacute;jugent pas des performances futures, que les Prix des Crypto-actifs sont particuli&egrave;rement volatils (&agrave; titre indicatif, ils peuvent varier de plus de + ou &ndash; 30% en une journ&eacute;e) et qu&rsquo;en r&egrave;gle g&eacute;n&eacute;rale, Il n&rsquo;y a pas de performance &eacute;lev&eacute;e sans risque &eacute;lev&eacute;. &nbsp;</p>
            <ol start="2">
                <li><strong>D&eacute;finitions</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p><strong>Achat de Crypto-actifs, Achat, ordre d&rsquo;Achat :</strong>&nbsp;acquisition par un Client d&rsquo;une quantit&eacute; de Crypto-actif vendue par LAVERDY contre un paiement en euros par un moyen de paiement accept&eacute; sur le Site Internet.&nbsp;</p>
            <p><strong>Avis Op&eacute;ration</strong>&nbsp;: document envoy&eacute; par un Charg&eacute; de Client&egrave;le pour signature &eacute;lectronique par le Client en lien avec la r&eacute;alisation de certains Services. L&rsquo;envoi de ce document vaut pour preuve de l&rsquo;ex&eacute;cution du Service consid&eacute;r&eacute;.</p>
            <p><strong>Charg&eacute; de Client&egrave;le&nbsp;:&nbsp;</strong>Op&eacute;rateur accompagnant les Clients dans la r&eacute;alisation Services et le conseil sur leurs investissements en Crypto-actifs. Les Charg&eacute;s de Client&egrave;le, sp&eacute;cialement form&eacute;s et identifi&eacute;s comme tels, peuvent intervenir &agrave; distance (email, t&eacute;l&eacute;phone ou syst&egrave;me vid&eacute;o par internet) apr&egrave;s avoir identifi&eacute; le Client ou en pr&eacute;sence du Client.&nbsp;</p>
            <p><strong>Compensation d&rsquo;un Montant d&rsquo;Achat :</strong>&nbsp;LAVERDY calcule la quantit&eacute; de Crypto-actif &agrave; recevoir par le Client lors d&rsquo;un Achat en divisant le Montant d&rsquo;Achat exprim&eacute; en euros par le Prix du Crypto-actif fix&eacute; par LAVERDY au moment de la validation de l&rsquo;Achat par le Client.&nbsp;</p>
            <p><strong>Compensation d&rsquo;une Vente :</strong>&nbsp;LAVERDY calcule le montant vers&eacute; au Client lors d&rsquo;une Vente en multipliant la quantit&eacute; donn&eacute;e de Crypto-actif du Client disponible dans le cadre de la Vente par un Cours du Crypto-actif fix&eacute; par LAVERDY au moment de la validation de la Vente, auquel sont d&eacute;duits les Frais de Vente.&nbsp;</p>
            <p><strong>Conditions Particuli&egrave;res&nbsp;:&nbsp;</strong>sont consid&eacute;r&eacute;es comme Conditions Particuli&egrave;res les mentions et stipulations diverses pr&eacute;cisant toutes les caract&eacute;ristiques contractuelles sp&eacute;cifiques aux CG de LAVERDY. Les Conditions Particuli&egrave;res s&rsquo;ajoutent ou modifient les CG dans le but de les adapter au cas particulier d&rsquo;un Client et/ou d&rsquo;un Service. Les Conditions Particuli&egrave;res propos&eacute;es par LAVERDY peuvent &ecirc;tre pr&eacute;cis&eacute;es dans un document ou contrat sp&eacute;cifique sign&eacute; par le Client ou sur tout support durable propos&eacute; par LAVERDY (y compris syst&egrave;me de signature &eacute;lectronique) et valid&eacute;es par le Client qui les accepte.&nbsp;&nbsp;&nbsp;&nbsp;</p>
            <p><strong>Conservation de l&rsquo;acc&egrave;s aux Crypto-actifs ou &laquo;&nbsp;Conservation &raquo; ou service de &laquo;&nbsp;Stockage&nbsp;&raquo; de Crypto-actifs :&nbsp;</strong>service fourni par LAVERDY &agrave; ses clients, visant &agrave; garder et s&eacute;curiser l&rsquo;acc&egrave;s &agrave; leurs Crypto-actifs. Les Crypto-actifs gard&eacute;s par LAVERDY pour le compte de son Client sont uniquement accessibles au Client suivant des proc&eacute;dures d&eacute;finies par LAVERDY et accept&eacute;es par le Client qui accepte les pr&eacute;sentes CG.&nbsp;</p>
            <p><strong>Prix d&rsquo;un Crypto-actif ou Prix :</strong>&nbsp;contre-valeur en euros ou dans un autre Crypto-actif donn&eacute; d&rsquo;une unit&eacute; du Crypto-actif concern&eacute; propos&eacute;e au client, telle que fix&eacute; par LAVERDY sur demande du client.</p>
            <p>Le Prix d&rsquo;un Crypto-actif est fix&eacute; par LAVERDY par agr&eacute;gation des diff&eacute;rentes contre-valeurs du Crypto-actif consid&eacute;r&eacute; sur des places de march&eacute; de renom ou des fournisseurs de liquidit&eacute; s&eacute;lectionn&eacute;s par LAVERDY, ajust&eacute; d&rsquo;une marge technique variant en fonction des facteurs suivants&nbsp;: nature du Crypto-actif, prix et liquidit&eacute; de l&rsquo;actif sur les places de march&eacute; et les fournisseurs de liquidit&eacute; partenaires de LAVERDY, quantit&eacute; requise par le client, nature de la relation d&rsquo;affaires entre le client et LAVERDY ou tout autre crit&egrave;re pertinent d&eacute;termin&eacute; au moment de la fixation du Prix.</p>
            <p>Le Prix d&rsquo;un Crypto-actif est indiqu&eacute; &agrave; diff&eacute;rents endroits sur le Site Internet, il est mis &agrave; jour r&eacute;guli&egrave;rement (g&eacute;n&eacute;ralement &agrave; moins de quelques minutes d&rsquo;intervalle). Lorsqu&rsquo;il n&rsquo;est pas indiqu&eacute; dans une proc&eacute;dure li&eacute;e &agrave; un Achat, une Vente ou une op&eacute;ration de Conversion, le Prix d&rsquo;un Crypto-actif est donn&eacute; &agrave; titre indicatif et peut repr&eacute;senter une valeur moyenne entre une op&eacute;ration d&rsquo;Achat et de Vente de ce Crypto-Actif, et donc diff&eacute;r&eacute; du Prix qui sera retenu lors de la Transaction. Seul le Prix retenu lors de la Transaction compte dans sa comptabilisation et pour l&rsquo;application des pr&eacute;sentes CG.&nbsp;</p>
            <p>Sauf mention contraire ou Conditions Particuli&egrave;res, LAVERDY ne peut donc garantir par avance le Prix du Crypto-actif au titre duquel une Transaction s&rsquo;effectue.</p>
            <p><strong>Crypto-actif ou Crypto-monnaie ou Actif Num&eacute;rique :</strong>&nbsp;est une repr&eacute;sentation num&eacute;rique de valeurs ou droits pouvant &ecirc;tre transf&eacute;r&eacute;s et stock&eacute;s &eacute;lectroniquement, en utilisant la blockchain ou une technologie similaire. Les actifs num&eacute;riques sont d&eacute;finis juridiquement comme&nbsp;:</p>
            <ul>
                <li>Les jetons mentionn&eacute;s &agrave; l&rsquo;article L. 552-2 du Code Mon&eacute;taire et Financier, &agrave; l&rsquo;exclusion de ceux remplissant les caract&eacute;ristiques des instruments financiers mentionn&eacute;s &agrave; l&rsquo;article L. 211-1 et des bons de caisse mentionn&eacute;s &agrave; l&rsquo;article L. 223-1 ;</li>
                <li>Toute repr&eacute;sentation num&eacute;rique d&rsquo;une valeur qui n&rsquo;est pas &eacute;mise ou garantie par une banque centrale ou par une autorit&eacute; publique, qui n&rsquo;est pas n&eacute;cessairement attach&eacute;e &agrave; une monnaie ayant cours l&eacute;gal et qui ne poss&egrave;de pas le statut juridique d&rsquo;une monnaie, mais qui est accept&eacute;e par des personnes physiques ou morales comme un moyen d&rsquo;&eacute;change et qui peut &ecirc;tre transf&eacute;r&eacute;e, stock&eacute;e ou &eacute;chang&eacute;e &eacute;lectroniquement.</li>
            </ul>
            <p>Il est pr&eacute;cis&eacute; que cette d&eacute;finition juridique est susceptible d&rsquo;&eacute;voluer dans le temps. Les actifs num&eacute;riques n&rsquo;ob&eacute;issent pas &agrave; une d&eacute;finition juridique unique. La qualification juridique qui leur est applicable, et le r&eacute;gime r&eacute;glementaire aff&eacute;rent, doivent &ecirc;tre appr&eacute;ci&eacute;s au cas par cas et&nbsp;<em>in concreto</em>, compte tenu des caract&eacute;ristiques sp&eacute;cifiques de chaque projet (<em>rapport ESMA, Advice on Initial Coin Offerings and Crypto-assets, 9 January 2019&nbsp;; et rapport EBA, Report with Advice for the European Commission on crypto-assets, 9 January 2018</em>).&nbsp;</p>
            <p>LAVERDY propose diff&eacute;rents types de Crypo-actifs, tels que le Bitcoin, l&rsquo;Ethereum et d&rsquo;autres stablecoins. La liste est disponible sur le Site Internet et sur demande au Service Client.&nbsp;</p>
            <p><strong>CG :</strong>&nbsp;correspond aux pr&eacute;sentes conditions g&eacute;n&eacute;rales de LAVERDY r&eacute;gissant les Services applicables &agrave; tous les Clients de LAVERDY, sauf Conditions Particuli&egrave;res. Les CG sont disponibles et t&eacute;l&eacute;chargeables sur le Site Internet&nbsp;; elles sont &eacute;galement envoy&eacute;es au Client par messagerie &eacute;lectronique. Le client doit notamment les valider lors de la cr&eacute;ation de son Compte.&nbsp;&nbsp;</p>
            <p><strong>Client :</strong>&nbsp;Le Client peut &ecirc;tre un Consommateur au sens du Code de la consommation, un non-professionnel, une personne morale. Le Client personne physique doit &ecirc;tre majeur au sens de l&rsquo;article 414 du Code civil et ne doit pas &ecirc;tre frapp&eacute; d&rsquo;une incapacit&eacute; list&eacute;e &agrave; l&rsquo;article 1146 du Code civil.&nbsp;</p>
            <p><strong>Compte Client ou Compte :</strong>&nbsp;signifie un compte nominatif unique et personnel permettant d&rsquo;acc&eacute;der aux services de LAVERDY sur son Site Internet. L&rsquo;identifiant d&rsquo;un Compte Client est une adresse de messagerie &eacute;lectronique. Un Compte Client est prot&eacute;g&eacute; par un mot de passe qui doit respecter le format impos&eacute;. LAVERDY proc&egrave;de &agrave; des V&eacute;rifications de Connexion et recommande tr&egrave;s fortement &agrave; ses clients l&rsquo;utilisation d&rsquo;une double authentification (&laquo;&nbsp;<strong>2FA</strong>&nbsp;&raquo;) pour acc&eacute;der au Compte Client sur le Site et r&eacute;aliser des Transactions et Retraits, modifier et fournir des informations du Compte. Par d&eacute;faut, un Client dispose d&rsquo;un Compte Client dit &laquo;&nbsp;<strong>Classique&nbsp;</strong>&raquo;.&nbsp;</p>
            <p><strong>Compte Client valid&eacute; :</strong>&nbsp;un Compte Client est valid&eacute; quand le Client re&ccedil;oit un message &eacute;lectronique de LAVERDY l&rsquo;informant de sa validation ou en est inform&eacute; par un Charg&eacute; de Client&egrave;le. LAVERDY d&eacute;cide seul &agrave; travers son D&eacute;partement Conformit&eacute; et Gestion des Risques de la validation d&rsquo;un Compte Client sur la base des donn&eacute;es d&rsquo;identification du Client, de sa politique LCB-FT et des informations fournies par ce dernier. Seul un Client disposant d&rsquo;un Compte Client valid&eacute; peut acc&eacute;der au Service.&nbsp;</p>
            <p><strong>Compte Crypto&nbsp;:&nbsp;</strong>signifie un compte de tenue de position dans lequel est inscrit la quantit&eacute; d&rsquo;un Crypto-actif donn&eacute; d&eacute;tenue par un Client et mise en Conservation chez LAVERDY, ainsi que sa contre-valeur en monnaie ayant cours l&eacute;gal bas&eacute; sur un Prix fourni &agrave; titre indicatif. Chaque Client dispose de plusieurs Comptes Crypto associ&eacute;s au Compte Client valid&eacute;, dont le nombre est &eacute;gal au nombre de Crypto-actifs diff&eacute;rents disponibles pour r&eacute;aliser des Transactions sur le Site Internet.&nbsp;</p>
            <p><strong>Compte payant&nbsp;</strong>: d&eacute;signe une offre commerciale propos&eacute;e par LAVERDY &agrave; ses Clients. Il s&rsquo;agit notamment des Comptes Premium, Business Premium, Platinum, etc&hellip; Cette offre est valable pendant la dur&eacute;e indiqu&eacute;e lors de sa souscription et apr&egrave;s paiement par le Client concern&eacute; d&rsquo;un droit d&rsquo;entr&eacute;e non remboursable fix&eacute; par LAVERDY. L&rsquo;offre permet aux b&eacute;n&eacute;ficiaires d&rsquo;acc&eacute;der &agrave; des services suppl&eacute;mentaires d&eacute;crits sur le Site Internet pendant la dur&eacute;e o&ugrave; la souscription est effective et le Compte Client est valid&eacute;. L&rsquo;adh&eacute;sion au Statut Premium est valid&eacute;e par le D&eacute;partement Conformit&eacute; et Gestion des Risques de LAVERDY, qui se r&eacute;serve le droit de refuser et de suspendre une adh&eacute;sion, sans &ecirc;tre tenu de rembourser le Client du montant du droit d&rsquo;entr&eacute;e, r&eacute;put&eacute; non remboursable. L&rsquo;offre et ses conditions d&rsquo;&eacute;ligibilit&eacute; sont indiqu&eacute;es sur le Site Internet et sont disponibles sur demande formul&eacute;e aupr&egrave;s du Service Client.&nbsp;</p>
            <p><strong>Consommateur :</strong>&nbsp;signifie au sens des dispositions du Code de la consommation, toute personne physique qui agit &agrave; des fins qui n&rsquo;entrent pas dans le cadre de son activit&eacute; commerciale, industrielle, artisanale, lib&eacute;rale ou agricole.</p>
            <p><strong>Conversion ou op&eacute;ration de Conversion :</strong>&nbsp;service propos&eacute;e par LAVERDY consistant &agrave; &eacute;changer une quantit&eacute; d&rsquo;un type de Crypto-actif en une quantit&eacute; d&rsquo;un autre Crypto-actif.&nbsp;</p>
            <p><strong>D&eacute;faillance Technique :</strong>&nbsp;d&eacute;signe l&rsquo;un des cas suivant r&eacute;sultant d&rsquo;un fait non imputable &agrave; LAVERDY: indisponibilit&eacute; de l&rsquo;acc&egrave;s au r&eacute;seau t&eacute;l&eacute;phonique ou &agrave; l&rsquo;Internet par LAVERDY ou le Client, indisponibilit&eacute; d&rsquo;acc&eacute;der aux donn&eacute;es de LAVERDY h&eacute;berg&eacute;s par des tiers pour des raisons imputables &agrave; ces tiers, par des indisponibilit&eacute; de l&rsquo;acc&egrave;s aux Portefeuilles de LAVERDY, probl&egrave;me technique inh&eacute;rent au fonctionnement d&rsquo;une Blockchain ou d&rsquo;un &laquo;&nbsp;smart contract&nbsp;&raquo;, indisponibilit&eacute; de l&rsquo;acc&egrave;s &agrave; des plateformes de partenaires ou places de march&eacute;s pour l&rsquo;achat ou la vente de Crypto-actifs</p>
            <p>op&eacute;r&eacute;e par LAVERDY pour son compte propre, indisponibilit&eacute; ou perte par le Client de l&rsquo;acc&egrave;s &agrave; son Portefeuille, faits r&eacute;sultant de l&rsquo;action de Hackers, d&rsquo;une atteinte logique ou d&rsquo;un virus informatique ou de tout autre malveillance sur les syst&egrave;mes informatiques du Client, sur ceux des partenaires techniques, ou des places de march&eacute; utilis&eacute;s par LAVERDY pour les Transactions et la Conservation.</p>
            <p><strong>2FA&nbsp;(double authentification&nbsp;ou&nbsp;v&eacute;rification en deux &eacute;tapes)&nbsp;:</strong>&nbsp;Il s&rsquo;agit d&rsquo;une m&eacute;thode par laquelle un Client peut acc&eacute;der &agrave; son Compte Client, modifier des informations de son Compte, fournir une adresse de Portefeuille, r&eacute;aliser une Transaction ou un Retrait seulement apr&egrave;s avoir pr&eacute;sent&eacute; deux modes d&rsquo;identification distincts &agrave; un m&eacute;canisme d&rsquo;<a href="https://fr.wikipedia.org/wiki/Authentification">authentification</a>. Cet &eacute;l&eacute;ment de s&eacute;curit&eacute; est fortement recommand&eacute; pour l&rsquo;ensemble des Clients de LAVERDY.&nbsp;</p>
            <p><strong>D&eacute;p&ocirc;t d&rsquo;un Crypto-actif&nbsp;:&nbsp;</strong>service propos&eacute; par LAVERDY pour certains Crypto-actifs uniquement visant &agrave; permettre &agrave; un Client d&rsquo;envoyer lui-m&ecirc;me depuis son Portefeuille &agrave; une adresse de Portefeuille fournie par LAVERDY une quantit&eacute; d&rsquo;un Crypto-Actif qu&rsquo;il d&eacute;tient pour &ecirc;tre mise en Conservation chez LAVERDY dans le Compte Crypto du Client.</p>
            <p><strong>Fork&nbsp;ou bifurcation d&rsquo;une blockchain&nbsp;:</strong>&nbsp;d&eacute;signe l&rsquo;&eacute;v&egrave;nement par lequel une mise &agrave; jour du protocole d&rsquo;une blockchain est accept&eacute;e et impl&eacute;ment&eacute;e uniquement par une partie de la communaut&eacute; des parties impliqu&eacute;es dans la gestion, la validation et la propagation de ce protocole. Cet &eacute;v&egrave;nement aboutit de fait &agrave; la cr&eacute;ation d&rsquo;une nouvelle blockchain, qui partage un historique commun avec la blockchain originale jusqu&rsquo;au moment de l&rsquo;&eacute;v&eacute;nement de bifurcation, mais qui n&rsquo;a plus rien de compatible apr&egrave;s cet &eacute;v&eacute;nement.</p>
            <p><strong>Frais sur Transaction ou Commission :</strong>&nbsp;montant en euros, ou le cas &eacute;ch&eacute;ant en Crypto-actif, per&ccedil;u par LAVERDY en r&eacute;mun&eacute;ration de la r&eacute;alisation d&rsquo;une Transaction. La Commission varie en fonction de la nature de la Transaction, du type de Compte Client (classique, payant), du montant de la Transaction et du type Crypto-actif. Elle est pr&eacute;sent&eacute;e et valid&eacute;e explicitement par le client &agrave; chaque Transaction. La m&eacute;thodologie de calcul est pr&eacute;cis&eacute;e &agrave; l&rsquo;article 9.&nbsp;</p>
            <p><strong>Frais d&rsquo;Intervention</strong>&nbsp;: frais pr&eacute;lev&eacute;s par LAVERDY au Client au titre de la gestion d&rsquo;un impay&eacute;, d&rsquo;un incident de paiement, d&rsquo;un incident sur le Compte Client, de la gestion d&rsquo;une Transaction n&rsquo;ayant pas abouti en raison d&rsquo;une erreur ou omission du Client ou en absence de justificatifs conformes fournies au Service Conformit&eacute; et Gestion des Risques, en cas d&rsquo;abus d&rsquo;un Client, ou pour tout autre raison mentionn&eacute;e dans les CG. Le montant des Frais d&rsquo;Intervention s&rsquo;exprime en euros calcul&eacute; comme un pourcentage du montant de la Transaction ou en montant &eacute;quivalent en Crypto-actif, ou le &eacute;ch&eacute;ant par un montant fixe.&nbsp;</p>
            <p><strong>Frais de Retrait :</strong>&nbsp;frais pr&eacute;lev&eacute;s par LAVERDY pour le Retrait d&rsquo;un Crypto-actif sur un Portefeuille externe en raison des co&ucirc;ts associ&eacute;s &agrave; la validation par des syst&egrave;mes informatiques de la blockchain de l&rsquo;op&eacute;ration de transfert de la quantit&eacute; de Crypto-actif vers le Portefeuille du Client.&nbsp;</p>
            <p><strong>Force Majeure :</strong>&nbsp;au terme de l&rsquo;article 1218 du Code civil, &eacute;v&eacute;nement &eacute;chappant au contr&ocirc;le de son d&eacute;biteur, qui ne pouvait &ecirc;tre raisonnablement pr&eacute;vu lors de l&rsquo;entr&eacute;e en vigueur des CG et dont les effets ne peuvent &ecirc;tre &eacute;vit&eacute;s par des mesures appropri&eacute;es et emp&ecirc;chant l&rsquo;ex&eacute;cution de son obligation. Si l&rsquo;emp&ecirc;chement est temporaire, l&rsquo;ex&eacute;cution de l&rsquo;obligation est suspendue &agrave; moins que le retard qui en r&eacute;sulterait ne justifie la r&eacute;solution de la convention. Si l&rsquo;emp&ecirc;chement est d&eacute;finitif, la convention est r&eacute;solue de plein droit et les parties sont lib&eacute;r&eacute;es de leurs obligations.</p>
            <p><strong>IBAN :</strong>&nbsp;International Banking Account Number signifie identifiant de compte bancaire international. Il est associ&eacute; &agrave; un code BIC.&nbsp;</p>
            <p><strong>KYC (Know Your Customer ou Connaissance client) :</strong>&nbsp;terme utilis&eacute; dans le cadre de l&rsquo;application d&rsquo;une proc&eacute;dure de lutte contre le blanchiment d&rsquo;argent et le financement du terrorisme et d&eacute;signant les diligences &agrave; r&eacute;aliser pour permettre l&rsquo;identification d&rsquo;un Client (Know Your Customer).&nbsp;</p>
            <p><strong>LCB-FT ou LAB-FT</strong>&nbsp;: signifie la lutte contre le blanchiment d&rsquo;argent et le financement du terrorisme au sens du code mon&eacute;taire et financier et donnant lieu &agrave; la mise en place d&rsquo;un dispositif d&rsquo;&eacute;valuation des op&eacute;rations clients conformes &agrave; la r&egrave;glementation en vigueur en France et en Europe.&nbsp;&nbsp;</p>
            <p><strong>Limites d&rsquo;Achat, de Vente, de D&eacute;p&ocirc;t et de Retrait :</strong>&nbsp;signifie, pour un Client donn&eacute;, les sommes &eacute;gales aux montants maximum d&rsquo;Achat, de Vente, de D&eacute;p&ocirc;t et de Retrait en cumul&eacute; r&eacute;alisables par ce Client sur une p&eacute;riode donn&eacute;e.&nbsp;</p>
            <p><strong>Mandat de Gestion&nbsp;:&nbsp;</strong>un contrat &eacute;crit sur papier ou autre support durable, par lequel un Client (le mandat) donne pouvoir &agrave; LAVERDY (le mandataire) afin de g&eacute;rer un portefeuille de Crypo-actifs, en fonction de ses objectifs d&rsquo;investissement, y compris sa tol&eacute;rance au risque, de ses connaissances et son exp&eacute;rience et de sa situation financi&egrave;re, y compris sa capacit&eacute; &agrave; subir des pertes.&nbsp;</p>
            <p><strong>Montant Brut d&rsquo;Achat, Montant d&rsquo;Achat, Montant Net de Vente&nbsp;:&nbsp;</strong>termes d&eacute;finis &agrave; l&rsquo;article 10.<strong>&nbsp;Partie :</strong>&nbsp;signifie le Client et LAVERDY, ensemble ou s&eacute;par&eacute;ment suivant le contexte.</p>
            <p><strong>Personnel ou Op&eacute;rateur :</strong>&nbsp;tout salari&eacute;, contractuel, prestataire, stagiaire, mandataire ou personne d&eacute;sign&eacute;e comme telle, employ&eacute; par LAVERDY, ses filiales ou sa soci&eacute;t&eacute; m&egrave;re.&nbsp;</p>
            <p><strong>Portefeuille ou Wallet :</strong>&nbsp;une application informatique permettant le contr&ocirc;le, la r&eacute;ception et l&rsquo;envoi des Crypto-actifs, dont la s&eacute;curisation est garantie par l&rsquo;utilisation de processus cryptographiques bas&eacute;s sur des m&eacute;canismes de cl&eacute; priv&eacute;e et cl&eacute; publique. Un Portefeuille contient une ou plusieurs adresses utilis&eacute;es pour l&rsquo;&eacute;change de Crypto-actifs ainsi qu&rsquo;une ou plusieurs cl&eacute;s priv&eacute;es s&eacute;curisant les avoirs. La divulgation, le vol, ou la perte des cl&eacute;s priv&eacute;es peut entra&icirc;ner la perte totale des Crypto-actifs.&nbsp;</p>
            <p><strong>Questionnaire de Connaissance Client :</strong>&nbsp;questionnaire &eacute;tabli dans un objectif de LCB-FT, de lutte contre la fraude, d&rsquo;&eacute;valuation des attentes d&rsquo;un Client, de connaissance en mati&egrave;re d&rsquo;investissement (notamment crypto) et de sa tol&eacute;rance aux risques, et adress&eacute; au Client via une interface du Site Internet ou directement par le Personnel de LAVERDY. LAVERDY se r&eacute;serve le droit de refuser un Service &agrave; un Client, sans d&eacute;dommagement, en cas d&rsquo;absence de r&eacute;ponse, de r&eacute;ponse partielle ou non satisfaisante &agrave; un Questionnaire Client dans un d&eacute;lai de 72 heures apr&egrave;s sa communication au Client.&nbsp;</p>
            <p><strong>Restitution&nbsp;:&nbsp;</strong>tel que ce terme est d&eacute;fini &agrave; l&rsquo;article 13.5.&nbsp;&nbsp;<strong>&nbsp;</strong></p>
            <p><strong>SEED :</strong>&nbsp;donn&eacute;es de sauvegarde d&rsquo;un Portefeuille de Crypto-actifs. Celles-ci se pr&eacute;sentent sous la forme d&rsquo;une liste de mots, et permettent un acc&egrave;s complet au Portefeuille li&eacute;. Cette s&eacute;curit&eacute; permet de restaurer le Portefeuille en cas de perte, de vol ou de destruction du support du Portefeuille (t&eacute;l&eacute;phone, tablette, PC ou autre). Cette liste de mots est strictement confidentielle. Le stockage sous quelque forme que ce soit des informations de la SEED est sous la responsabilit&eacute; unique du Client. LAVERDY ne peut en aucun cas &ecirc;tre tenu responsable d&rsquo;erreurs dans le stockage de la SEED du Portefeuille d&rsquo;un Client ou de sa perte.&nbsp;</p>
            <p><strong>Services&nbsp;</strong>: d&eacute;signe l&rsquo;ensemble de services offerts par LAVERDY &agrave; ses Clients depuis le Site Internet ou par le biais d&rsquo;un Op&eacute;rateur ou d&rsquo;un Charg&eacute; de Client&egrave;le. Les Services sont notamment constitu&eacute;s des Transactions, de la Conservation, du Retrait et du D&eacute;p&ocirc;t d&rsquo;un Crypto-actif.&nbsp; Ces services incluent &eacute;galement i) des prestations techniques, comme la r&eacute;cup&eacute;ration d&rsquo;une SEED, le transfert de Wallet, la conservation de certains Crypto-actifs pour le compte du Client dans un dispositif non connect&eacute; de type hardware Wallet, ii) les prestations de conseil en investissement sur des Crypto-actifs, ou iii) des services d&rsquo;investissement dans un ou plusieurs Crypto-actifs par le biais de Mandat de Gestion, d&rsquo;offre de&nbsp;<em>staking&nbsp;</em>ou d&rsquo;acc&egrave;s &agrave; des solutions d&rsquo;investissement en finance d&eacute;centralis&eacute;e tels que ces termes sont d&eacute;finis sur le Site Internet et pr&eacute;sent&eacute;s commercialement. LAVERDY se r&eacute;serve le droit d&rsquo;offrir les Services &agrave; ses Clients selon leurs besoins et leurs profils d&rsquo;aversion au risque. Certains Services peuvent faire l&rsquo;objet de Conditions Particuli&egrave;res.&nbsp;</p>
            <p><strong>Site Internet :</strong>&nbsp;signifie l&rsquo;ensemble des Services de LAVERDY accessibles &agrave; l&rsquo;adresse web (url) suivante :&nbsp;<a href="&nbsp;https:/www.laverdy.io&nbsp;">&nbsp;https://www.laverdy.io&nbsp;</a>, et notamment l&rsquo;interface du Compte Client accessible avec ses identifiants de connexion et le cas &eacute;ch&eacute;ant, le 2FA.&nbsp;</p>
            <p><strong>Solde d&rsquo;un Compte Crypto</strong>&nbsp;: il correspond &agrave; la quantit&eacute; disponible du Crypto-Actif sur le Compte Crypto d&rsquo;un Client.&nbsp;</p>
            <p><strong>Transaction :</strong>&nbsp;signifie Achat, Vente ou Conversion de Crypto-actif par un Client.&nbsp;</p>
            <p><strong>Transfert&nbsp;:&nbsp;</strong>d&eacute;signe l&rsquo;acte d&rsquo;envoi par LAVERDY, &agrave; la demande du Client, d&rsquo;une quantit&eacute; de Crypto-Actif &agrave; l&rsquo;adresse du Portefeuille fournie par un Client, &eacute;tant entendu que le Client est seul responsable de la communication de cette adresse et que LAVERDY ne saurait &ecirc;tre tenu responsable de la communication par le Client d&rsquo;une adresse erron&eacute;e ou ne lui appartenant pas. Le Retrait n&rsquo;est pas accessible pour tous les Crypto-actifs propos&eacute;s par LAVERDY. Les Crypto-actifs non retirables sont indiqu&eacute;s sur le Site Internet au moment de la proc&eacute;dure d&rsquo;Achat.&nbsp;</p>
            <p><strong>Vente de Crypto-actif, Vente ou ordre de Vente :</strong>&nbsp;service par lequel LAVERDY ach&egrave;te une quantit&eacute; de Crypto-actif d&eacute;tenue par un Client disposant d&rsquo;un Compte Client valid&eacute; contre un paiement en euros effectu&eacute; par virement sur le compte bancaire du Client ouvert dans la zone Europe.&nbsp;</p>
            <p><strong>Portefeuille externe</strong>&nbsp;: un Portefeuille dont le Client dispose de la ma&icirc;trise et pour lequel LAVERDY n&rsquo;a aucune maitrise possible. Le Portefeuille externe est totalement &eacute;tranger aux syst&egrave;mes informatiques de LAVERDY.</p>
            <p>&nbsp;</p>
            <ol start="3">
                <li><strong>Dispositions g&eacute;n&eacute;rales</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>Les pr&eacute;sentes CG r&eacute;gissent les Services propos&eacute;s &agrave; tout Client sur le Site Internet, &agrave; distance et par le biais d&rsquo;un Charg&eacute; de Client&egrave;le ou d&rsquo;un Op&eacute;rateur. Elles constituent avec la facture, le cas &eacute;ch&eacute;ant les Conditions Particuli&egrave;res, l&rsquo;Avis d&rsquo;Op&eacute;ration, le Mandat de Gestion et le Questionnaire de Connaissance Client ou tout autre document en tenant lieu, les seuls documents contractuels opposables aux Parties, &agrave; l&rsquo;exclusion de tout autre document (prospectus, pr&eacute;sentation de Services, catalogues ou photographies des Crypto-actifs, etc.) qui n&rsquo;ont qu&rsquo;une valeur indicative et non contractuelle. Les pr&eacute;sentes CG sont applicables dans le monde entier.</p>
            <p>Les conditions g&eacute;n&eacute;rales d&rsquo;achat ou de vente des Clients personnes morales ne sauraient, en aucune mani&egrave;re, se substituer ou supplanter les pr&eacute;sentes CG et ne sauraient &ecirc;tre opposables &agrave; LAVERDY, nonobstant toute disposition contraire.</p>
            <p>Lors de l&rsquo;Achat ou la Vente des Crypto-actifs en monnaie fiduciaire, les Crypto-actifs sont vendus ou achet&eacute;s aux Clients par LAVERDY pour un Prix libell&eacute; en euro (&euro;), sauf mention particuli&egrave;re contraire. La Conversion de Crypto-actif ne permet pas d&rsquo;afficher un Prix libell&eacute; en euro (&euro;), puisqu&rsquo;une quantit&eacute; est &eacute;chang&eacute;e contre une autre quantit&eacute;. Les Frais de Conversion peuvent &ecirc;tre exprim&eacute;s en euro en utilisation l&rsquo;&eacute;quivalence avec le Prix du Crypto-actif.&nbsp;</p>
            <p>&nbsp;</p>
            <ol start="4">
                <li><strong>Application des conditions g&eacute;n&eacute;rales &ndash; Opposabilit&eacute; et Preuve</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>Les CG sont mises &agrave; la disposition du Client sur le Site Internet conform&eacute;ment &agrave; l&rsquo;article 1127-1 du Code civil. Elles peuvent &eacute;galement &ecirc;tre communiqu&eacute;es via email aux Clients sur simple demande, par courrier &eacute;lectronique ou courrier postal, en plus de la communication automatique et de leur validation &agrave; la cr&eacute;ation du Compte Client et la notification &agrave; tous les Clients lors de leur mise &agrave; jour. De ce fait, les CG et leurs modifications ult&eacute;rieures sont opposables aux Clients qui reconnaissent en avoir eu connaissance et les accepter.&nbsp;</p>
            <p>L&rsquo;archivage des communications, des Services r&eacute;alis&eacute;s, des documents Clients et des factures est effectu&eacute; sur un support fiable et durable conform&eacute;ment &agrave; l&rsquo;article 1379 du Code civil. Tous ces &eacute;l&eacute;ments ont valeur probante entre les Parties. De mani&egrave;re g&eacute;n&eacute;rale, tout document informatique de LAVERDY fait preuve entre les Parties.</p>
            <p>&nbsp;</p>
            <ol start="5">
                <li><strong>Langue des CG</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>Les pr&eacute;sentes CG pourront &ecirc;tre traduites en anglais afin de faciliter leur compr&eacute;hension aupr&egrave;s de Clients. Nonobstant cette traduction, il est express&eacute;ment convenu entre les Parties qu&rsquo;en cas de diff&eacute;rend, seule la version fran&ccedil;aise des pr&eacute;sentes CG fera foi entre les Parties.</p>
            <p>&nbsp;</p>
            <ol start="6">
                <li><strong>Modification des CG</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>LAVERDY se r&eacute;serve la facult&eacute; de modifier ses CG &agrave; tout moment sans pr&eacute;avis. En cas de modification des CG, les CG applicables au Client sont celles en vigueur &agrave; la date de Validation des nouvelles CG, c&rsquo;est-&agrave;-dire lors de leur notification au Client, de la r&eacute;alisation d&rsquo;un Service. Il est recommand&eacute; au Client de sauvegarder les CG en vigueur au jour de la r&eacute;alisation des Services auxquels il a eu recours, nonobstant les r&egrave;gles d&rsquo;archivage mises en &oelig;uvre par LAVERDY.</p>
            <p>&nbsp;</p>
            <ol start="7">
                <li><strong>Nullit&eacute; d&rsquo;une clause des CG</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>La nullit&eacute; d&rsquo;une des dispositions des pr&eacute;sentes CG n&rsquo;entra&icirc;ne pas la nullit&eacute; de la totalit&eacute; de ces CG.&nbsp;</p>
            <p>&nbsp;</p>
            <ol start="8">
                <li><strong>R&egrave;gles g&eacute;n&eacute;rales applicables aux Services&nbsp;</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <ul>
                <li><strong>Offre de Crypto-actifs&nbsp;</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>La liste des Crypto-actifs propos&eacute;s par LAVERDY est communiqu&eacute;e au Client par l&rsquo;interm&eacute;diaire du Site Internet ou d&rsquo;un Charg&eacute; de Client&egrave;le. Le Client peut &eacute;galement demander par email la communication de cette liste. Elle est susceptible d&rsquo;&eacute;voluer &agrave; tout moment sans pr&eacute;avis. En particulier, LAVERDY peut d&eacute;cider unilat&eacute;ralement de ne plus offrir de Services sur un Crypto-actif donn&eacute;. Cette d&eacute;cision peut &ecirc;tre motiv&eacute;e par un &eacute;ventuel risque juridique ou technologique. Dans ce cas, LAVERDY fera ses meilleurs efforts pour notifier les Clients concern&eacute;s dans des d&eacute;lais raisonnables, proposera des solutions afin que les Clients puissent retirer les Crypto-actifs vers leur Portefeuille, les convertir contre d&rsquo;autres Crypto-actifs ou bien les vendre.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Offres de Services&nbsp;</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>La liste des Services propos&eacute;s par LAVERDY &agrave; ses Clients peut &eacute;voluer dans le temps. Ces Services sont communiqu&eacute;s au Client par l&rsquo;interm&eacute;diaire du Site Internet et ou d&rsquo;un Charg&eacute; de Client&egrave;le.</p>
            <p>Tous les Services offerts par LAVERDY ne sont pas disponibles pour tous les Crypto-actifs offerts. Certains Services comme le D&eacute;p&ocirc;t, Retrait, la Conversion peuvent &ecirc;tre limit&eacute;s &agrave; certains Crypto-actifs propos&eacute;s. De m&ecirc;me, certains Services peuvent &ecirc;tre r&eacute;serv&eacute;s &agrave; une partie des Clients de LAVERDY ou peuvent n&eacute;cessiter un Compte Client sp&eacute;cifique, tel que le Compte payant. Il convient au Client de se r&eacute;f&eacute;rer aux informations communiqu&eacute;es sur le Site Internet, en particulier au moment il a recours &agrave; un Service pour en connaitre les &eacute;ventuelles limitations, d&rsquo;interroger son Charg&eacute; de Client&egrave;le ou d&rsquo;en demander la communication par email au Service Client.&nbsp;</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Dispositions g&eacute;n&eacute;rales applicables aux Services sur le Site Internet&nbsp;&nbsp;&nbsp;</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Tous les Services auxquels un Client peut avoir recours &agrave; distance doivent imp&eacute;rativement &ecirc;tre r&eacute;alis&eacute;s sur un support &eacute;crit durable, depuis le Compte Client valid&eacute; via le Site Internet.&nbsp;</p>
            <p>Les Services propos&eacute;s par LAVERDY sont accessibles directement sur le Site Internet de LAVERDY &agrave; partir d&rsquo;un Compte Client valid&eacute;, non bloqu&eacute; ou non suspendu.&nbsp;</p>
            <p>En cas de difficult&eacute; dans la bonne ex&eacute;cution d&rsquo;un Service ou lors de la cr&eacute;ation d&rsquo;un Compte Client, un Client du Site Internet et LAVERDY peuvent communiquer par t&eacute;l&eacute;phone, par email ou via le syst&egrave;me de support utilis&eacute; par LAVERDY.&nbsp;</p>
            <p>LAVERDY se r&eacute;serve le droit, sans pr&eacute;avis et sans aucun d&eacute;dommagement possible, de ne pas valider un Compte Client, de refuser d&rsquo;ex&eacute;cuter tout Service pour un Client donn&eacute;, ou de bloquer, suspendre provisoirement, supprimer un Compte Client dans les conditions de l&rsquo;article 8.8.&nbsp; Plus g&eacute;n&eacute;ralement, LAVERDY se r&eacute;serve le droit de fermer l&rsquo;acc&egrave;s &agrave; tout ou partie du Site Internet, de suspendre l&rsquo;ex&eacute;cution ou la fourniture de tout ou partie des Services propos&eacute;s en cas de Force Majeure, de D&eacute;faillance Technique, de mise en danger ou de menaces portant sur son Personnel ou ses moyens techniques de quelque nature que ce soit.&nbsp;&nbsp;</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Dispositifs g&eacute;n&eacute;rales applicables aux Op&eacute;rations initi&eacute;es par le biais d&rsquo;un Charg&eacute; de Client&egrave;le&nbsp;</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Selon le statut de son Compte Client (se r&eacute;f&eacute;rer aux offres commerciales sur le Site Internet) un Client peut recourir &agrave; l&rsquo;accompagnement d&rsquo;un Charg&eacute; de Client&egrave;le pour la r&eacute;alisation d&rsquo;un Service en pr&eacute;sentiel ou &agrave; distance (par t&eacute;l&eacute;phone, email, par le biais de la signature &eacute;lectronique d&rsquo;un formulaire ou par conservation vid&eacute;o). Les conversations &agrave; distance peuvent &ecirc;tre enregistr&eacute;es. La demande de r&eacute;alisation d&rsquo;un Service par un Client doit &ecirc;tre formul&eacute;e par e-mail, par t&eacute;l&eacute;phone, par tout document &eacute;lectronique pr&eacute;vu &agrave; cet effet ou en pr&eacute;sence du Client. Une fois formul&eacute;e par le Client, la demande de r&eacute;alisation d&rsquo;un Service &agrave; un Charg&eacute; de Client&egrave;le ou Op&eacute;rateur de LAVERDY est irr&eacute;vocable. L&rsquo;ex&eacute;cution du Service peut &ecirc;tre confirm&eacute;e au Client par e-mail ou par le biais de la signature &eacute;lectronique d&rsquo;un Avis d&rsquo;Op&eacute;ration ou oralement en pr&eacute;sence du Client.&nbsp;</p>
            <p>Un client donn&eacute; peut &eacute;galement b&eacute;n&eacute;ficier de l&rsquo;accompagnement d&rsquo;un Charg&eacute; de Client&egrave;le ou d&rsquo;un Op&eacute;rateur dans le cas de difficult&eacute;s rencontr&eacute;es sur le Site Internet sans que cela soit pour autant une obligation d&rsquo;accompagnement imputable &agrave; LAVERDY.&nbsp;</p>
            <p>En cas d&rsquo;abus de la part d&rsquo;un Client dans le recours &agrave; un Charg&eacute; de Client&egrave;le ou &agrave; un Op&eacute;rateur pour la r&eacute;alisation de Services, en cas de comportement inappropri&eacute; ou mena&ccedil;ant d&rsquo;un Client &agrave; l&rsquo;encontre d&rsquo;un Charg&eacute; de Client&egrave;le ou d&rsquo;un Op&eacute;rateur, en cas de manquement d&rsquo;un Client au titre de ses obligations d&eacute;crites dans les CG, et &agrave; la discr&eacute;tion de LAVERDY, LAVERDY peut pr&eacute;lever au Client des Frais d&rsquo;Intervention.&nbsp;&nbsp;&nbsp;</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Ouverture d&rsquo;un Compte Client</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <ul>
                <li><strong>Documents et informations &agrave; fournir</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Les documents et informations &agrave; fournir lors de la demande de cr&eacute;ation d&rsquo;un Compte Client sont indiqu&eacute;s sur le Site Internet ou disponibles sur demande formul&eacute;e par &eacute;crit au Service Client. LAVERDY exigera notamment, sans que cette liste soit exhaustive, une pi&egrave;ce d&rsquo;identit&eacute; lisible et en cours de validit&eacute;, une photographie (une selfie du Client portant un mot manuscrit) ou vid&eacute;o du Client, la communication d&rsquo;une adresse email valide, du num&eacute;ro de t&eacute;l&eacute;phone du Client, de son adresse de r&eacute;sidence principale, d&rsquo;informations concernant ses revenus, sa profession et son patrimoine, la communication d&rsquo;un relev&eacute; d&rsquo;identit&eacute; bancaire.&nbsp; En cas de Compte Client joint, des documents identiques peuvent &ecirc;tre demand&eacute;s &agrave; l&rsquo;autre titulaire du Compet Client.&nbsp;&nbsp;&nbsp;&nbsp;</p>
            <p>La nature ou le format de ses documents (image, document pdf, &hellip;) peut &eacute;voluer dans le temps.&nbsp; La liste des documents exigibles peut diff&eacute;rer dans le cas de la cr&eacute;ation d&rsquo;un Compte Client personne morale ou personne physique. La cr&eacute;ation d&rsquo;un Compte Client personne physique n&eacute;cessitant notamment la communication d&rsquo;un kbis (ou &eacute;quivalent) de moins de 3 mois, des statuts, du registre des b&eacute;n&eacute;ficiaires effectifs.&nbsp;</p>
            <p>Dans le cadre de la relation d&rsquo;affaires, LAVERDY est susceptible de demander &agrave; un Client une mise &agrave; jour de ses documents ou la fourniture de documents suppl&eacute;mentaires (notamment justificatifs d&rsquo;origine des fonds, justificatifs de revenu ou de patrimoine, justificatifs d&rsquo;achat de Crypto-actifs, r&eacute;ponse &agrave; un Questionnaire de Connaissance Client) afin de lui permettre d&rsquo;acc&eacute;der &agrave; de nouveaux Services, de permettre de la r&eacute;alisation d&rsquo;un Service ou de modifier les Limites telles que pr&eacute;vues &agrave; l&rsquo;article 8.6.</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Restrictions sur la nationalit&eacute; et le pays de r&eacute;sidence en raison des mesures LCB-FT et des proc&eacute;dures du Service Conformit&eacute; et Gestion des Risques&nbsp;</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>L&rsquo;ouverture d&rsquo;un Compte Client et le maintien de la relation d&rsquo;affaires avec LAVERDY sont restreints &agrave; des Clients majeurs, dont la r&eacute;sidence principale se situe en Europe et dont le pays de nationalit&eacute; fait partie de la liste des&nbsp;pays indiqu&eacute;s. Elle est susceptible d&rsquo;&eacute;voluer &agrave; tout moment et sans pr&eacute;avis par simple d&eacute;cision de LAVERDY ou par obligations r&eacute;glementaires. LAVERDY ne peut &ecirc;tre tenu responsable des cons&eacute;quences de la fin d&rsquo;une relation d&rsquo;affaires avec un Client du fait du blocage ou suspension de son Compte Client en raison des restrictions li&eacute;es &agrave; son pays de r&eacute;sidence ou de nationalit&eacute;.&nbsp;&nbsp;</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Mise &agrave; jour des informations du Compte Client</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Le Client s&rsquo;engage &agrave; communiquer dans les meilleurs d&eacute;lais tout changement d&rsquo;identit&eacute;, de situation patrimoniale ou professionnelle, changement de domicile, de num&eacute;ro de t&eacute;l&eacute;phone, de coordonn&eacute;es bancaires, tout autre changement capable d&rsquo;impacter sa capacit&eacute; d&rsquo;investissement&nbsp;; et &agrave; fournir un nouveau justificatif.&nbsp;</p>
            <p>En cas d&rsquo;expiration de son document d&rsquo;identit&eacute;, le Client pourra &ecirc;tre amen&eacute; &agrave; fournir un nouveau document en cours de validit&eacute; et complet dans les meilleurs d&eacute;lais sous peine de voir son Compte suspendu ou bloqu&eacute;.&nbsp;</p>
            <p>Une fois le Compte Client Valid&eacute;, certaines informations ne peuvent modifi&eacute;es sans proc&eacute;dure sp&eacute;cifique communiqu&eacute;e par le Service Client et sur pr&eacute;sentation de justificatifs.&nbsp;&nbsp;</p>
            <p>Les coordonn&eacute;es du compte bancaire du Client et les adresses de Retrait de Crypto-actifs sont &eacute;galement des donn&eacute;es sensibles. Leur modification fait l&rsquo;objet d&rsquo;une proc&eacute;dure particuli&egrave;re d&eacute;crite sur le Site Internet ou communiqu&eacute;e par le Service Client.&nbsp;</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>S&eacute;curisation du Compte Client&nbsp;sur le Site Internet</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Il est imp&eacute;ratif que le Client prot&egrave;ge lui-m&ecirc;me son compte au minimum avec un mot de passe pr&eacute;alablement &agrave; la r&eacute;alisation de tout Service.&nbsp; Il est recommand&eacute; au Client de mettre en place le 2FA sur son Compte Client afin de pouvoir s&eacute;curiser ses informations et acc&eacute;der aux Services. LAVERDY peut exiger l&rsquo;activation pr&eacute;alable du 2FA ou la V&eacute;rification de Connexion pour certaines op&eacute;rations (cf. Avertissement sur les risques, Site internet Onglet &laquo;&nbsp;Compliance &amp; Legal&nbsp;&raquo;). Le Client peut &ecirc;tre amen&eacute; &agrave; bloquer lui-m&ecirc;me son compte s&rsquo;il consid&egrave;re qu&rsquo;il y a un risque de s&eacute;curit&eacute;. Cette demande de blocage peut &ecirc;tre communiqu&eacute;e au Service Client ou &ecirc;tre effectu&eacute;e en suivi les instructions sur le Site Internet ou re&ccedil;ue par email. La demande de d&eacute;blocage d&rsquo;un Compte Client ou de suppression du 2FA par un Client n&eacute;cessite le suivi d&rsquo;une proc&eacute;dure stricte et la communication de justificatifs communiqu&eacute;es par le Service Client ou un Charg&eacute; de Client&egrave;le. LAVERDY se r&eacute;serve le droit de ne pas ex&eacute;cuter cette demande si la proc&eacute;dure n&rsquo;est pas respect&eacute;e et les documents demand&eacute;s ne sont pas transmis et jug&eacute;s valides.</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Suppression d&rsquo;un Compte Client</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Un Client peut demander, &agrave; tout moment, &agrave; supprimer son Compte Client, valid&eacute; ou non, en informant par e-mail le Service Client. LAVERDY acc&egrave;dera &agrave; sa demande dans des d&eacute;lais raisonnables et selon les dispositions de l&rsquo;article 24. Des Frais d&rsquo;Intervention d&rsquo;un montant minimum de 20 euros TTC pourront &ecirc;tre appliqu&eacute;s &agrave; la discr&eacute;tion de LAVERDY.</p>
            <p>&Agrave; noter que pour r&eacute;pondre &agrave; ses obligations l&eacute;gales, LAVERDY met en &oelig;uvre un traitement de surveillance ayant pour finalit&eacute; la LCB-FT et l&rsquo;application des mesures de sanctions financi&egrave;res. En cons&eacute;quence, certaines donn&eacute;es seront conserv&eacute;es pendant 5 ans &agrave; compter de la cl&ocirc;ture de la relation d&rsquo;affaires.&nbsp;</p>
            <p>Lorsque le Client dispose d&rsquo;un solde positif sur son Compte Crypto, il convient d&rsquo;op&eacute;rer une distinction&nbsp;:</p>
            <ul>
                <li>Lorsque le solde du Compte est inf&eacute;rieur &agrave; l&rsquo;&eacute;quivalent du montant minimum des Frais de Retrait communiqu&eacute;s au Client sur le Site Internet ou par le Service Client, et que le Client souhaite cl&ocirc;turer son Compte, il doit renoncer express&eacute;ment au montant pr&eacute;sent sur son Compte Crypto. L&rsquo;attention du Client est attir&eacute;e sur le fait que toute renonciation est d&eacute;finitive et qu&rsquo;elle engendre l&rsquo;impossibilit&eacute; totale de r&eacute;cup&eacute;rer les fonds ult&eacute;rieurement.&nbsp;</li>
                <li>Lorsque le solde du Compte est sup&eacute;rieur au montant minimum des Frais de Retrait : le Client est inform&eacute; qu&rsquo;il est dans l&rsquo;obligation de retirer pr&eacute;alablement les fonds conserv&eacute;s et de pr&eacute;venir LAVERDY lorsque le retrait est r&eacute;alis&eacute; afin de pouvoir archiver son Compte Client.&nbsp;</li>
            </ul>
            <p>&nbsp;</p>
            <p>Si le Client d&eacute;tient un Crypto-actif qui ne peut &ecirc;tre retir&eacute; sur un Portefeuille externe, alors le Client devra vendre la quantit&eacute; d&eacute;tenue de ce Crypto-actif ou le cas &eacute;ch&eacute;ant, la convertir dans un Crypto-actif qui peut &ecirc;tre retir&eacute; (tel que le Bitcoin). Si la Vente ou la Conversion n&rsquo;est pas possible parce que la quantit&eacute; de Crypto-actif d&eacute;tenue est inf&eacute;rieure au seuil minimal de Vente ou de Conversion de ce Crypto-actif, alors le Client doit renoncer express&eacute;ment au montant pr&eacute;sent sur son Compte Crypto de ce Crypto-actif.&nbsp;&nbsp;&nbsp;&nbsp;</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Double Compte&nbsp;et Compte joint</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Un Compte Client est strictement personnel et est cr&eacute;&eacute; &agrave; destination et utilisation unique du Client. LAVERDY n&rsquo;autorise l&rsquo;ouverture que d&rsquo;un seul Compte Client par personne.&nbsp;</p>
            <p>Toutefois,&nbsp;</p>
            <ul>
                <li>un Client peut ouvrir &agrave; la fois un Compte Client Consommateur et un Compte Client personne morale &agrave; condition d&rsquo;avoir deux adresses email de connexion diff&eacute;rentes et d&rsquo;utiliser des moyens de paiement distincts propres &agrave; chaque Compte Client.</li>
                <li>Un Client peut ouvrir un Compte Client joint avec son &eacute;poux(se), conjoint, concubin, partenaire li&eacute; par un Pacte Civil de Solidarit&eacute; ou transformer son Compte Client unique en Compte Client joint. Dans tous les cas, le Client devra informer le plus rapidement possible le Service Client et fournir des justificatifs li&eacute;s &agrave; lui-m&ecirc;me et au cotitulaire du Compte. L&rsquo;identifiant d&rsquo;un Compte Client est unique et LAVERDY ne pourrait &ecirc;tre tenu responsable de toutes cons&eacute;quences li&eacute;es aux partages des identifiants de connexion, des moyens de paiement et du mat&eacute;riel informatique et des syst&egrave;mes de s&eacute;curit&eacute; entre le Client et le cotitulaire du Compte.&nbsp;&nbsp;</li>
            </ul>
            <p>&nbsp;</p>
            <ul>
                <li><strong>D&eacute;c&egrave;s, invalidit&eacute;, incapacit&eacute; du client, ex&eacute;cution d&rsquo;une d&eacute;cision judiciaire</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>En cas de d&eacute;c&egrave;s, d&rsquo;invalidit&eacute; ou d&rsquo;incapacit&eacute; du Client ou sur d&eacute;cision de justice, le Compte Client pourra &ecirc;tre transf&eacute;r&eacute; &agrave; un ayant droit sur pr&eacute;sentation des justificatifs, apr&egrave;s accord du Service Conformit&eacute; et Gestion des Risques et si l&rsquo;ayant droit rempli les crit&egrave;res pour disposer d&rsquo;un Compte Client valid&eacute;. Le cas &eacute;chant, les Crypto-actifs des Comptes Crypto seront vendues par LAVERDY et le produit de la Vente sera vers&eacute; &agrave; l&rsquo;ayant droit ou en l&rsquo;absence de Compte Crypto, le Compte sera supprim&eacute;.&nbsp; Des Frais d&rsquo;Intervention d&rsquo;un montant minimum de 250 euros pourront &ecirc;tre appliqu&eacute;s.</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Limites li&eacute;es &agrave; la r&eacute;alisation des Services</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <ul>
                <li><strong>Montant minimum</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p><strong>&nbsp;</strong></p>
            <p>LAVERDY se r&eacute;serve le droit de modifier &agrave; tout moment sans pr&eacute;avis la valeur minimale en euros ou en quantit&eacute; de Crypto-actif li&eacute;e &agrave; la r&eacute;alisation d&rsquo;un Service pour un Crypto-actif donn&eacute; (notamment le montant minimal d&rsquo;Achat, de Vente, de Retrait, de Conversion). Ces montants sont communiqu&eacute;s sur le Site Internet, par les Charg&eacute;s de Client&egrave;le et sur demande au Service Client. Sauf mention contraire sur le Site Internet, il n&rsquo;y a pas de montant minimum pour le D&eacute;p&ocirc;t de Crypto-actif.&nbsp;&nbsp;</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Montant maximum et fixation des Limites</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>LAVERDY permet th&eacute;oriquement &agrave; ses Clients la r&eacute;alisation de Services sans limite de montant maximum tant que le Compte Client ne pr&eacute;sente pas de risque de s&eacute;curit&eacute;, que le Client est en mesure de justifier de l&rsquo;origine des fonds (en monnaie ayant cours l&eacute;gal ou en Crypto-actifs) lors d&rsquo;un Achat ou d&rsquo;une Vente,&nbsp; et uniquement si le D&eacute;partement Conformit&eacute; et Gestion des Risques a donn&eacute; un avis favorable &agrave; la r&eacute;alisation du Service, suite &agrave; l&rsquo;analyse des documents, des &eacute;ventuels Questionnaires Connaissance Client et plus g&eacute;n&eacute;ralement des informations transmises par le Client.&nbsp;</p>
            <p>Des Limites d&rsquo;Achat, de Vente, de D&eacute;p&ocirc;t ou de Retrait peuvent &ecirc;tre fix&eacute;es sur une p&eacute;riode de 365 jours glissants, par jour, sur une p&eacute;riode donn&eacute;e ou sans limite temporelle et peuvent s&rsquo;appliquer &agrave; un Crypto-actif donn&eacute; du Compte Client. La valeur des Limites correspond aux montants cumul&eacute;s de toutes les op&eacute;rations r&eacute;alis&eacute;es sur la p&eacute;riode. Les Limites d&rsquo;Achat peuvent &ecirc;tre diff&eacute;rentes selon le moyen de paiement.&nbsp;&nbsp;</p>
            <p>Le Client en est inform&eacute; via le Site Internet ou par un Charg&eacute; de Client&egrave;le.</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Cas du Retrait ou D&eacute;p&ocirc;t</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Tous les Crypto-actifs offerts par LAVERDY ne sont pas retirables ou d&eacute;posables sur ou depuis un Portefeuille externe. Il revient au Client de se renseigner sur les Crypto-actifs support&eacute;s. Il existe un montant minimal pour un Retrait. Celui-ci est communiqu&eacute; en permanence sur le Site Internet, sur demande aupr&egrave;s du Service Client et par les Charg&eacute;s de Client&egrave;le.</p>
            <p>Il peut &ecirc;tre impos&eacute; une limite journali&egrave;re au Retrait sur le Site Internet pour un Compte Crypto donn&eacute;, de fa&ccedil;on &agrave; prot&eacute;ger le Client d&rsquo;&eacute;ventuels risques de s&eacute;curit&eacute;. Dans ce cas, le Client en est inform&eacute; et une proc&eacute;dure, qui passe par une authentification renforc&eacute;e, lui est communiqu&eacute;e afin de lever cette Limite.&nbsp;</p>
            <p>LAVERDY peut exiger du Client un Retrait de ses Crypto-actifs si leur valeur exprim&eacute;e en Euros venait &agrave; d&eacute;passer un seuil fix&eacute; par LAVERDY. Dans ce cas, LAVERDY informe le client avec un pr&eacute;avis de 30 jours et peut lui proposer une solution de garde mieux adapt&eacute;e &agrave; ses besoins.&nbsp;</p>
            <p>LAVERDY est en droit de demander au Client la nature du Portefeuille externe (Wallet physique, Wallet logiciel, Wallet d&eacute;tenu chez un autre Prestataire, Wallet d&rsquo;un site internet) depuis ou vers lequel il r&eacute;alise un D&eacute;p&ocirc;t ou un Retrait, ainsi que le nom de la personne ou de l&rsquo;entit&eacute; poss&eacute;dant le Wallet d&rsquo;origine ou le Wallet de destination.</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>V&eacute;rification et analyse lors de l&rsquo;ex&eacute;cution des Services</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Quel que soit le niveau du Compte Client, le D&eacute;partement Conformit&eacute; et Gestion des Risques dans le cadre de son action pour la LCB-FT et de la pr&eacute;vention de la fraude et des risques peut d&eacute;cider &agrave; tout moment de modifier des Limites, suspendre l&rsquo;ex&eacute;cution d&rsquo;un Service pour un Client donn&eacute; pendant un d&eacute;lai ne pouvant exc&eacute;der 14 jours ouvr&eacute;s, interdire la r&eacute;alisation de Services, interdire ou limiter l&rsquo;usage de certains moyens de paiement.&nbsp;</p>
            <p>Les questions que LAVERDY pose au Client lui permettent de le conna&icirc;tre, de mieux comprendre ses motivations et de lever le doute sur les conditions dans lesquelles il souhaite la r&eacute;alisation d&rsquo;un Service. LAVERDY est soumis par la r&eacute;glementation &agrave; l&rsquo;obligation de poser des questions r&egrave;glementaires.</p>
            <p>En l&rsquo;absence de r&eacute;ponse satisfaisante et de coop&eacute;ration du Client,</p>
            <ul>
                <li>Dans le cas d&rsquo;une Transaction, LAVERDY annulera l&rsquo;op&eacute;ration en question et remboursera le Client en d&eacute;duisant des Frais d&rsquo;Intervention et d&rsquo;&eacute;ventuelles autres pertes subies par LAVERDY dans le cadre de la r&eacute;alisation de la dite op&eacute;ration.&nbsp;</li>
                <li>Dans le cas d&rsquo;un Retrait, LAVERDY refusera le Retrait.</li>
                <li>Dans le cas d&rsquo;un D&eacute;p&ocirc;t, LAVERDY refusera le D&eacute;p&ocirc;t et renverra la quantit&eacute; de Crypto-actifs d&eacute;pos&eacute;e en d&eacute;duisant des Frais d&rsquo;Intervention &agrave; l&rsquo;adresse que le Client devra fournir sous sa propre responsabilit&eacute;.&nbsp;&nbsp;</li>
                <li>Dans le cas de tout autre Service, LAVERDY annulera le Service et appliquera des Frais d&rsquo;Intervention.</li>
            </ul>
            <p>&nbsp;</p>
            <p>Les pi&egrave;ces justificatives exigibles par le D&eacute;partement Conformit&eacute; et Gestion des Risques sont notamment (sans que cette liste soit exhaustive) :</p>
            <ul>
                <li>Documents d&rsquo;identit&eacute; suppl&eacute;mentaires (passeport, permis de conduire, &eacute;tat civil, visa) notamment document attestant la cr&eacute;ation d&rsquo;un Compte joint ;&nbsp;</li>
                <li>Communiquer le plus rapidement possible tout changement de sa situation patrimoniale ou professionnelle&nbsp;;</li>
                <li>Preuves de l&rsquo;origine des fonds (document prouvant qu&rsquo;il est bien le d&eacute;tenteur du moyen de paiement utilis&eacute;, relev&eacute; bancaire, relev&eacute; de bancaire compte joint, relev&eacute; d&rsquo;&eacute;pargne, avis d&rsquo;imposition, bulletin de salaire, documents notari&eacute;s attestant d&rsquo;une vente, attestation bancaire, etc.), et en cas de Vente ou de D&eacute;p&ocirc;t : adresses cryptographiques permettant de d&eacute;terminer les dates d&rsquo;acquisition des Crypto-actifs &agrave; vendre, preuve d&rsquo;achat, preuve de minage, identit&eacute; de la personne ou de l&rsquo;entit&eacute; poss&eacute;dant le Wallet d&rsquo;origine ou de destination ;</li>
                <li>Attestation du Client certifiant en toute connaissance de cause sa demande de Retrait &agrave; une adresse cryptographique donn&eacute;e&nbsp;;</li>
                <li>Attestation bancaire validant le moyen de paiement utilis&eacute; ;&nbsp;</li>
                <li>Documents li&eacute;s &agrave; la personne morale : comptes sociaux, relev&eacute; bancaire, attestation bancaire, b&eacute;n&eacute;ficiaires effectifs, &hellip;&nbsp;</li>
                <li>Questionnaire Connaissance client&nbsp;;</li>
                <li>D&eacute;charge de responsabilit&eacute;.</li>
            </ul>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Suspension ou blocage d&rsquo;un Compte Client&nbsp;</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Un Compte Client peut &ecirc;tre suspendu ou bloqu&eacute; par le Service Conformit&eacute; et Gestion des Risques &agrave; tout moment pour des raisons li&eacute;es&nbsp;:</p>
            <ul>
                <li>A la proc&eacute;dure de connaissance du Client&nbsp;;</li>
                <li>A la LCB-FT&nbsp;;&nbsp;</li>
                <li>Sur demande expresse des autorit&eacute;s comp&eacute;tentes ;</li>
                <li>Au non-respect des pr&eacute;sentes CG, notamment des dispositions li&eacute;es aux moyens de paiement (y compris l&rsquo;usage d&rsquo;un VPN par le Client)&nbsp;;&nbsp;&nbsp;</li>
                <li>En l&rsquo;absence de r&eacute;ponse valide du Client dans un d&eacute;lai maximum de 7 jours ouvr&eacute;s &agrave; des demandes de justificatifs sur son KYC ou sur la fourniture d&rsquo;un Service ;</li>
                <li>A la suspicion l&eacute;gitime que le Client est victime d&rsquo;une escroquerie, d&rsquo;un vol, d&rsquo;une cyberattaque, d&rsquo;une extorsion, d&rsquo;une manipulation, de violence, de chantage ou qu&rsquo;il n&rsquo;est pas en mesure d&rsquo;&eacute;valuer les risques associ&eacute;s aux Investissements&nbsp;;&nbsp;</li>
                <li>En raison d&rsquo;un comportement imputable au Client (insultes, menaces, demande abusive, etc.).</li>
            </ul>
            <p>&nbsp;</p>
            <p>Un Compte Client suspendu ou bloqu&eacute; ne pourra plus acc&eacute;der aux Services temporairement ou d&eacute;finitivement. LAVERDY se r&eacute;serve le droit &agrave; sa discr&eacute;tion d&rsquo;appliquer des Frais d&rsquo;Intervention &agrave; la suspension ou au blocage d&rsquo;un Compte Client.&nbsp;</p>
            <p>LAVERDY ne peut en aucun cas &ecirc;tre tenu responsable des cons&eacute;quences de quelque nature qu&rsquo;elles soient li&eacute;es au blocage ou &agrave; la suspension d&rsquo;un Compte Client.</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Modification d&rsquo;un Retrait</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Un Retrait une fois r&eacute;alis&eacute; par LAVERDY ne peut pas &ecirc;tre modifi&eacute;, cette op&eacute;ration &eacute;tant irr&eacute;versible et sous la responsabilit&eacute; du Client.&nbsp;</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Absence de d&eacute;lai de r&eacute;tractation</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>En application des dispositions de l&rsquo;article L.221-28, 2&deg; du Code de la consommation, la r&eacute;alisation d&rsquo;une op&eacute;ration d&rsquo;Achat, de Vente, de Conversion, de D&eacute;p&ocirc;t ou de Retrait ne donne pas lieu &agrave; un droit de r&eacute;tractation au b&eacute;n&eacute;fice du Client dans la mesure o&ugrave; il s&rsquo;agit de la &laquo;&nbsp;fourniture de biens ou de services dont le prix d&eacute;pend de fluctuations sur le march&eacute; financier &eacute;chappant au contr&ocirc;le du professionnel susceptible de se produire pendant le d&eacute;lai de r&eacute;tractation&nbsp;&raquo;.</p>
            <p>&nbsp;</p>
            <ol start="9">
                <li><strong>Tarification</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>Les tarifs pratiqu&eacute;s par LAVERDY sur l&rsquo;ensemble de ses offres commerciales sont mentionn&eacute;s sur le Site Internet et dans la rubrique Aide/F.A.Q. Ils sont &eacute;galement disponibles sur simple demande &eacute;crite formul&eacute;e aupr&egrave;s du Service Client.&nbsp;&nbsp;</p>
            <p>Des Frais minimums sur les Transactions peuvent s&rsquo;appliquer.&nbsp;</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Information sur le Prix des Crypto-actifs</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Les Prix des Crypto-actifs pouvant faire l&rsquo;objet d&rsquo;une Transaction par LAVERDY sont communiqu&eacute;s au Client sur le Site Internet ou &agrave; sa demande lors d&rsquo;une op&eacute;ration r&eacute;alis&eacute;e avec l&rsquo;accompagnement d&rsquo;un Charg&eacute; de Client&egrave;le.&nbsp;</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Calcul des Frais sur les Transactions et Frais d&rsquo;Intervention</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Les Frais sur les Transactions sont d&eacute;termin&eacute;s&nbsp;:&nbsp;</p>
            <ul>
                <li>A l&rsquo;Achat, les Frais d&rsquo;Achat sont d&eacute;termin&eacute;s en multipliant le taux de Commission par le Montant Brut d&rsquo;Achat (tel que ce terme est d&eacute;fini &agrave; l&rsquo;article 10) divis&eacute; par (1 + taux de Commission), le taux de Commission &eacute;tant exprim&eacute; en pourcentage.&nbsp;</li>
                <li>A la Vente, les Frais de Vente sont d&eacute;termin&eacute;s en multipliant le taux de Commission par la quantit&eacute; de Crypto-actifs vendue et par le Prix du Crypto-actif fix&eacute; par LAVERDY lors de la Compensation de la Vente.&nbsp;</li>
                <li>A la Conversion, les Frais de Conversion sont d&eacute;termin&eacute;s en multipliant le taux de Commission par la contre-valeur des Crypto-actifs convertis.</li>
            </ul>
            <p>&nbsp;</p>
            <p>Les Frais sur les Transactions sont soumis &agrave; des minimums tels qu&rsquo;indiqu&eacute;s sur le Site Internet.&nbsp;&nbsp;&nbsp;</p>
            <p>Le taux de Commission peut d&eacute;pendre du type de Compte Client (par exemple&nbsp;: Compte Premium) et du moyen de paiement utilis&eacute;.&nbsp;</p>
            <p>En cas d&rsquo;erreur imputable au Client (envoi d&rsquo;un mauvais montant en Euros, d&eacute;passement du d&eacute;lai autoris&eacute; de Transaction, utilisation d&rsquo;un moyen de paiement non autoris&eacute; ou ne lui appartenant pas, absence de libell&eacute; dans le virement bancaire &agrave; l&rsquo;Achat, communication d&rsquo;un IBAN incorrect ou li&eacute; &agrave; un compte bancaire n&rsquo;appartenant pas au Client, irr&eacute;gularit&eacute; du Compte Client), LAVERDY peut appliquer des Frais d&rsquo;Intervention par Transaction, qui s&rsquo;additionnent des Frais de Transaction. Les Frais d&rsquo;Intervention sont calcul&eacute;s en pourcentage du montant &eacute;quivalent en euros de la Transaction ou en pourcentage de la quantit&eacute; de Crypto-actif. Les Frais d&rsquo;Intervention peuvent &ecirc;tre soumis &agrave; un minimum.&nbsp;</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Communication du Prix d&rsquo;un Crypto-actif. Prix garanti et non garanti</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Le Prix du Crypto-actif et le montant d&eacute;finitif des Frais de Transaction sont fix&eacute;s et communiqu&eacute;s au Client&nbsp;:&nbsp;</p>
            <ul>
                <li>au moment de la confirmation par la Transaction effectu&eacute;e sur le Site Internet.&nbsp;</li>
                <li>post&eacute;rieurement &agrave; la r&eacute;alisation de la Transaction si celle est effectu&eacute;e directement par un Charg&eacute; de Client&egrave;le agissant sur demande du Client.&nbsp;</li>
            </ul>
            <p>&nbsp;</p>
            <p>Dans les deux cas, les Frais d&rsquo;Intervention peuvent &ecirc;tre communiqu&eacute;s ult&eacute;rieurement et faire l&rsquo;objet d&rsquo;une facturation s&eacute;par&eacute;e.&nbsp;&nbsp;</p>
            <p>Le Prix est garanti&nbsp;:</p>
            <ul>
                <li>En cas de Vente, de Conversion, d&rsquo;Achat par virement bancaire ou carte bancaire initi&eacute; et finalis&eacute; par le Client depuis le Site Internet&nbsp;;</li>
                <li>En cas d&rsquo;Achat r&eacute;alis&eacute; avec l&rsquo;aide d&rsquo;un Charg&eacute; de Client&egrave;le ou d&rsquo;un Op&eacute;rateur, lorsque les fonds ont &eacute;t&eacute; re&ccedil;us par virement bancaire, et si le Client pr&eacute;cise express&eacute;ment vouloir confirmer lui-m&ecirc;me ou annuler l&rsquo;op&eacute;ration pendant le laps de temps qui sera indiqu&eacute; par email ;</li>
                <li>Si les Conditions Particuli&egrave;res le pr&eacute;cisent.&nbsp;</li>
            </ul>
            <p>&nbsp;</p>
            <p>Le Prix n&rsquo;est pas garanti&nbsp;:&nbsp;</p>
            <ul>
                <li>En cas de paiement r&eacute;current automatique d&rsquo;Achat de Crypto-actif&nbsp;ou de Vente r&eacute;currente ;&nbsp;</li>
                <li>En cas de Transaction portant sur un Crypto-actif effectu&eacute; directement par un Charg&eacute; de Client&egrave;le ou un Op&eacute;rateur, sauf demande expresse du Client&nbsp;;</li>
                <li>En cas de d&rsquo;Achat par virement bancaire r&eacute;alis&eacute; depuis le Site Internet si le Client ne peut pas acc&eacute;der &agrave; son Compte Client sur le Site Internet&nbsp;ou s&rsquo;il n&rsquo;annule pas l&rsquo;Achat &agrave; la fin du laps de temps indiqu&eacute; par email ;</li>
                <li>Suite &agrave; une modification de la Transaction requise par le Client ou r&eacute;sultant d&rsquo;une erreur qui lui est imputable, au non-respect des CG notamment des proc&eacute;dures de l&rsquo;article 12, &agrave; la suspension du Compte Client ou son blocage par le Service Conformit&eacute; et Gestion des Risques&nbsp;;&nbsp;</li>
                <li>En cas d&rsquo;annulation de la Transaction par le Client&nbsp;;&nbsp;</li>
                <li>En cas de D&eacute;faillance Technique ou Force Majeure.&nbsp;&nbsp;</li>
            </ul>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Absence de TVA sur les Frais de Transactions</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Les Frais sur les Transactions, les Frais de Retrait et de D&eacute;p&ocirc;t ne sont pas assujetties &agrave; la TVA (article 261 C, 1&deg;-d du CGI &ndash; BOI-TVA-CHAMP-10-10-40-50 n&deg; 10 ; article&nbsp;135, paragraphe&nbsp;1, sous&nbsp;d), e) et&nbsp;f), de la directive&nbsp;2006/112 &ndash; CJUE, 22.10.15, C264/14, Hedqvist).</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Calcul des Frais de Retrait&nbsp;&nbsp;</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>LAVERDY refacture int&eacute;gralement les Frais de Minage au Client sous forme de Frais de Retrait. Les Frais de Minage sont d&eacute;termin&eacute;s en utilisant les informations fournies par les Wallets (en unit&eacute;/Byte) multipli&eacute;es par la taille d&rsquo;une transaction standard et par le Cours du Crypto-actif. Les Frais de Retrait sont calcul&eacute;s en pourcentage de la quantit&eacute; brute du Crypto-actif faisant l&rsquo;objet d&rsquo;un Retrait en incluant des frais minimums pris par LAVERDY</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Tarification du D&eacute;p&ocirc;t</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Le D&eacute;p&ocirc;t de Crypto-actifs par un Client dans son Compte Crypto est gratuit, sauf mention contraire.&nbsp;</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Tarification de la Conservation&nbsp;&nbsp;</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>La Conservation d&rsquo;une quantit&eacute; d&rsquo;un ou plusieurs Crypto-actifs par un Client sur son Compte Crypto est un service gratuit sauf mention contraire.&nbsp;&nbsp;</p>
            <p>Le calcul des Frais de Conservation est indiqu&eacute; &agrave; l&rsquo;article 11 des CG. Les Frais de Conservation sont soumis &agrave; la TVA fran&ccedil;aise selon la l&eacute;gislation en vigueur.&nbsp;</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Tarification du Retrait&nbsp;</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Le Retrait de Crypto-actifs par un Client depuis son Compte Crypto est un service payant, sauf mention contraire.</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Tarification de l&rsquo;Abonnement, tarification sp&eacute;cifique&nbsp;</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>L&rsquo;acc&egrave;s &agrave; certains Services propos&eacute;s par LAVERDY, la cr&eacute;ation de Compte Client personne morale, l&rsquo;acc&egrave;s &agrave; l&rsquo;accompagnement par un Charg&eacute; de Client&egrave;le font l&rsquo;objet d&rsquo;une tarification sp&eacute;cifique qui peut inclure le paiement d&rsquo;un abonnement sur une p&eacute;riode donn&eacute;e.&nbsp;</p>
            <p>Ces tarifications qui sont pr&eacute;sent&eacute;es sur le Site Internet et dans la documentation commerciale de LAVERDY correspondent &agrave; un engagement ferme sur l&rsquo;achat des prestations par le Client.</p>
            <p>L&rsquo;acc&egrave;s aux Services et aux b&eacute;n&eacute;fices d&rsquo;un Compte Client sur abonnement sont conditionn&eacute;s &agrave; son paiement et prennent fin &agrave; la fin de la dur&eacute;e d&rsquo;engagement, d&egrave;s l&rsquo;arr&ecirc;t des paiements ou en cas de demande formul&eacute;e par &eacute;crit du Client. Un Service sur abonnement ne peut &ecirc;tre suspendu. Une fois r&eacute;gl&eacute;, un abonnement est consid&eacute;r&eacute; comme d&ucirc; et ne peut &ecirc;tre rembours&eacute; m&ecirc;me s&rsquo;il est arr&ecirc;t&eacute; du fait du Client.&nbsp;</p>
            <p>&nbsp;</p>
            <ol start="10">
                <li><strong>Proc&eacute;dures d&rsquo;Achat</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <ul>
                <li><strong>Proc&eacute;dures g&eacute;n&eacute;rales :&nbsp;</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Pour r&eacute;aliser un Achat, un Client doit disposer d&rsquo;un Compte Client ouvert et valid&eacute;. Un Compte suspendu ou bloqu&eacute; ne peut r&eacute;aliser d&rsquo;Achat.&nbsp;&nbsp;&nbsp;</p>
            <p>Lors d&rsquo;un Achat, le Client renseigne le type Crypto-actif &agrave; acheter (&eacute;tant entendu que ce Crypto-actif est vendu par LAVERDY) et la quantit&eacute; qu&rsquo;il souhaite acheter ou le montant total en euros qu&rsquo;il souhaite r&eacute;gler (ci-apr&egrave;s &laquo;&nbsp;Montant Brut d&rsquo;Achat&nbsp;&raquo;).&nbsp; Le Montant d&rsquo;Achat correspond au Montant Brut d&rsquo;Achat apr&egrave;s d&eacute;duction des Frais d&rsquo;Achat. Il revient au Client de s&rsquo;informer sur la volatilit&eacute; des Prix des Crypto-actifs. Le Client est libre d&rsquo;annuler un Achat avant la validation de son paiement et celui-ci est annul&eacute; sans frais et les fonds sont retourn&eacute;s dans un d&eacute;lai de 2 &agrave; 7 jours ouvr&eacute;s sur le compte bancaire utilis&eacute; pour l&rsquo;envoi des fonds.&nbsp;</p>
            <p>Pour un Achat sur le Site Internet, la proc&eacute;dure est d&eacute;crite sur le Site Internet et le Prix du Crypto-actif est affich&eacute; au Client pr&eacute;alablement &agrave; sa validation. Le Client dispose d&rsquo;un d&eacute;lai indiqu&eacute; sur le Site Internet pour payer l&rsquo;Achat et pour finaliser l&rsquo;op&eacute;ration. Une fois le paiement valid&eacute;, l&rsquo;Achat n&rsquo;est pas annulable.&nbsp;<strong>&nbsp;</strong>&nbsp;</p>
            <p>Dans le cadre d&rsquo;un Achat par virement, le compte bancaire utilis&eacute; par le Client doit &ecirc;tre identifi&eacute;, sa banque doit &ecirc;tre en Europe (compte SEPA), en particulier un Client Consommateur ne peut utiliser un compte bancaire de personne morale ou vice versa. LAVERDY se r&eacute;serve le droit, sans pr&eacute;avis, de limiter le nombre de compte bancaire utilisable par un Client.&nbsp;</p>
            <p>Des Limites s&rsquo;appliquent aux Achats d&rsquo;un Client en fonction des donn&eacute;es du Compte Client, de son historique de Transactions et du moyen de paiement utilis&eacute;.&nbsp; Le Client peut demander l&rsquo;augmentation de ses Limites d&rsquo;Achat aupr&egrave;s d&rsquo;un Op&eacute;rateur ou via le Site Internet. Le Client peut &ecirc;tre amen&eacute; &agrave; remplir ponctuellement un Questionnaire de Connaissance Client fourni par LAVERDY.&nbsp;</p>
            <p>Une facture est &eacute;mise &agrave; l&rsquo;issue d&rsquo;un Achat et envoy&eacute;e au Client par email ou disponible sur demande. L&rsquo;historique des Achats est &eacute;galement pr&eacute;sent sur le Site Internet.&nbsp;</p>
            <p>La quantit&eacute; de Crypto-actifs achet&eacute;e est disponible dans un court d&eacute;lai (au maximum 48h) dans le Compte Crypto correspondant du Client. L&rsquo;application de mesures de LCB-FT, de lutte contre la Fraude ou les cons&eacute;quences d&rsquo;un d&eacute;faut de liquidit&eacute; sur les places de march&eacute;s utilis&eacute;es par LAVERDY pour r&eacute;aliser les transactions pour compte propre peuvent retarder la mise &agrave; disposition des Crypto-actifs achet&eacute;s. LAVERDY ne peut tenu responsable des cons&eacute;quences de ce retard.&nbsp;&nbsp;&nbsp;</p>
            <p>LAVERDY ne peut &ecirc;tre tenu responsable d&rsquo;un refus de l&rsquo;op&eacute;ration d&rsquo;Achat par LAVERDY et de ses cons&eacute;quences, du fait de la r&eacute;ception d&rsquo;un montant diff&eacute;rent du Montant Brut d&rsquo;Achat, d&rsquo;instructions incompl&egrave;tes ou erron&eacute;es dans le libell&eacute; du virement bancaire, de l&rsquo;utilisation par le Client de moyens de paiement (carte bancaire ou compte bancaire) ne lui appartenant pas ou impossible &agrave; associer &agrave; son Compte Client ou pour des raisons justifi&eacute;es par son D&eacute;partement Conformit&eacute; et Gestion des Risques en raison du KYC du Client ou de la LCB-FT.&nbsp;</p>
            <p>Si LAVERDY venait &agrave; annuler un Achat alors que le Client a proc&eacute;d&eacute; au paiement du Montant d&rsquo;Achat, le Client sera rembours&eacute; d&eacute;duction faite des pertes &eacute;ventuellement subies par LAVERDY du fait de la diff&eacute;rence de Prix du Crypto-actif avec le moment o&ugrave; LAVERDY a proc&eacute;d&eacute; &agrave; la Compensation du Montant d&rsquo;Achat.&nbsp;</p>
            <p>LAVERDY n&rsquo;est en aucune mani&egrave;re tenue d&rsquo;ex&eacute;cuter un Achat expir&eacute; ou annul&eacute;. Un Achat non valid&eacute;, non pay&eacute;, ou partiellement pay&eacute; par un Client sera r&eacute;put&eacute; annul&eacute; et pourra ne pas &ecirc;tre r&eacute;alis&eacute; par LAVERDY.&nbsp;</p>
            <p>En cas d&rsquo;erreurs r&eacute;p&eacute;t&eacute;es (virement sans r&eacute;f&eacute;rence/libell&eacute;, virement ne respectant pas les Limites, utilisation de moyens de paiement ne lui appartenant pas, virement depuis un compte bancaire hors zone Europe ou non autoris&eacute;) ou de tout manquement imputable au Client au titre des CG, des Frais d&rsquo;Intervention seront appliqu&eacute;s et calcul&eacute;s sur le Montant Brut d&rsquo;Achat.&nbsp;&nbsp;&nbsp;</p>
            <p>Si un Achat n&rsquo;a pu aboutir pass&eacute; un d&eacute;lai de 360 jours pour une raison imputable au Client, le Montant Brut d&rsquo;Achat envoy&eacute; sera retourn&eacute; au Client sur demande de celui-ci, d&eacute;duction faite des Frais d&rsquo;Intervention major&eacute;s de 10 % par jour &agrave; partir du 361<sup>&egrave;me</sup>&nbsp;jour.</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Achat par carte bancaire&nbsp;</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Le Client dispose d&rsquo;un d&eacute;lai de quelques minutes tel qu&rsquo;indiqu&eacute; sur le Site Internet pour effectuer son paiement par carte bancaire qui sera confirm&eacute; par &laquo; 3D-Secure &raquo;. La carte bancaire utilis&eacute;e doit &ecirc;tre &agrave; son nom ou au nom de la personne morale titulaire du Compte Client, ne doit pas &ecirc;tre consid&eacute;r&eacute;e comme un moyen de paiement &eacute;lectronique anonyme, &ecirc;tre valide et accept&eacute;e par LAVERDY. Au-del&agrave; du d&eacute;lai affich&eacute;, l&rsquo;Achat est indiqu&eacute; comme &laquo; expir&eacute; &raquo; et est annul&eacute;. Le Client ne doit pas utiliser de VPN pour sa connexion, car le paiement risque d&rsquo;&ecirc;tre refus&eacute;.&nbsp;</p>
            <p>LAVERDY ne peut &ecirc;tre tenu responsable des cons&eacute;quences de l&rsquo;utilisation d&rsquo;une carte bancaire n&rsquo;appartenant pas au Client, d&rsquo;une op&eacute;ration d&rsquo;Achat annul&eacute;e ou en &eacute;chec.</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Achat par virement bancaire</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Lors d&rsquo;un Achat par virement bancaire, LAVERDY communique directement sur le Site Internet les coordonn&eacute;es bancaires de LAVERDY (&laquo; Compte Bancaire LAVERDY&nbsp;&raquo;) et les instructions exactes permettant au Client de faire son virement bancaire, notamment l&rsquo;indication d&rsquo;un code &agrave; mettre dans le libell&eacute; du virement (qui peut &ecirc;tre du type &laquo;&nbsp;CNHS xxxxxx&nbsp;&raquo;). Le Client est libre d&rsquo;annuler &agrave; tout moment son ordre d&rsquo;Achat tant que le virement du Montant Brut d&rsquo;Achat n&rsquo;a pas &eacute;t&eacute; re&ccedil;u sur le Compte Bancaire LAVERDY.&nbsp;&nbsp;</p>
            <p>Le Client doit imp&eacute;rativement g&eacute;n&eacute;rer l&rsquo;op&eacute;ration d&rsquo;Achat sur le Site Internet avant de r&eacute;aliser son virement afin de valider que le Montant Brut d&rsquo;Achat ne d&eacute;passe pas sa Limite autoris&eacute;e et afin d&rsquo;obtenir un code d&rsquo;identification de l&rsquo;op&eacute;ration &agrave; pr&eacute;ciser dans le libell&eacute; de son virement bancaire.&nbsp;</p>
            <p>Une fois le paiement du Montant Brut d&rsquo;Achat re&ccedil;u sur le Compte Bancaire LAVERDY avec les instructions correctement indiqu&eacute;es dans un d&eacute;lai maximum de 8 jours ouvr&eacute;s apr&egrave;s le jour apr&egrave;s l&rsquo;initiation de l&rsquo;Achat sur le Site Internet, LAVERDY proc&egrave;de &agrave; la v&eacute;rification des informations du Client. Cette validation peut prendre maximum 72h, sauf si le Client est notifi&eacute; (par email ou t&eacute;l&eacute;phone) d&rsquo;une anomalie ou si LAVERDY n&rsquo;est pas en mesure d&rsquo;attribuer la transaction au Client (par exemple&nbsp;: Client/Op&eacute;ration non identifiable)&nbsp;; dans ce cas, l&rsquo;Achat est suspendu pour une dur&eacute;e maximum de 30 jours. Des Frais d&rsquo;Intervention peuvent &ecirc;tre imput&eacute;s au Client &agrave; la seule discr&eacute;tion de LAVERDY.&nbsp;</p>
            <p>La validation de la transaction d&rsquo;Achat par LAVERDY d&eacute;clenche une notification (par email) au Client afin de l&rsquo;informer qu&rsquo;il dispose d&rsquo;un laps de temps qui peut atteindre 72 heures pour finaliser lui-m&ecirc;me l&rsquo;op&eacute;ration ou pour l&rsquo;annuler sans frais. En cas d&rsquo;annulation, le remboursement du Montant Brut d&rsquo;Achat se fait sous 7 jours ouvr&eacute;s.&nbsp; Pendant ce d&eacute;lai de 72 heures, le Client peut se connecter &agrave; son Compte Client et valider d&eacute;finitivement l&rsquo;Achat. La quantit&eacute; de Crypto-actifs &agrave; recevoir est calcul&eacute;e par Compensation du Montant d&rsquo;Achat en utilisant le Prix du Crypto-actif fix&eacute; par LAVERDY au moment o&ugrave; le Client effectue la validation. Les informations sur le Prix d&rsquo;une unit&eacute; de Crypto-Actif, la quantit&eacute; achet&eacute;e, les Frais d&rsquo;Achat et le Montant Brut d&rsquo;Achat sont communiqu&eacute;es au Client. Sans action du Client &agrave; l&rsquo;expiration d&rsquo;un d&eacute;lai de 72 heures, la transaction d&rsquo;Achat est automatiquement finalis&eacute;e au Prix du Crypto-actif fix&eacute; par LAVERDY &agrave; l&rsquo;instant o&ugrave; le d&eacute;lai expire.&nbsp;</p>
            <p>A r&eacute;ception d&rsquo;un virement bancaire valide, si l&rsquo;Achat a &eacute;t&eacute; annul&eacute; ou que le Montant Brut d&rsquo;Achat ne correspond pas au montant du virement re&ccedil;u, LAVERDY peut &agrave; sa seule discr&eacute;tion d&eacute;cider de recr&eacute;er ou modifier l&rsquo;op&eacute;ration d&rsquo;Achat avec le bon montant. Le Client en sera alors inform&eacute; par email et disposera d&rsquo;un laps de temps (communiqu&eacute; dans l&rsquo;email et qui peut atteindre de 72 heures) pour annuler lui-m&ecirc;me sans frais l&rsquo;Achat comme dans le cadre de la proc&eacute;dure d&eacute;finie au pr&eacute;c&eacute;dent paragraphe.&nbsp;&nbsp;</p>
            <p>Dans le cas d&rsquo;un Achat r&eacute;current, le Client devra indiquer dans le libell&eacute; du virement les mots &laquo;&nbsp;r&eacute;current ou recurr&nbsp;&raquo; ainsi que le nom du Crypto-actif, tel que cela est pr&eacute;cis&eacute; sur le Site Internet ou devrait informer le Service Client de son souhait de mettre en place un virement r&eacute;current. D&egrave;s r&eacute;ception d&rsquo;un virement bancaire valide pour une op&eacute;ration r&eacute;currente, LAVERDY passera l&rsquo;op&eacute;ration d&rsquo;Achat suivant les instructions sans validation pr&eacute;alable du Client qui en sera inform&eacute; &agrave; post&eacute;riori. Un achat par virement r&eacute;current n&rsquo;est pas annulable.&nbsp;&nbsp;&nbsp;</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Achat accompagn&eacute; par un Charg&eacute; de Client&egrave;le</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>L&rsquo;Achat accompagn&eacute; par un Charg&eacute; de Client&egrave;le s&rsquo;applique principalement au Client payant par virement bancaire. Le Charg&eacute; de Client&egrave;le communique le Compte Bancaire LAVERDY.&nbsp;</p>
            <p>Le Client doit imp&eacute;rativement informer pr&eacute;alablement le Charg&eacute; de Client&egrave;le de son projet d&rsquo;Achat avant de r&eacute;aliser son virement afin de valider que le Montant Brut d&rsquo;Achat ne d&eacute;passe pas sa Limite autoris&eacute;e. Le Charg&eacute; de Client&egrave;le se chargera de r&eacute;pondre aux demandes du Client en lien avec les CG et les r&egrave;gles &eacute;tablies par d&eacute;partement Conformit&eacute; et Gestion des Risques.&nbsp;&nbsp;</p>
            <p>A r&eacute;ception du virement et apr&egrave;s sa validation, le Charg&eacute; de Client&egrave;le contacte le Client par email ou par t&eacute;l&eacute;phone pour confirmer sa ou ses op&eacute;rations d&rsquo;Achat. Le Charg&eacute; de Client&egrave;le accompagne le Client dans ses d&eacute;marches d&rsquo;investissement en rappelant les facteurs de risques et en lui communiquant le cas &eacute;ch&eacute;ant les notes d&rsquo;analyses r&eacute;alis&eacute;es par LAVERDY. Le Client est seul d&eacute;cisionnaire de son Achat, il est libre d&rsquo;annuler son Achat avant sa validation et sera rembours&eacute; sans frais, sauf d&rsquo;&eacute;ventuels Frais d&rsquo;Intervention en cas d&rsquo;abus ou de comportement inappropri&eacute;.&nbsp;&nbsp;</p>
            <p>Sauf demande expresse du Client et accept&eacute;e par LAVERDY ou Conditions Particuli&egrave;res, l&rsquo;Achat se fait &agrave; Prix non garanti. Le Charg&eacute; de Client&egrave;le confirmera l&rsquo;Achat aupr&egrave;s du Client par t&eacute;l&eacute;phone (la conversation peut &ecirc;tre enregistr&eacute;e), par email et/ou par signature &eacute;lectronique d&rsquo;un Avis d&rsquo;Op&eacute;ration.&nbsp;&nbsp;</p>
            <p>Le Client peut demander le Retrait des Crypto-actifs achet&eacute;s sur une Portefeuille externe, dont la communication (par email ou depuis le Site Internet) est &agrave; la seule responsabilit&eacute; du Client. Le Retrait pourra &ecirc;tre effectu&eacute; concomitamment &agrave; l&rsquo;Achat dans un d&eacute;lai maximum de 48 heures.&nbsp;</p>
            <p>&nbsp;</p>
            <ol start="11">
                <li><strong>Proc&eacute;dure de vente&nbsp;</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <ul>
                <li><strong>Dispositions g&eacute;n&eacute;rales</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Pour r&eacute;aliser une Vente, un Client doit disposer d&rsquo;un Compte Client ouvert et valid&eacute;. Un Compte suspendu ou bloqu&eacute; ne peut r&eacute;aliser de Vente.&nbsp;&nbsp;&nbsp;</p>
            <p>Lors d&rsquo;une Vente, le Client renseigne la quantit&eacute; exacte de Crypto-actif &agrave; vendre (&laquo;&nbsp;Quantit&eacute; QV&nbsp;&raquo;) ou le montant net en euros &agrave; recevoir sur son compte bancaire (le &laquo;&nbsp;Montant Net de la Vente&nbsp;&raquo;). Une fois l&rsquo;une des deux valeurs fix&eacute;es par le Client, l&rsquo;autre est calcul&eacute;e par LAVERDY en utilisant le Prix du Crypto-actif vendu. Un Client ne peut vendre une Quantit&eacute; QV sup&eacute;rieure au Solde de son Compte Crypto consid&eacute;r&eacute;. Le Client est seul d&eacute;cisionnaire de sa Vente, il est libre d&rsquo;annuler sa Vente avant sa validation.</p>
            <p>Le Client doit renseigner le compte bancaire si l&rsquo;IBAN de celui-ci n&rsquo;a pas &eacute;t&eacute; pr&eacute;alablement renseign&eacute; en suivant les instructions fournies sur le Compte Client et en r&eacute;pondant &agrave; d&rsquo;&eacute;ventuelles questions de s&eacute;curit&eacute; additionnelles. LAVERDY peut demander la fourniture d&rsquo;un justificatif pour chaque IBAN.&nbsp;</p>
            <p>Pour une Vente sur le Site Internet, la proc&eacute;dure est d&eacute;crite sur le Site Internet et le Prix du Crypto-actif est affich&eacute; au Client pr&eacute;alablement &agrave; sa validation. Le Client dispose d&rsquo;un d&eacute;lai indiqu&eacute; sur le Site Internet pour valider la Vente et finaliser l&rsquo;op&eacute;ration. Une fois valid&eacute;e, la Vente n&rsquo;est pas annulable.&nbsp;<strong>&nbsp;</strong>&nbsp;</p>
            <p>Le Montant Net de la Vente est soumis &agrave; un minimum fix&eacute; par LAVERDY au moment de la Vente. Ces Limites de Vente d&eacute;pendent des donn&eacute;es du Compte Client et de son historique de Transactions.&nbsp; Le Client peut demander l&rsquo;augmentation de ses Limites de Vente directement depuis le Site Internet, aupr&egrave;s d&rsquo;un Charg&eacute; de Client&egrave;le ou du Service Client. Le D&eacute;partement Conformit&eacute; et Gestion des Risques met en &oelig;uvre les moyens n&eacute;cessaires pour acc&eacute;der &agrave; la demande du Client et peut lui demander des justificatifs et des informations compl&eacute;mentaires afin d&rsquo;autoriser la Vente (notamment un Questionnaire de Connaissance Client et des justificatifs sur l&rsquo;origine des Crypto-actifs quand ceux-ci ont &eacute;t&eacute; acquis en dehors de LAVERDY).&nbsp;</p>
            <p>Le r&egrave;glement du Montant Net de la Vente s&rsquo;effectue par virement bancaire suivant les instructions fournies par le Client dans un d&eacute;lai maximum de 7 jours ouvr&eacute;s &agrave; compter de la r&eacute;alisation de la Vente par LAVERDY. Une facture d&eacute;taillant l&rsquo;op&eacute;ration est envoy&eacute;e au Client et disponible sur simple demande. Ces informations sont affich&eacute;es, en permanence, dans l&rsquo;historique des Transactions sur l&rsquo;interface du Compte Client sur le Site Internet.</p>
            <p>Tout au long du processus de Vente et avant que celle-ci ne soit valid&eacute;e par LAVERDY, LAVERDY se r&eacute;serve le droit de demander &agrave; un Client l&rsquo;origine des Crypto-actifs vendus, leur date d&rsquo;acquisition et l&rsquo;origine des fonds utilis&eacute;s pour leur acquisition. En cas de r&eacute;ponse jug&eacute;e insatisfaisante par le D&eacute;partement Conformit&eacute; et Gestion des Risques, LAVERDY refusera la Vente et retournera la quantit&eacute; de Crypto-actifs re&ccedil;ue, si elle a &eacute;t&eacute; envoy&eacute;e par le Client, sans aucun d&eacute;dommagement.</p>
            <p>Si LAVERDY venait &agrave; annuler une Vente alors que celle-ci a &eacute;t&eacute; valid&eacute;e par le Client, le Client sera re-cr&eacute;dit&eacute; en Crypto-actif d&eacute;duction faite des pertes &eacute;ventuellement subies par LAVERDY du fait de la diff&eacute;rence de Prix du Crypto-actif avec le moment o&ugrave; LAVERDY a proc&eacute;d&eacute; &agrave; la Compensation de la Vente.&nbsp;</p>
            <p>Le Client est seul responsable de la communication de ses coordonn&eacute;es bancaires (IBAN). LAVERDY ne sera en aucun cas tenu responsable des cons&eacute;quences li&eacute;es &agrave; la communication d&rsquo;information erron&eacute;e par le Client concernant ses coordonn&eacute;es bancaires.&nbsp; Une confirmation des coordonn&eacute;es bancaires peut &ecirc;tre exig&eacute;e &agrave; partir du 2FA, de la V&eacute;rification de Connexion ou de tout autre moyen afin de s&rsquo;assurer que l&rsquo;IBAN est bien celui du Client.</p>
            <p>Si une Vente n&rsquo;a pu aboutir pass&eacute; un d&eacute;lai de 360 jours pour une raison imputable au Client, la quantit&eacute; de Crypto-actifs envoy&eacute;e sera retourn&eacute;e au Client sur demande de celui-ci, d&eacute;duction faite des Frais d&rsquo;Intervention de 20&euro; major&eacute;s de 10 % par jour &agrave; partir du 361<sup>&egrave;me</sup>&nbsp;jour.</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Vente accompagn&eacute;e par un Charg&eacute; de Client&egrave;le</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Le Client doit imp&eacute;rativement informer pr&eacute;alablement le Charg&eacute; de Client&egrave;le de son projet de Vente avant de r&eacute;aliser son D&eacute;p&ocirc;t afin de valider que le Montant Net de la Vente ne d&eacute;passe pas sa Limite autoris&eacute;e. Le Charg&eacute; de Client&egrave;le se chargera de r&eacute;pondre aux demandes du Client en lien avec les CG et les r&egrave;gles &eacute;tablies par d&eacute;partement Conformit&eacute; et Gestion des Risques.&nbsp;&nbsp;</p>
            <p>Le Client contacte le Charg&eacute; de Client&egrave;le par email ou par t&eacute;l&eacute;phone pour demander la r&eacute;alisation d&rsquo;une Vente. Le Charg&eacute; de Client&egrave;le accompagne le Client dans ses d&eacute;marches et lui communique le cas &eacute;ch&eacute;ant les notes d&rsquo;analyses r&eacute;alis&eacute;es par LAVERDY. Le Client est seul d&eacute;cisionnaire de sa Vente, il est libre d&rsquo;annuler sa Vente avant sa validation. Tout abus de la part d&rsquo;un Client pourra faire l&rsquo;objet de la facturation de Frais d&rsquo;Intervention.&nbsp;</p>
            <p>La Vente se fait &agrave; Prix non garanti, sauf Conditions Particuli&egrave;res. Le Charg&eacute; de Client&egrave;le confirmera la Vente aupr&egrave;s du Client par t&eacute;l&eacute;phone (la conversation peut &ecirc;tre enregistr&eacute;e), par email et/ou par signature &eacute;lectronique d&rsquo;un Avis d&rsquo;Op&eacute;ration.</p>
            <p>&nbsp;</p>
            <ol start="12">
                <li><strong>Proc&eacute;dure li&eacute;e &agrave; une op&eacute;ration de Conversion&nbsp;</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <ul>
                <li><strong>Dispositions g&eacute;n&eacute;rales&nbsp;</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Pour r&eacute;aliser une op&eacute;ration de Conversion, un Client doit disposer d&rsquo;un Compte Client ouvert et valid&eacute;. Un Compte suspendu ou bloqu&eacute; ne peut r&eacute;aliser de Conversion. La liste des Crypto-actifs pour lesquels la Conversion est propos&eacute;e est disponible sur le Site Internet, aupr&egrave;s d&rsquo;un Charg&eacute; de Client&egrave;le et sur demande au Service Client.&nbsp;&nbsp;&nbsp;&nbsp;</p>
            <p>Lors d&rsquo;une op&eacute;ration de Conversion, le Client renseigne la quantit&eacute; exacte de Crypto-actif &agrave; convertir et le type de Crypto-actif qu&rsquo;il souhaite obtenir &agrave; l&rsquo;issue de la Conversion. Un Client ne peut convertir une quantit&eacute; de Crypto-actif sup&eacute;rieure au Solde de son Compte Crypto consid&eacute;r&eacute;. Le Client est seul d&eacute;cisionnaire de la Conversion, il est libre de l&rsquo;annuler avant sa validation.</p>
            <p>Pour une Conversion initi&eacute; depuis le Site Internet, la proc&eacute;dure est d&eacute;crite sur le Site Internet et le Prix permettant de d&eacute;finir la parit&eacute; d&rsquo;&eacute;change entre les deux Crypto-actifs est affich&eacute; au Client pr&eacute;alablement &agrave; sa validation. Le Client dispose d&rsquo;un d&eacute;lai indiqu&eacute; sur le Site Internet pour valider et finaliser l&rsquo;op&eacute;ration. Une fois valid&eacute;e, la Conversion n&rsquo;est pas annulable.&nbsp;<strong>&nbsp;</strong>&nbsp;</p>
            <p>La quantit&eacute; de Crypto-actif &agrave; convertir est soumis &agrave; un minimum fix&eacute; par LAVERDY au moment de la Conversion.&nbsp;</p>
            <p>Une fois l&rsquo;op&eacute;ration de Conversion valid&eacute;e, le Compte Crypto du Client est d&eacute;bit&eacute; de la quantit&eacute; de Crypto-actif convertie et il est cr&eacute;dit&eacute; de la quantit&eacute; de Crypto-actif re&ccedil;ue en &eacute;change de la Conversion en tenant compte des Frais de Conversion. La quantit&eacute; du nouveau Crypto-actif cr&eacute;dit&eacute; au Compte Crypto du Client est disponible au Retrait, &agrave; la Vente, ou pour une autre Conversion dans un d&eacute;lai court qui ne peut exc&eacute;der 48 heures.&nbsp;</p>
            <p>Une facture d&eacute;taillant l&rsquo;op&eacute;ration est envoy&eacute;e au Client et disponible sur simple demande. Ces informations sont affich&eacute;es, en permanence, dans l&rsquo;historique des Transactions sur l&rsquo;interface du Compte Client sur le Site Internet.</p>
            <p>Si LAVERDY venait &agrave; annuler une op&eacute;ration de Conversion alors que celle-ci a &eacute;t&eacute; valid&eacute;e par le Client, le Compte Crypto du Client sera re-cr&eacute;dit&eacute; en Crypto-actif d&eacute;duction faite des pertes &eacute;ventuellement subies par LAVERDY du fait de la diff&eacute;rence de Prix entre les deux Crypto-actifs avec le moment o&ugrave; LAVERDY a proc&eacute;d&eacute; &agrave; la Conversion.</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Op&eacute;ration de Conversion accompagn&eacute;e par un Charg&eacute; de Client&egrave;le</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Le Client contacte le Charg&eacute; de Client&egrave;le par email ou par t&eacute;l&eacute;phone pour demander la r&eacute;alisation d&rsquo;une op&eacute;ration de Conversion. Le Charg&eacute; de Client&egrave;le accompagne le Client dans ses d&eacute;marches et lui communique le cas &eacute;ch&eacute;ant les notes d&rsquo;analyses r&eacute;alis&eacute;es par LAVERDY. Le Client est seul d&eacute;cisionnaire de son op&eacute;ration de Conversion, il est libre de l&rsquo;annuler avant sa validation. Tout abus de la part d&rsquo;un Client pourra faire l&rsquo;objet de la facturation de Frais d&rsquo;Intervention.&nbsp;</p>
            <p>La Conversion se fait &agrave; Prix non garanti, sauf Conditions Particuli&egrave;res. Le Charg&eacute; de Client&egrave;le confirmera la r&eacute;alisation de l&rsquo;op&eacute;ration de Conversion au Client par t&eacute;l&eacute;phone (la conversation peut &ecirc;tre enregistr&eacute;e), par email et/ou par signature &eacute;lectronique d&rsquo;un Avis d&rsquo;Op&eacute;ration.</p>
            <p>&nbsp;</p>
            <ol start="13">
                <li><strong>Service de Conservation</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <ul>
                <li><strong>Accessibilit&eacute; et dur&eacute;e de validit&eacute;</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Le service de Conservation est propos&eacute; &agrave; tous les Clients disposant d&rsquo;un Compte Client valid&eacute;, non bloqu&eacute; et non suspendu et qui peuvent b&eacute;n&eacute;ficier de l&rsquo;acc&egrave;s aux services de LAVERDY par le biais du Site Internet.&nbsp;</p>
            <p>Le service est accessible depuis le Site Internet ou par le biais d&rsquo;un Charg&eacute; de Client&egrave;le cons&eacute;cutivement &agrave; un Achat ou effectuant un D&eacute;p&ocirc;t (Compte Crypto). Il peut n&eacute;cessiter la mise en place du 2FA, de la V&eacute;rification de Connexion, ainsi que la fourniture d&rsquo;informations pr&eacute;alables &agrave; son utilisation, comme l&rsquo;IBAN et BIC du compte bancaire du Client ou la fourniture d&rsquo;une ou plusieurs adresses de Retrait sur le Portefeuille Externe du Client.&nbsp;</p>
            <p>Le Solde des Comptes Crypto d&rsquo;un Client est accessible &agrave; tout moment (sauf en cas de D&eacute;faillance Technique) sur le Site Internet.</p>
            <p>Toutefois, LAVERDY se r&eacute;serve le droit de fixer une limite maximale &agrave; la valeur exprim&eacute;e en euros des Crypto-actifs d&eacute;tenus par un Client et gard&eacute;s dans son Compte Crypto. En cas de d&eacute;passement de ce seuil, LAVERDY peut exiger le Retrait de tout ou partie des Crypto-actifs avec un d&eacute;lai de pr&eacute;avis de 30 jours et peut proposer une solution mieux adapt&eacute;e aux besoins du Client ou si les Crypto-actifs ne sont pas retirables.</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Fonctionnement du service et autorisation donn&eacute;e &agrave; LAVERDY</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>LAVERDY propose &agrave; ses Clients de conserver leurs Crypto-actifs achet&eacute;s ou d&eacute;pos&eacute;s dans un Compte Crypto propre &agrave; chaque nature de Crypto-actif pour laquelle LAVERDY propose ce service.&nbsp;</p>
            <p>Le Compte Crypto d&rsquo;un Client est un compte de tenue de position o&ugrave; est refl&eacute;t&eacute; le Solde par Crypto-actif du Client. L&rsquo;acc&egrave;s &agrave; ses Crypto-actifs est s&eacute;curis&eacute; par LAVERDY. Le Client reconnait express&eacute;ment qu&rsquo;il n&rsquo;a pas un acc&egrave;s direct aux Portefeuilles de ses Crypto-actifs qu&rsquo;il d&eacute;l&egrave;gue &agrave; LAVERDY.&nbsp;</p>
            <p>Les Crypto-actifs des Clients sont conserv&eacute;s dans des comptes distincts selon la nature du Crypto-actif ouverts chez LAVERDY, et manipulables suivant les proc&eacute;dures &eacute;tablies. Les Crypto-actifs des Clients sont stock&eacute;s dans des comptes ouverts &agrave; cet effet et se distinguent des Crypto-actifs d&eacute;tenus en propre par LAVERDY dont la conservation est r&eacute;alis&eacute;e dans des comptes s&eacute;par&eacute;s. LAVERDY assure la tenue de position de chacun de ses Clients dans des bases de donn&eacute;es s&eacute;curis&eacute;es.&nbsp;</p>
            <p>LAVERDY se r&eacute;serve le droit d&rsquo;utiliser sans pr&eacute;avis et &agrave; sa seule discr&eacute;tion, une part des quantit&eacute;s de Crypto-actifs qu&rsquo;il d&eacute;tient pour le compte de ses Clients afin de r&eacute;aliser des placements permettant de g&eacute;n&eacute;rer un rendement pour LAVERDY et/ou ses Clients. LAVERDY est seul responsable de la s&eacute;lection des interm&eacute;diaires et/ou des protocoles informatiques d&eacute;centralis&eacute;s utilis&eacute;s &agrave; ces fins et est responsable des &eacute;ventuelles cons&eacute;quences de la perte de tout ou partie de la quantit&eacute; de Crypto-actifs de ces Clients ainsi utilis&eacute;s. LAVERDY agira en cons&eacute;quence de cause et r&eacute;alisera les diligences n&eacute;cessaires dans la s&eacute;lection et la gestion de ces placements.</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Engagements et absence de responsabilit&eacute;s de LAVERDY</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>LAVERDY s&rsquo;engage &agrave; mettre les moyens &agrave; sa disposition pour assurer la s&eacute;curit&eacute; des Crypto-actifs conserv&eacute;s et la s&eacute;curit&eacute; et r&eacute;silience de ces syst&egrave;mes informatiques. LAVERDY disposent des moyens humains et techniques pour op&eacute;rer le service.&nbsp;</p>
            <p>LAVERDY ne peut &ecirc;tre tenu responsable des cons&eacute;quences sur la Conservation ou le Retrait dues &agrave; une D&eacute;faillance Technique, un cas de Force Majeure, une atteinte &agrave; la s&eacute;curit&eacute; du Client de quelque nature qu&rsquo;elle soit, un cas de Kidnapping ou d&rsquo;une demande de ran&ccedil;on portant sur le Client ou ses proches, d&rsquo;un cryptolockage (ran&ccedil;on logicielle), d&rsquo;une attaque informatique ou d&rsquo;une malveillance de la part de Hackers ou d&rsquo;une atteinte logique ou d&rsquo;un virus g&eacute;n&eacute;rique ou non affectant l&rsquo;ordinateur, le syst&egrave;me de messagerie, le t&eacute;l&eacute;phone ou les moyens informatiques du Client en dehors de toute atteinte ne portant que sur les syst&egrave;mes informatiques propres &agrave; LAVERDY et dont la soci&eacute;t&eacute; a la ma&icirc;trise, d&rsquo;une erreur, d&rsquo;une n&eacute;gligence ou d&rsquo;un acte malveillant imputable au Client, du non-respect par le Client des CG.</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Gestion des Forks</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>En cas de Fork, le client est r&eacute;put&eacute; avoir droit aux actifs num&eacute;riques issus de la bifurcation &agrave; hauteur de sa position au moment de la survenance du Fork, sauf convention conclue avec le conservateur qui en dispose autrement. Le cas &eacute;ch&eacute;ant, toute exemption est fix&eacute;e dans des conditions et limites raisonnables et pr&eacute;d&eacute;finie par le conservateur.&nbsp;</p>
            <p>Sauf exception, LAVERDY est en mesure d&rsquo;assurer la gestion des Forks uniquement pour quelques Crypto-actifs pour lesquels il propose un service de Retrait. LAVERDY fera ses meilleurs efforts afin d&rsquo;informer les clients concern&eacute;s par e-mail de l&rsquo;existence d&rsquo;un Fork. LAVERDY pourra les inciter &agrave; effectuer un Retrait afin qu&rsquo;il puisse g&eacute;rer eux-m&ecirc;mes leur acc&egrave;s aux droits issus du Fork.&nbsp;&nbsp;</p>
            <p>En outre, pour assurer la gestion d&rsquo;un Fork, LAVERDY prendra en compte le fait que&nbsp;:&nbsp;</p>
            <ul>
                <li>Le ou les nouveaux Crypto-actifs doivent &ecirc;tre support&eacute;e par le syst&egrave;me de Conservation utilis&eacute; sans modification technique, et&nbsp;</li>
                <li>Le ou les nouveaux Crypto-actifs doivent &ecirc;tre list&eacute;s sur un site web de r&eacute;f&eacute;rence (&agrave; ce jour&nbsp;: coinmarketcap.com) parmi les 20 premi&egrave;res capitalisations de march&eacute;.</li>
            </ul>
            <p>&nbsp;</p>
            <p>LAVERDY ne saurait garantir &agrave; ses Clients le fait de recevoir ou d&rsquo;&ecirc;tre cr&eacute;dit&eacute; sur leur Compte Crypto d&rsquo;une certaine quantit&eacute; d&rsquo;un ou de nouveaux Crypto-actifs issus du Fork du Crypto-actif initialement d&eacute;tenu au moment de la constatation du Fork.</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Restitution des Crypto-actifs conserv&eacute;s&nbsp;</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Le Client qui a mis en Conservation ses Crypto-actifs chez LAVERDY peut en demander la Restitution totale ou partielle. Cette Restitution peut s&rsquo;effectuer par Retrait ou par Vente. Il est important de noter que tous les Crypto-actifs offerts en Service par LAVERDY ne sont pas retirables. Le Site Internet fait mention expresse en cas d&rsquo;Achat de l&rsquo;impossibilit&eacute; de Retrait de certains Crypto-actifs. En poursuivant n&eacute;anmoins la proc&eacute;dure d&rsquo;Achat, le Client reconnait et accepte qu&rsquo;il ne pourra pas retirer ses Crypto-actifs.&nbsp;&nbsp;&nbsp;</p>
            <p>S&rsquo;il propose le Retrait pour un Crypto-actif donn&eacute;, LAVERDY s&rsquo;engage &agrave; restituer dans les plus brefs d&eacute;lais les quantit&eacute;s de Crypto-actifs d&eacute;tenues dans le Compte Crypto d&rsquo;un Client qui en fait la demande. Nonobstant cet engagement, LAVERDY ne restituera pas imm&eacute;diatement les Crypto-actifs d&rsquo;un Client en cas de survenance d&rsquo;un ou plusieurs &eacute;v&egrave;nements&nbsp;suivants :</p>
            <ul>
                <li>Si le Compte Client a &eacute;t&eacute; suspendu, ferm&eacute; ou bloqu&eacute;&nbsp;;&nbsp;</li>
                <li>Si le Service Conformit&eacute; et Gestion des Risques le d&eacute;cide en raison de la LCB-FT&nbsp;;</li>
                <li>Sur demande d&rsquo;une autorit&eacute; comp&eacute;tente par l&rsquo;interm&eacute;diaire du D&eacute;partement Conformit&eacute; et Gestion des risques&nbsp;;</li>
                <li>Si LAVERDY a des suspicions l&eacute;gitimes sur le fait que le Client n&rsquo;est pas &agrave; l&rsquo;origine de la demande de Retrait&nbsp;;&nbsp;</li>
                <li>Depuis le Site Internet, si les Limites au Retrait ont &eacute;t&eacute; atteintes&nbsp;;&nbsp;</li>
                <li>Dans l&rsquo;un des cas d&rsquo;absences de responsabilit&eacute;s &eacute;voqu&eacute;es au 12.3.&nbsp;;</li>
                <li>Si LAVERDY subit une cyberattaque, fait l&rsquo;objet d&rsquo;une atteinte logique, d&rsquo;un virus, g&eacute;n&eacute;rique ou non, d&rsquo;une attaque physique de ses locaux ou portant atteinte &agrave; l&rsquo;int&eacute;grit&eacute; des Op&eacute;rateurs dans l&rsquo;exercice de leur fonction&nbsp;;&nbsp;</li>
                <li>Si le Site Internet et plus g&eacute;n&eacute;ralement les syst&egrave;mes informatiques de LAVERDY sont en proc&eacute;dure de maintenance&nbsp;;</li>
                <li>Dans le cas de la fourniture de certains services sur Crypto-actifs qui imposent un d&eacute;lai de retrait ou la r&eacute;alisation d&rsquo;op&eacute;rations sp&eacute;cifiques par LAVERDY avant de proc&eacute;der &agrave; la restitution des actifs&nbsp;;</li>
                <li>Si le Client est d&eacute;c&eacute;d&eacute; ou n&rsquo;est plus joignable.&nbsp;</li>
            </ul>
            <p>&nbsp;</p>
            <p>LAVERDY restituera les Crypto-actifs d&egrave;s lors que le ou les &eacute;v&egrave;nements susmentionn&eacute;s auront &eacute;t&eacute; r&eacute;solus ou trait&eacute;s de telle mani&egrave;re que la s&eacute;curit&eacute; et la responsabilit&eacute; du Client et/ou de LAVERDY ne seront plus en jeu.</p>
            <p>&nbsp;</p>
            <ol start="14">
                <li><strong>Proc&eacute;dure de Retrait et de D&eacute;p&ocirc;t</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>Le Retrait et le D&eacute;p&ocirc;t sont propos&eacute;s pour certains Crypto-actifs aux Clients disposant d&rsquo;un Compte Client valid&eacute;, non bloqu&eacute; et non suspendu et qui peuvent b&eacute;n&eacute;ficier de l&rsquo;acc&egrave;s aux services de LAVERDY par le biais du Site Internet. Ces services sont accessibles depuis le Site Internet et par le biais d&rsquo;un Charg&eacute; de Client&egrave;le.</p>
            <p>Ces services sont soumis aux m&ecirc;mes limites et conditions que le service de Conservation d&eacute;fini ci-dessus.</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>D&eacute;p&ocirc;t</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Le D&eacute;p&ocirc;t n&rsquo;est pas li&eacute; &agrave; un Achat concomitamment r&eacute;alis&eacute; chez LAVERDY et proc&egrave;de d&rsquo;une op&eacute;ration distincte. Le Client doit n&eacute;anmoins avoir r&eacute;alis&eacute; un Achat ou transmettre dans un d&eacute;lai court certains documents afin de pouvoir effectuer ou valider un D&eacute;p&ocirc;t.&nbsp;</p>
            <p><strong><em>Important &ndash; D&eacute;claration du Client&nbsp;:</em></strong></p>
            <p>La quantit&eacute; de Crypto-actifs d&eacute;pos&eacute;e doit provenir d&rsquo;un Portefeuille appartement au Client. En acceptant les CG, le Client d&eacute;clare que les Crypto-actifs d&eacute;pos&eacute;s ont &eacute;t&eacute; acquis par lui de fa&ccedil;on l&eacute;gale, qu&rsquo;il en est pleinement propri&eacute;taire, qu&rsquo;ils ne font pas l&rsquo;objet d&rsquo;un nantissement et qu&rsquo;ils ne sont pas issus d&rsquo;une activit&eacute; frauduleuse, d&rsquo;un blanchiment d&rsquo;argent, de toute activit&eacute; r&eacute;prim&eacute;e par la loi et qu&rsquo;ils n&rsquo;ont pas &eacute;t&eacute; confi&eacute;s au Client par un tiers susceptible d&rsquo;enfreindre les m&ecirc;mes r&egrave;gles. Le Client reconna&icirc;t qu&rsquo;il est enti&egrave;rement responsable des cons&eacute;quences li&eacute;es &agrave; une d&eacute;claration fausse, incompl&egrave;te ou erron&eacute;e. LAVERDY ne serait &ecirc;tre tenu responsable de d&eacute;claration fausse, incompl&egrave;te ou erron&eacute;e du Client. &Agrave; tout moment, LAVERDY peut demander au Client d&rsquo;apporter des &eacute;l&eacute;ments justificatifs sur l&rsquo;origine des Actifs Num&eacute;riques d&eacute;pos&eacute;s ou d&rsquo;annuler son D&eacute;p&ocirc;t.&nbsp;</p>
            <p>Le Client s&rsquo;engage &agrave; n&rsquo;utiliser que l&rsquo;adresse fournie par LAVERDY pour CHAQUE D&eacute;p&ocirc;t car celle-ci peut &ecirc;tre diff&eacute;rente &agrave; chaque D&eacute;p&ocirc;t. LAVERDY peut &ecirc;tre amen&eacute; &agrave; changer r&eacute;guli&egrave;rement les adresses ou les adapter pour des besoins li&eacute;s &agrave; la technologie Blockchain ou &agrave; stopper son offre de D&eacute;p&ocirc;t pour un Crypto-actif donn&eacute;. Il appartient au Client, pour chaque D&eacute;p&ocirc;t, de s&rsquo;assurer que l&rsquo;adresse de LAVERDY est active en proc&eacute;dant &agrave; l&rsquo;envoi apr&egrave;s cr&eacute;ation d&rsquo;une op&eacute;ration de D&eacute;p&ocirc;t sur son Espace Client. LAVERDY ne saurait &ecirc;tre tenu responsable d&rsquo;un envoi de crypto-actifs &agrave; une adresse d&eacute;sactiv&eacute;e d&egrave;s lors que l&rsquo;op&eacute;ration de D&eacute;p&ocirc;t n&rsquo;a pas &eacute;t&eacute; pr&eacute;alablement cr&eacute;&eacute; sur le Site Internet ou en consultant le Charg&eacute; de Client&egrave;le. Le Client est alors seul responsable de son envoi.&nbsp;</p>
            <p>Le Client est seul responsable de l&rsquo;utilisation de l&rsquo;adresse du Portefeuille de LAVERDY, communiqu&eacute;e par LAVERDY pour le D&eacute;p&ocirc;t. Le Client doit recopier absolument &agrave; l&rsquo;identique cette adresse dans l&rsquo;application de son Portefeuille permettant l&rsquo;envoi de la quantit&eacute; de Crypto-actifs suivant les instructions indiqu&eacute;es par LAVERDY depuis le Site Internet. LAVERDY ne peut en aucun cas &ecirc;tre tenu pour responsable des dommages provoqu&eacute;s par une erreur d&rsquo;adresse de Portefeuille imputable au Client.&nbsp;</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Proc&eacute;dure en ligne</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>La proc&eacute;dure de D&eacute;p&ocirc;t est expliqu&eacute;e sur le Site Internet. A r&eacute;ception d&rsquo;une quantit&eacute; de Crypto-actif par LAVERDY, le Service Conformit&eacute; et Gestion des Risques se r&eacute;serve le droit de bloquer et de refuser le D&eacute;p&ocirc;t pendant un d&eacute;lai de 5 jours ouvr&eacute;s maximum. En cas de refus, le D&eacute;p&ocirc;t est renvoy&eacute; au Client dans un d&eacute;lai maximum de 7 jours ouvr&eacute;s apr&egrave;s pr&eacute;l&egrave;vement de Frais de Retrait et d&rsquo;&eacute;ventuels Frais d&rsquo;Intervention et Frais de Conservation.</p>
            <p>Une fois valid&eacute;, le D&eacute;p&ocirc;t vient cr&eacute;diter le Solde du Compte Crypto correspondant et est disponible &agrave; la Vente, Conversion et au Retrait.</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Proc&eacute;dure par le biais d&rsquo;un Charg&eacute; de Client&egrave;le</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>A distance et pour les Comptes Clients autoris&eacute;s, le Charg&eacute; de Client&egrave;le communique par email l&rsquo;adresse de D&eacute;p&ocirc;t ou le QR-code correspondant, ou affiche au client le QR-code. L&rsquo;op&eacute;ration est confirm&eacute;e par email.&nbsp;</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Retrait</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Le Client est autoris&eacute; &agrave; retirer &agrave; tout moment tout ou partie de la quantit&eacute; d&rsquo;un Crypto-actif donn&eacute; d&eacute;tenue dans son Compte Crypto ouvert chez LAVERDY, d&egrave;s lors que son Compte Client est valid&eacute;, non bloqu&eacute; et non suspendu et que LAVERDY propose le Retrait de ce Crypto-actif. Le Client souhaitant supprimer son compte doit imp&eacute;rativement mettre son Solde de Crypto-actifs &agrave; z&eacute;ro (0) afin que LAVERDY puisse satisfaire sa demande. Si le Solde est inf&eacute;rieur au montant minimum de Retrait ou de Vente, le Client devra express&eacute;ment renoncer au Retrait ou &agrave; la Vente des fonds d&eacute;tenus sur son Compte Crypto. &Agrave; d&eacute;faut de renonciation, LAVERDY facturera 20 euros TTC au Client payable par virement bancaire ou carte bancaire pour effectuer le Retrait et cl&ocirc;turer le Compte&nbsp;Client.&nbsp;</p>
            <p><strong><em>Important &ndash; S&eacute;curisation du Retrait :</em></strong></p>
            <p>Pr&eacute;alablement &agrave; toute op&eacute;ration de Retrait, LAVERDY peut demander au Client d&rsquo;activer le 2FA sur son Compte Client ou effectuer une V&eacute;rification de Connexion.&nbsp;</p>
            <p>Afin de garantir une plus grande s&eacute;curit&eacute;, LAVERDY recommande et peut exiger du Client que les Retraits soient soumis &agrave; des Limites journali&egrave;res et/ou hebdomadaires. Le Client pourra lever ses Limites en suivant la proc&eacute;dure communiqu&eacute;e par le Service Client ou un Charg&eacute; de Client&egrave;le. La proc&eacute;dure aura pour but de v&eacute;rifier que le Client est en parfaite s&eacute;curit&eacute; et qu&rsquo;il est bien &agrave; l&rsquo;origine du Retrait sur une adresse de Retrait qu&rsquo;il a bien valid&eacute;.</p>
            <p>&nbsp;</p>
            <ol start="15">
                <li><strong>Comptes payants</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>Certains types de Compte Client (notamment Platinum et Business) sont payants et permettent &agrave; leur titulaire d&rsquo;acc&eacute;der &agrave; des services d&rsquo;accompagnement, qui sont d&eacute;crits sur le Site Internet et dans la documentation commerciale de LAVERDY.&nbsp;</p>
            <p>&nbsp;</p>
            <ol start="16">
                <li><strong>Annulation d&rsquo;un Service et d&eacute;lai de contestation.</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <ul>
                <li><strong>Par le Client</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Le Client peut annuler de plein droit :</p>
            <ul>
                <li>Pr&eacute;alablement &agrave; la validation d&rsquo;une Transaction, la r&eacute;alisation d&rsquo;un D&eacute;p&ocirc;t ou de la validation d&rsquo;une demande de Retrait&nbsp;;&nbsp;&nbsp;</li>
                <li>Un Achat si son Compte Crypto correspondant n&rsquo;a pas &eacute;t&eacute; cr&eacute;dit&eacute; dans un d&eacute;lai raisonnable ;</li>
                <li>Une Vente si son compta bancaire n&rsquo;a pas &eacute;t&eacute; cr&eacute;dit&eacute; dans un d&eacute;lai raisonnable ;</li>
                <li>&nbsp;</li>
            </ul>
            <p>Le Client dispose d&rsquo;un d&eacute;lai maximum de 7 jours apr&egrave;s paiement de sa Transaction, r&eacute;alisation du D&eacute;p&ocirc;t, confirmation du Retrait ou Retrait des Crypto-actifs pour contester ou signaler un probl&egrave;me &agrave;&nbsp;serviceclient@laverdy.io.&nbsp; Au-del&agrave; de ce d&eacute;lai, LAVERDY ne pourra &ecirc;tre tenu responsable des cons&eacute;quences li&eacute;es &agrave; une op&eacute;ration non r&eacute;alis&eacute;e.</p>
            <p>&nbsp;</p>
            <ul>
                <li><strong>Par LAVERDY</strong></li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <p>Une Transaction peut &ecirc;tre annul&eacute;e de plein droit et sans pr&eacute;avis par LAVERDY en cas de violation des dispositions des CG, et notamment en cas de :</p>
            <ul>
                <li>Suspicion l&eacute;gitime de LAVERDY sur l&rsquo;identit&eacute; v&eacute;ritable ou portant sur le moyen de paiement du Client ;</li>
                <li>Non-respect par le Client des dispositions sur les moyens de paiement&nbsp;;</li>
                <li>Absence de r&eacute;ponses par le Client dans un d&eacute;lai sup&eacute;rieur &agrave; 72 heures aux questions et demandes du service Conformit&eacute; et Gestion des Risques de LAVERDY ;&nbsp;&nbsp;</li>
                <li>De comportement agressif, d&eacute;lictueux, ou de menaces prof&eacute;r&eacute;es par un Client lors de ses communications avec LAVERDY.&nbsp;</li>
            </ul>
            <p>&nbsp;</p>
            <p>En cas d&rsquo;annulation d&rsquo;une Transaction pour l&rsquo;un de ces motifs, LAVERDY remboursera le Client sur la base soit du Prix du Crypto-actif utilis&eacute; pour la Transaction, soit du Prix du Crypto-actif correspondant &agrave; la date de remboursement au choix de LAVERDY. LAVERDY pourra facturer des Frais d&rsquo;Intervention et d&eacute;duire d&rsquo;&eacute;ventuelles pertes occasionn&eacute;es.&nbsp;</p>
            <p>Une Transaction peut &ecirc;tre annul&eacute;e de plein droit et sans pr&eacute;avis par LAVERDY, sans possibilit&eacute; de remboursement, en cas de violation des CG et notamment en cas de&nbsp;:&nbsp;</p>
            <ul>
                <li>Non-paiement du Montant Brut d&rsquo;Achat par le Client ;</li>
                <li>Fourniture d&rsquo;adresse de Retrait erron&eacute;e par le Client en cas de Retrait ;&nbsp;&nbsp;</li>
                <li>Non-transfert ou transfert erron&eacute; par le Client de la nature et quantit&eacute; de Crypto-actif sur un Portefeuille d&eacute;tenu par LAVERDY en cas de D&eacute;p&ocirc;t.</li>
            </ul>
            <p>&nbsp;</p>
            <ol start="17">
                <li><strong>Modalit&eacute;s et moyens de paiement</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>LAVERDY se r&eacute;serve le droit &agrave; tout moment et sans pr&eacute;avis de modifier les moyens de paiement accept&eacute;s sur son Site Internet, &agrave; distance et notamment le type et le pays &eacute;metteur des cartes bancaires autoris&eacute;es pour une Transaction.&nbsp;</p>
            <p>Le Client s&rsquo;engage express&eacute;ment &agrave; utiliser&nbsp;comme moyen de paiement :</p>
            <ul>
                <li>Maximum deux cartes bancaires diff&eacute;rentes sur une m&ecirc;me p&eacute;riode, qui doivent &ecirc;tre valides et au nom du titulaire du Compte. Le Client doit disposer des fonds suffisants pour r&eacute;aliser son Achat. Les cartes bancaires consid&eacute;r&eacute;es comme des moyens de paiement &eacute;lectroniques anonymes ne sont pas autoris&eacute;es ;&nbsp;</li>
                <li>Le virement bancaire &agrave; partir d&rsquo;un compte bancaire &agrave; son nom exact, ou au sien et &agrave; celui de son conjoint, concubin ou partenaire de Pacte Civil de Solidarit&eacute; si sa situation familiale le justifie, ouvert dans un &eacute;tablissement bancaire situ&eacute; en Europe (zone SEPA).&nbsp;</li>
            </ul>
            <p>&nbsp;</p>
            <p>Les Clients disposant d&rsquo;un Compte Client personne morale doivent utiliser la carte bancaire et le compte bancaire de la personne morale et non ceux d&rsquo;une personne physique, et vice versa.&nbsp;</p>
            <p>Toutes les Transactions pay&eacute;es par carte bancaire sur le Site Internet doivent &ecirc;tre s&eacute;curis&eacute;es par le syst&egrave;me &laquo; 3D secure &raquo; permettant la s&eacute;curisation en ligne. L&rsquo;usage d&rsquo;un VPN par le Client est susceptible de bloquer les Achats par carte bancaire sur le Site Internet en raison des restrictions du prestataire de paiement et de LAVERDY. Il est donc demand&eacute; au Client de ne pas avoir recours &agrave; un VPN. LAVERDY ne peut &ecirc;tre responsable des cons&eacute;quences d&rsquo;un &eacute;chec lors d&rsquo;un Achat du fait de l&rsquo;usage du VPN par un Client.&nbsp;</p>
            <p>LAVERDY se r&eacute;serve le droit de v&eacute;rifier les donn&eacute;es personnelles communiqu&eacute;es par le Client et d&rsquo;adopter toutes les mesures jug&eacute;es n&eacute;cessaires &agrave; la v&eacute;rification de l&rsquo;identit&eacute; du Client dont le compte bancaire est d&eacute;bit&eacute;. Cette v&eacute;rification pourra prendre la forme, outre la v&eacute;rification de l&rsquo;identit&eacute; et/ou du domicile du Client, d&rsquo;une demande de communication de tous documents bancaires tels qu&rsquo;un IBAN pr&eacute;alablement &agrave; la validation de la Transaction.</p>
            <p>LAVERDY ne saurait &ecirc;tre responsable des cons&eacute;quences&nbsp;:</p>
            <p>D&rsquo;un d&eacute;faut du moyen de paiement du Client et/ou du syst&egrave;me de paiement imputable au Client ou &agrave; sa banque et/ou au fournisseur de moyens de paiement utilis&eacute; par LAVERDY&nbsp;;&nbsp;</p>
            <p>Du d&eacute;lai de renvoi des fonds vers les comptes bancaires des Clients en cas de refus ou d&rsquo;annulation de l&rsquo;Achat par le Client, par LAVERDY ou par les prestataires de paiement utilis&eacute;s&nbsp;;&nbsp;</p>
            <p>Du d&eacute;lai de r&eacute;ception des virements bancaires sur le compte du Client ou sur le Compte Bancaire Laverdy Capital.</p>
            <p>&nbsp;</p>
            <ol start="18">
                <li><strong>D&eacute;claration fiscale&nbsp;</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>LAVERDY ne r&eacute;alise pas de d&eacute;claration fiscale pour le compte de ses Clients. Il revient aux Clients de d&eacute;clarer leurs plus-values et d&rsquo;effectuer toutes les d&eacute;marches n&eacute;cessaires et obligatoires li&eacute;es au paiement de leurs imp&ocirc;ts selon la l&eacute;gislation en vigueur.&nbsp;</p>
            <p>&nbsp;</p>
            <ol start="19">
                <li><strong>Responsabilit&eacute; et Exon&eacute;ration de responsabilit&eacute;</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>DANS LES LIMITES PERMISES PAR LA LOI, LAVERDY D&Eacute;CLINE TOUTE RESPONSABILIT&Eacute; POUR LES PERTES DE B&Eacute;N&Eacute;FICES, DE REVENUS, DE VALEUR, DE PROFITS OU DE DONN&Eacute;ES, OU LES DOMMAGES ET INT&Eacute;R&Ecirc;TS INDIRECTS, SP&Eacute;CIAUX, CONS&Eacute;CUTIFS, EXEMPLAIRES OU PUNITIFS.</p>
            <p>&nbsp;</p>
            <p>La responsabilit&eacute; de LAVERDY vis-&agrave;-vis du Client ne saurait &ecirc;tre engag&eacute;e en cas d&rsquo;inex&eacute;cution ou de mauvaise ex&eacute;cution des CG du fait du Client, de fraude, n&eacute;gligence ou erreur imputable au Client, du fait de la survenance d&rsquo;un cas de Force Majeure, en cas de D&eacute;faillance Technique ou en cas de r&eacute;alisation d&rsquo;un ou plusieurs &eacute;v&eacute;nements pr&eacute;vus aux articles 10, 11, 12, 13.3, 13.4, 13.5, 14 et 17.&nbsp;</p>
            <p>Le Client est d&ucirc;ment averti des risques entourant les Services et les Crypto-actifs. Dans ce cadre, LAVERDY ne saurait &ecirc;tre responsable :</p>
            <ul>
                <li>De la stabilit&eacute; du Prix des Crypto-actifs en raison de leur grande volatilit&eacute; ;</li>
                <li>De la perte ou divulgation &agrave; un tiers, volontairement ou involontairement, de son 2FA ou de ses identifiants de connexion ou mot de passe&nbsp;;&nbsp;</li>
                <li>D&rsquo;une cyberattaque, d&rsquo;un virus informatique, malware, d&rsquo;une panne, d&rsquo;un vol, d&rsquo;une perte ou d&eacute;faillance de son t&eacute;l&eacute;phone ou ordinateur bloquant l&rsquo;acc&egrave;s &agrave; son Compte Client ou conduisant &agrave; en donner l&rsquo;acc&egrave;s &agrave; un tiers&nbsp;;&nbsp;&nbsp;&nbsp;</li>
                <li>Du cambriolage, vol, extorsion, violence, chantage, escroquerie, manipulation de personne, abus de faiblesse, ou toutes autres infractions p&eacute;nales subies par le Client et ayant pu entra&icirc;ner la perte de Crypto-actifs&nbsp;;</li>
                <li>De la perte ou du vol des Crypto-actifs de quelque mani&egrave;re que ce soit apr&egrave;s leur Retrait &agrave; l&rsquo;adresse publique indiqu&eacute;e par le Client&nbsp;;</li>
                <li>D&rsquo;un usage illicite des Crypto-actifs achet&eacute;s par le biais des Services.</li>
            </ul>
            <p>LAVERDY s&rsquo;efforce, dans la mesure du possible, de maintenir le Site accessible 7 jours sur 7 et 24 heures sur 24. N&eacute;anmoins, l&rsquo;utilisation du Site peut &ecirc;tre temporairement suspendue, en raison d&rsquo;op&eacute;rations techniques de maintenance, de migration ou de mises &agrave; jour, en raison de pannes ou de contraintes li&eacute;es au fonctionnement d&rsquo;Internet ou des r&eacute;seaux de communication utilis&eacute;s.&nbsp;</p>
            <p>Les r&eacute;seaux sur lesquels circulent les donn&eacute;es pr&eacute;sentent des caract&eacute;ristiques et capacit&eacute;s diverses et sont susceptibles d&rsquo;&ecirc;tre satur&eacute;s &agrave; certaines heures de la journ&eacute;e, ce qui est de nature &agrave; affecter leurs d&eacute;lais de t&eacute;l&eacute;chargement ainsi que leur accessibilit&eacute;. LAVERDY ne saurait voir sa responsabilit&eacute; engag&eacute;e en raison d&rsquo;un dysfonctionnement ou d&rsquo;une impossibilit&eacute; d&rsquo;acc&egrave;s au Site imputable &agrave; des tiers, &agrave; un encombrement du r&eacute;seau Internet, &agrave; un mat&eacute;riel inadapt&eacute; du Client, &agrave; une op&eacute;ration de maintenance ou de mise &agrave; jour du Site ou &agrave; toute autre circonstance &eacute;trang&egrave;re &agrave; LAVERDY.</p>
            <p>&nbsp;</p>
            <ol start="20">
                <li><strong>Assurance Responsabilit&eacute; Civile Professionnelle</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>LAVERDY a souscrit une assurance Responsabilit&eacute; Civile Professionnelle des Prestataires de Services sur Actifs Num&eacute;riques aupr&egrave;s de MAAF Entreprise.</p>
            <p>&nbsp;</p>
            <ol start="21">
                <li><strong>Clause r&eacute;solutoire</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>L&rsquo;annulation portant sur l&rsquo;ex&eacute;cution d&rsquo;un Service par LAVERDY ou la suppression d&rsquo;un Compte Client par LAVERDY sera prononc&eacute;e par la r&eacute;ception par le Client d&rsquo;un email &agrave; l&rsquo;adresse du Compte Client &eacute;manant de LAVERDY et sera acquise de plein droit sans autre formalit&eacute;, notamment judiciaire.</p>
            <ol start="22">
                <li><strong>Rendez-vous en pr&eacute;sentiel avec un Charg&eacute; de Client&egrave;le</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>Sauf exception, les bureaux de LAVERDY ne sont pas ouverts au public. Toutefois, dans le cas de la r&eacute;alisation de certains Services, les Clients disposant d&rsquo;un Compte payant peuvent avoir un rendez-vous en pr&eacute;sentiel avec un Charg&eacute; de Client&egrave;le dans les bureaux de LAVERDY ou dans tout autre lieu d&eacute;cid&eacute; d&rsquo;un commun accord entre LAVERDY et le Client.</p>
            <p>LAVERDY se r&eacute;serve le droit de poursuivre en justice et de demander des dommages et int&eacute;r&ecirc;ts pour tout pr&eacute;judice de quelque nature que ce soit &agrave; toute personne ou entit&eacute; qui prof&eacute;rait des menaces &agrave; l&rsquo;encontre de son Personnel ou de dirigeant de LAVERDY ou ses affili&eacute;s, endommagerait ses syst&egrave;mes ou ses bureaux.</p>
            <p>&nbsp;</p>
            <ol start="23">
                <li><strong>Propri&eacute;t&eacute; intellectuelle, Marques et Photographies&nbsp;</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>Le Site Internet, Les marques LAVERDY, LAVERDY CAPITAL, LAVERDY PLATINUM, LAVERDY BUSINESS, LAVERDY WALLET, LAVERDY COIN, LAVERDY ENTREPRISE, LAVERDY CRYPTO, LAVERDY ASSET MANAGEMENT, LAVERDY MANAGEMENT, LAVERDY DEFI, LAVERDY CORP, et les &eacute;l&eacute;ments reproduits sur le Site Internet de LAVERDY constitu&eacute;s notamment de photographies, de visuels, de textes, ouvrages, illustrations, de dessins, logos et images, sont et restent la propri&eacute;t&eacute; exclusive de LAVERDY et sont prot&eacute;g&eacute;s par le droit d&rsquo;auteur, le droit des marques et/ou le droit des brevets. Toute utilisation, reproduction et/ou toute diffusion de ces &eacute;l&eacute;ments, sans autorisation &eacute;crite pr&eacute;alable de LAVERDY expose les contrevenants &agrave; des poursuites judiciaires.</p>
            <p>En cons&eacute;quence, le Client s&rsquo;interdit de reproduire, repr&eacute;senter, extraire et utiliser de quelque mani&egrave;re que ce soit tout ou partie des marques, logos, du Site de LAVERDY.</p>
            <p>Toutes les photographies pr&eacute;sentes sur le Site Internet notamment en illustration des produits sont des photographies non contractuelles et communiqu&eacute;es &agrave; titre illustratif et d&rsquo;exemple uniquement.</p>
            <p>Il est strictement interdit d&rsquo;enregistrer sur un support informatique, de photographier ou de filmer les bureaux, son Personnel et les Clients sans accord expr&egrave;s de la direction g&eacute;n&eacute;rale de LAVERDY.&nbsp;</p>
            <p>&nbsp;</p>
            <ol start="24">
                <li><strong>Donn&eacute;es personnelles et Confidentialit&eacute;&nbsp;</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>LAVERDY s&rsquo;engage &agrave; tout mettre en &oelig;uvre pour assurer la s&eacute;curit&eacute; et la confidentialit&eacute; des donn&eacute;es personnelles conform&eacute;ment aux dispositions l&eacute;gales et r&egrave;glementaires en vigueur.</p>
            <p>LAVERDY s&rsquo;engage &agrave; ce que seul le Personnel habilit&eacute; soit autoris&eacute; &agrave; acc&eacute;der aux donn&eacute;es &agrave; caract&egrave;re personnel du Client. L&rsquo;acc&egrave;s aux locaux et aux serveurs sur lesquels les donn&eacute;es sont collect&eacute;es, trait&eacute;es et archiv&eacute;es est strictement limit&eacute;. Des mesures techniques et organisationnelles appropri&eacute;es ont &eacute;t&eacute; prises afin d&rsquo;interdire l&rsquo;acc&egrave;s &agrave; toute personne non autoris&eacute;e.&nbsp;</p>
            <p>Le Client est inform&eacute; lors de l&rsquo;ouverture de son Compte et dans les pr&eacute;sentes CG que LAVERDY collecte et traite de mani&egrave;re automatis&eacute;e des donn&eacute;es &agrave; caract&egrave;re personnel les concernant. Plus g&eacute;n&eacute;ralement, le Client consent &agrave; l&rsquo;application de la politique de confidentialit&eacute; LAVERDY.&nbsp;La collecte et le traitement des donn&eacute;es &agrave; caract&egrave;re personnel r&eacute;alis&eacute;es par LAVERDY en sa qualit&eacute; de responsable de traitement au sens du r&egrave;glement nᵒ 2016/679 dit R&egrave;glement G&eacute;n&eacute;ral sur la Protection des Donn&eacute;es sont n&eacute;cessaires &agrave; l&rsquo;ouverture d&rsquo;un Compte Client et &agrave; la r&eacute;alisation des Services, &agrave; la constitution de fichiers client&egrave;le et &agrave; la bonne administration des relations commerciales entre les Clients et LAVERDY. Ces donn&eacute;es sont destin&eacute;es &agrave; LAVERDY ainsi qu&rsquo;&agrave; ses prestataires et partenaires situ&eacute;s dans et hors de l&rsquo;Union Europ&eacute;enne aux fins des pr&eacute;sentes, mais ne seront pas utilis&eacute;s par des tiers dans le but de d&eacute;marcher les Clients.&nbsp;</p>
            <p>Le Client dispose &agrave; tout moment d&rsquo;un droit d&rsquo;acc&egrave;s, de modification, de rectification et d&rsquo;opposition pour des motifs l&eacute;gitimes, ainsi que de son droit &agrave; la limitation du traitement ou &agrave; la portabilit&eacute; des donn&eacute;es personnelles le concernant qu&rsquo;il peut exercer en &eacute;crivant directement sous signature et en joignant une copie de sa pi&egrave;ce d&rsquo;identit&eacute;, &agrave; :</p>
            <p><em>LAVERDY CAPITAL SAS</em><br /><em>Service Conformit&eacute; et Gestion des Donn&eacute;es&nbsp;</em><br /><em>10 rue de penthi&egrave;vre, 75008 Paris</em></p>
            <p>Le Client dispose du droit de formuler des directives relatives au sort de ses donn&eacute;es personnelles apr&egrave;s son d&eacute;c&egrave;s qui peuvent &ecirc;tre adress&eacute;es &agrave; l&rsquo;adresse indiqu&eacute;e ci-dessus.&nbsp;</p>
            <p>Les donn&eacute;es personnelles du Client sont conserv&eacute;es pour la plus longue des dur&eacute;es n&eacute;cessaires au respect des dispositions l&eacute;gales et r&eacute;glementaires applicables.</p>
            <p>La majorit&eacute; des informations sont conserv&eacute;es pendant une dur&eacute;e minimum de 5 ans conform&eacute;ment aux obligations l&eacute;gales et r&eacute;glementaires li&eacute;es &agrave; la lutte contre le blanchiment de capitaux et le financement du terrorisme.&nbsp;</p>
            <p>En cas de litige li&eacute; &agrave; l&rsquo;utilisation de ses donn&eacute;es par LAVERDY, le Client est inform&eacute; qu&rsquo;il peut saisir la Commission Nationale de l&rsquo;Informatique et des Libert&eacute;s.</p>
            <p>&nbsp;</p>
            <ol start="25">
                <li><strong>Service Client</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>Pour toute information ou question, le Service Client LAVERDY est joignable :</p>
            <p>Par e-mail &agrave; l&rsquo;adresse&nbsp;serviceclient@laverdy.io&nbsp;</p>
            <p>Par t&eacute;l&eacute;phone, au +33153009260, aux horaires indiqu&eacute;s par la messagerie&nbsp;;&nbsp;&nbsp;</p>
            <p>Par courrier &agrave; adresser &agrave; LAVERDY CAPITAL, Service Client, 10 rue de penthi&egrave;vre 75008 Paris &ndash; France.&nbsp;</p>
            <p>&nbsp;</p>
            <ol start="26">
                <li><strong>Litige &ndash; M&eacute;diation</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>En cas de litige entre un Client et LAVERDY, le Client devra en informer le service client aux coordonn&eacute;es indiqu&eacute;es &agrave; l&rsquo;article 23 ci-dessus&nbsp; en prenant soin de pr&eacute;ciser ses coordonn&eacute;es et de fournir toute information &agrave; LAVERDY pour lui permettre d&rsquo;appr&eacute;cier l&rsquo;origine et les incidences de la contestation.&nbsp;</p>
            <p>Si le litige persiste, le Client pourra contacter le service de r&egrave;glement en ligne des litiges de la Commission europ&eacute;enne &agrave; l&rsquo;adresse suivante :&nbsp;<a href="https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.chooseLanguage">https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.chooseLanguage</a>.</p>
            <p>LAVERDY ne dispose pas d&rsquo;un service de m&eacute;diation interne.</p>
            <p>&nbsp;</p>
            <ol start="27">
                <li><strong>Non-renonciation&nbsp;</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>Le fait que LAVERDY ne se pr&eacute;vale pas, &agrave; un moment ou &agrave; un autre de l&rsquo;une quelconque des dispositions des pr&eacute;sentes CG, ne saurait &ecirc;tre interpr&eacute;t&eacute; comme une renonciation par LAVERDY &agrave; se pr&eacute;valoir ult&eacute;rieurement de l&rsquo;une quelconque desdites CG comme ne saurait &ecirc;tre consid&eacute;r&eacute; comme une modification des CG.</p>
            <p>&nbsp;</p>
            <ol start="28">
                <li><strong>Comp&eacute;tence territoriale et droit applicable&nbsp;</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>Les pr&eacute;sentes CG sont soumises &agrave; la loi fran&ccedil;aise.</p>
            <p>En cas de litige, le Client est invit&eacute; &agrave; formuler une r&eacute;clamation conform&eacute;ment &agrave; l&rsquo;article 25. Aucune action ne pourra &ecirc;tre intent&eacute;e contre LAVERDY sans qu&rsquo;une mise en demeure par lettre recommand&eacute;e avec demande d&rsquo;avis de r&eacute;ception, ne lui ait &eacute;t&eacute; adress&eacute;e pr&eacute;alablement.</p>
            <p>Sauf disposition l&eacute;gale contraire, tous les litiges auxquels les Services conclus en application des pr&eacute;sentes CG pourraient donner lieu, concernant tant leur validit&eacute;, leur interpr&eacute;tation, leur ex&eacute;cution, leur r&eacute;siliation, leurs cons&eacute;quences et leurs suites, seront soumis aux tribunaux comp&eacute;tents du ressort de la Cour d&rsquo;Appel de Paris.</p>
            <ol start="29">
                <li><strong>Informations administratives</strong></li>
            </ol>
            <p><strong>&nbsp;</strong></p>
            <p>LAVERDY CAPITAL<br />Capital Social : 5 500 &euro;<br />Adresse : 10 Rue de penthi&egrave;vre 75008 Paris &ndash; France<br />834 900 219 RCS de PARIS<br />E-mail&nbsp;:&nbsp;serviceclient@laverdy.io</p>
            <p>&nbsp;</p>
        </div>
    )
}

export default CGVpage