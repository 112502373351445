import Parse from 'parse';


export async function AddNewActivity(status, user, type, coin, amount, pairprice, fee, euramount, totalamount, krakenTransaction, payment) {
    const Activity = Parse.Object.extend("Activity");
    const activity = new Activity();
    activity.set("createdBy", user);
    activity.set("status", status);
    activity.set("type", type);
    activity.set("currency", coin.symbol);
    activity.set("amount", amount);
    activity.set("pairPrice", pairprice);
    activity.set("fee", fee);
    activity.set("eurAmount", euramount);
    activity.set("totalAmount", totalamount);
    activity.set("krakenTransaction", krakenTransaction);
    activity.set("payment", payment ? payment : '');
    let newactivity = await activity.save();
    return newactivity
}

export async function AddWithdraw(user, amount, holder, iban, bic, activity) {
    const Withdraw = Parse.Object.extend("Withdraw");
    const withdraw = new Withdraw();
    withdraw.set("createdBy", user);
    withdraw.set("treated", false);
    withdraw.set("amount", amount);
    withdraw.set("holder", holder);
    withdraw.set("iban", iban);
    withdraw.set("bic", bic);
    withdraw.set("relatedActivity", activity);
    let newwithdraw = await withdraw.save();
    return newwithdraw
}

export async function getKyc(kycId) {
    const Kyc = Parse.Object.extend('Kyc');
    const query = new Parse.Query(Kyc);
    query.equalTo('objectId', kycId);
    let currentKyc = await query.first().catch(err => {
        console.log('error', err);
        return { error: err };
    });
    return currentKyc
}