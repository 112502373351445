import React, { useEffect, useState } from 'react'
import './SigninPage.css'
import signupImg from "../../assets/images/signup.svg"
import { useHistory } from 'react-router'
import { useForm } from 'react-hook-form'
import Parse from 'parse';
import logoNav from '../../assets/icons/logo-nav.svg'
import { toast } from 'react-toastify';
import eyeicon from '../../assets/icons/sidebar/icon-eye.svg'
import eyeiconclose from '../../assets/icons/sidebar/icon-eye-close.svg'

const SigninPage = () => {
    const history = useHistory();
    const { register, handleSubmit } = useForm();
    const [passwordShown, setPasswordShown] = useState(false);

    useEffect(() => {
        if (Parse.User.current()) {
            history.push('/')
        }
    }, [history])

    async function signIn(value) {
        try {
            let user = await Parse.User.logIn(value.email.toLowerCase(), value.password)
            notify();
            user.get('emailVerified') ? history.push('/') : history.push('/emailvalidation')
        } catch (e) {
            console.log('error', e)
            notifyError()
        }
    }

    const onSubmit = (d) => {
        signIn(d)
    }

    const notifyError = () => toast.error('🦄 Email et/ou mot de passe incorrect', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const notify = () => toast('🦄 Heureux de te revoir parmi nous', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });


    return (
        <div className="signup flex">
            <div className='container-img-signup' style={{ width: '50%', height: '100vh' }}>
                <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt='signup img' src={signupImg} />
            </div>
            <div style={{ height: '100vh' }} className='flex-center column form-container'>
                <div className='nav-logo-mobile'>
                    <img src={logoNav} alt='logo nav' />
                </div>
                <div className="width-signin" style={{ width: '50%', marginTop: '3rem' }}>
                    <h1>Bienvenue!</h1>
                    <p className='blue-text-signin' style={{ textDecoration: 'none' }} onClick={() => history.push('/')}>{`< Retour`}</p>
                </div>
                <form className="width-signin" onSubmit={handleSubmit(onSubmit)} style={{ width: '50%' }}>
                    <div>
                        <span>Email</span>
                        <div className="input-module-border" style={{ width: '100%', height: '52px' }}>
                            <input type='text' {...register("email")} className='input-module' />
                        </div>
                    </div>
                    <div>
                        <span>Mot de passe</span>
                        <div className="input-module-border" style={{ width: '100%', height: '52px', position: 'relative' }}>
                            <input autoComplete="current-password" name='password' type={passwordShown ? "text" : "password"} {...register("password")} className='input-module' />
                            <img style={{position: 'absolute', right: '1rem'}} alt='eye' onClick={() => setPasswordShown(!passwordShown)} src={passwordShown ? eyeicon : eyeiconclose}/>
                        </div>
                    </div>
                    <div className='flex-center' style={{ width: '100%', marginTop: '3rem' }}>
                        <button type='submit' className='custom-btn'>Se connecter</button>
                        <p className='blue-text-signin' onClick={() => history.push('/password')} style={{ marginLeft: '2rem' }}>Mot de passe oublié?</p>
                    </div>
                    <div className='flex-center column' style={{ width: '100%', marginTop: '3rem' }}>
                        <p className='black-text-signin'>Vous ne faites pas encore partie du club?</p>
                        <p className='blue-text-signin' onClick={() => history.push('/signup')}>Rejoindre le club</p>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SigninPage