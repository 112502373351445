import React from 'react'
// import './TestStripe.css'
import { Elements, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51J7GAlLTiJgtAI63d80Fwuk4AW9JaJvj52WhbRjFyd8jj8NW6Sv1R8JRULPYo11uFMQh8ZIkeUbBvtTfiiARibAp00DvxQyQt6');

const TestStripe = () => {
    return (
        <Elements stripe={stripePromise}>
            {/* <MyCheckoutForm /> */}
            {/* <h1>TEst de stripe</h1> */}
            <div className='flex-center' style={{width: '400px', background: 'white', height: '50px'}}>
            <CardElement
                options={{
                    hidePostalCode: true,
                    style: {
                        base: {
                            fontSize: '16px',
                            color: '#424770',
                            '::placeholder': {
                                color: '#aab7c4',
                            },
                        },
                        invalid: {
                            color: '#9e2146',
                        },
                    },
                }}
            />
            </div>
        </Elements>
    )
}

export default TestStripe