import React, { useEffect } from 'react'
import './BankTransferModal.css'
import Modal from 'react-modal';
import closeIcon from '../../assets/images/close_icon.svg'
import bankTransfer from '../../assets/images/bank-img.svg'
import { useWindowSize } from '../../lib/responsive';
import Parse from 'parse';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        borderRaduis: '20px',
        transform: 'translate(-50%, -50%)',
        height: '700px',
        width: '366px'
    }
};

const customStylesMobile = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        borderRaduis: '20px',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        width: '100%'
    }
};

function BankTransferModal({ open , close }) {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    let windowSize = useWindowSize()

    useEffect(() => {
        setIsOpen(open)
    }, [open])

    function closeModal() {
        setIsOpen(false);
        close()
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={windowSize.width > 1000 ? customStyles : customStylesMobile}
            contentLabel="Example Modal"
            overlayClassName="Overlay"
            className="Modal"
            shouldCloseOnOverlayClick={false}
        >
            <div className='banktransfer__modal' style={{ position: 'relative' }}>
                <img onClick={() => closeModal()} className='close-img-modal' src={closeIcon} alt='close icon' />
                <div className='kyc-header'>
                    <h1>Virement bancaire</h1>
                </div>
                <hr />
                <div className='banktransfer'>
                    <p><strong>Coordonnées bancaire ci-dessous</strong></p>
                    <div className='banktransfer__container'>
                        <div className='flex justify-between'>
                            <p>iban:</p>
                            <p><strong>FR95 3000 2030 0000 0070 7502 G20</strong></p>
                        </div>
                        <div className='flex justify-between'>
                            <p>BIC:</p>
                            <p><strong>CRLYFRPP</strong></p>
                        </div>
                        <div className='flex justify-between'>
                            <p>Titulaire de compte : </p>
                            <p><strong>LAVERDY CAPITAL</strong></p>
                        </div>
                        <div className='flex justify-between'>
                            <p>Domiciliation: </p>
                            <p><strong>LCL MONTPELLIER (03000)</strong></p>
                        </div>
                        <div className='flex justify-between'>
                            <p className='ref-bank'>Référence <strong>obligatoire</strong> à inscrire sur le virement :</p>
                            <p><strong>{Parse.User.current().id}</strong></p>
                        </div>
                    </div>
                    <p><strong>ATTENTION</strong>: conformément à la réglementation française, l'émetteur du virement doit avoir <strong>exactement</strong> le même nom que celui enregistré sur Laverdy.io</p>
                    <p>Votre compte sera crédité sous 7 jours ouvrés après réception de votre virement.</p>
                    <p>Un virement provenant d’un autre nom ou sans référence sera rejeté et des frais de 20€ttc seront facturés.</p>
                    <div className='flex-center' style={{marginTop: '2rem'}}>
                        <img src={bankTransfer} alt='transfer bank' />
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default BankTransferModal