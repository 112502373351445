import React, { useEffect, useState } from 'react'
import './UserCardComponent.css'
import Parse from 'parse';
import iconcard from '../../assets/icons/sidebar/icon-card.svg'
import iconbutton from '../../assets/icons/sidebar/icon-button.svg'
import { SubscriptionType } from '../../static/Subscription';
import { useHistory } from 'react-router-dom';

const UserCardComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);
    const history = useHistory();

    useEffect(() => {
        const currentUser = Parse.User.current();
        if (currentUser) {
            setUser(currentUser)
            setIsLoading(false)
        }
    }, [])

    return (
        <>
            {
                isLoading ? <div className="user-card"><p>...loading</p></div> :
                    <div onClick={() => {history.push('/settings')}} className={user.get('subscription') === SubscriptionType.PRENIUM ? 'user-card-prenium' : 'user-card'} style={{ marginBottom: '2rem', height: '90px', cursor: 'pointer' }}>
                        <div style={{ width: '100%' }}>
                            <div className='flex' style={{justifyContent: 'space-between'}}>
                                <h1 className='user-card-name'>Bienvenue <strong>{user.get('firstname')}</strong>.</h1>
                                {user.get('subscription') === SubscriptionType.PRENIUM ? <p>Statut: <strong>Club Platinum</strong></p> : <p>Statut: <strong>Club Classic</strong></p>}
                            </div>
                            {isLoading ? <p>...loading</p> : <p style={{marginTop: '0px', textAlign: 'right'}}>ID: <strong>{user.id}</strong></p>}
                        </div>
                        <img style={{ position: 'absolute', bottom: '10px', left: '10px' }} src={iconcard} alt='logo card laverdy' />
                        <div style={{ width: '100%', marginTop: '-12px' }} className='flex-center'>
                            <img src={iconbutton} alt='logo setting' />
                        </div>
                    </div>
            }
        </>
    )
}

export default UserCardComponent