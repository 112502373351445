import React, { useEffect, useState } from 'react'
import ActivityComponent from '../../components/ActivityComponent/ActivityComponent'
import CoinListComponent from '../../components/CoinListComponent/CoinListComponent'
import SummaryWalletComponent from '../../components/SummaryWalletComponent/SummaryWalletComponent'
import TotalEvolutionComponent from '../../components/TotalEvolutionComponent/TotalEvolutionComponent'
import './DashboardPage.css'
import Parse from 'parse';
import ModalKycComponent from '../../components/ModalKycComponent/ModalKycComponent'
import { useHistory } from 'react-router'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import { useWindowSize } from '../../lib/responsive'
import UserCardComponent from '../../components/UserCardComponent/UserCardComponent'
import logoNav from '../../assets/icons/logo-nav.svg'


const DashboardPage = () => {
    const [wallet, setWallet] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    let windowSize = useWindowSize()

    useEffect(() => {
        async function getWallet() {
            const currentUser = Parse.User.current();
            if (currentUser) {
                const Wallet = Parse.Object.extend("Wallet");
                const query = new Parse.Query(Wallet);
                query.equalTo("objectId", currentUser.get('wallet').id);
                const subscribe = await query.subscribe();
                let wallet = {}
                subscribe.on('open', async () => {
                    wallet = await query.find()
                    setWallet(wallet[0])
                    setIsLoading(false)
                })
                subscribe.on('update', (wallet) => {
                    setWallet(wallet)
                })
                subscribe.on('close', () => {
                    console.log('subscription closed');
                });
            } else {
                history.push('/signin')
            }
        }
        getWallet()
    }, [history])

    return (
        <>
            {
                isLoading ? <LoadingComponent height={100} /> :
                    <div className='dashboard flex'>
                        <div style={{ width: windowSize.width > 1350 ? '80%' : '100%', padding: windowSize.width > 1000 ? '3rem' : '0' }}>
                            {
                                windowSize.width < 1000 && <>
                                    <div className='flex-center'>
                                        <img src={logoNav} alt='logo laverdy' />
                                    </div>
                                    <UserCardComponent />
                                </>
                            }
                            <TotalEvolutionComponent wallet={wallet} />
                            <div className='flex flex-column-responsive' style={{ justifyContent: 'space-between' }}>
                                <SummaryWalletComponent wallet={wallet} />
                                <CoinListComponent />
                            </div>
                        </div>
                        {
                            windowSize.width > 1350 && <div style={{ width: '20%', backgroundColor: 'white' }}>
                                <ActivityComponent />
                            </div>
                        }
                        <ModalKycComponent></ModalKycComponent>
                    </div>
            }
        </>
    )
}


export default DashboardPage