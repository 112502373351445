import React, { useEffect, useState } from 'react'
import './HistoryCoinActivityComponent.css'
import Parse from 'parse';
import ActivityCardComponent from '../ActivityCardComponent/ActivityCardComponent'
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import { profitLossCalculation, roundDecimal } from '../../lib/number';


const HistoryCoinActivityComponent = ({ coinsymbol, eurValueWallet }) => {
    const [activities, setActivities] = useState([]);
    const [profitLoss, setProfitLoss] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        async function getActivities() {
            var query = new Parse.Query("Activity");
            query.equalTo("createdBy", Parse.User.current());
            query.equalTo("currency", coinsymbol);
            query.ascending("createdAt");
            const subscription = await query.subscribe();
            let currentActivities = {};
            let activityList = []
            const printcurrentActivities = () => {
                activityList = []
                Object.keys(currentActivities).forEach(id => {
                    activityList.unshift({
                        currency: currentActivities[id].toJSON().currency,
                        status: currentActivities[id].toJSON().status,
                        amount: currentActivities[id].toJSON().amount,
                        euramount: currentActivities[id].toJSON().eurAmount,
                        type: currentActivities[id].toJSON().type,
                        createdAt: currentActivities[id].toJSON().createdAt
                    })
                });
                setActivities(activityList);
                setProfitLoss(profitLossCalculation(activityList, eurValueWallet))
                // setProfitLoss(profitLossCalculation(activityList, 18.60))
            };
            subscription.on('open', async () => {
                currentActivities = {};
                currentActivities = (await query.find()).reduce((currentActivities, todo) => ({
                    ...currentActivities,
                    [todo.id]: todo
                }), currentActivities);
                printcurrentActivities();
                setIsLoading(false)
            });
            subscription.on('create', activity => {
                currentActivities[activity.id] = activity;
                printcurrentActivities();
            });
        }
        setIsLoading(true);
        getActivities();
    }, [coinsymbol, eurValueWallet])


    return (
        <div>
            {isLoading ? <LoadingComponent height={100} /> :
                <div>{eurValueWallet > 0 &&
                    <div>
                        <p className='history-title'>Statistiques</p>
                        <div className='flex-center' style={{ width: '100%', justifyContent: 'space-between' }}>
                            <div style={{ textAlign: 'center', width: '48%' }} className='profitloss__container'>
                                <p className='solde-wallet'>profits / pertes</p>
                                {profitLoss.profitloss > 0 ? <p className='solde-wallet' style={{ color: '#07bc0c' }}>+{roundDecimal(profitLoss.profitloss, 2)} €</p> : <p className='solde-wallet' style={{ color: 'red' }}>{roundDecimal(profitLoss.profitloss, 2)} €</p>}
                            </div>
                            <div style={{ textAlign: 'center', width: '48%' }} className='profitloss__container'>
                                <p className='solde-wallet'>plus-value</p>
                                {profitLoss.percentage > 0 ? <p className='solde-wallet' style={{ color: '#07bc0c' }}>+{roundDecimal(profitLoss.percentage, 2)} %</p> : <p className='solde-wallet' style={{ color: 'red' }}>{roundDecimal(profitLoss.percentage, 2)} %</p>}
                            </div>
                        </div>
                    </div>
                }
                    <p className='history-title'>Historique de transactions</p>
                    {
                        activities.map(
                            (activity, index) => {
                                return (
                                    <div key={index}>
                                        <ActivityCardComponent activity={activity} />
                                    </div>
                                )
                            }
                        )
                    }
                </div>
            }
        </div>
    )
}

export default HistoryCoinActivityComponent