import { SubscriptionType } from "../static/Subscription";

export function roundDecimal(nombre, precision){
    precision = precision || 2;
    var tmp = Math.pow(10, precision);
    return Math.round( nombre * tmp ) / tmp;
}

export function computeCoinToEur(coinprice, nbrcoin) {
    let price = coinprice * nbrcoin
    return roundDecimal(price, 2)
}

export function computeAmountWithPercentage(amount, percentage) {
    let result = amount * (percentage / 100);
    return result;
}

export function formatDate(date) {
    date = new Date(date);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    if (dt < 10) {
        dt = '0' + dt;
    }
    if (month < 10) {
        month = '0' + month;
    }
    let result =  dt + '-' + month + '-' + year
    return (result);
}

export function formatDateBirthday() {
    let date = new Date();
    let year = date.getFullYear() - 18;
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    if (dt < 10) {
        dt = '0' + dt;
    }
    if (month < 10) {
        month = '0' + month;
    }
    let result =  year + '-' + month + '-' + dt
    return (result);
}

export function formatHours(date) {
    date = new Date(date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    if (hours < 10) {
        hours = '0' + hours;
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    let result =  hours + ':' + minutes;
    return (result);
}


export function calculateFee(amount, sub) {
    let fee = sub === SubscriptionType.PRENIUM ? roundDecimal(amount * 0.025) : roundDecimal(amount * 0.05)
    // console.log('FEE Calculate', fee)
    if (fee < 1.50) {
        fee = 1.50;
        // console.log('FEE RECALCULE')
        return fee;
    }
    return fee
}




export function profitLossCalculation(activities, eurValueWallet) {
    let eurTotalValue = 0;
    activities.forEach(e => {
        if (e.type === 'buy') {
            eurTotalValue += e.euramount
        } else {
            eurTotalValue -= e.euramount
        }
    });
    let profitloss = eurValueWallet - eurTotalValue;
    let percentage = (eurValueWallet - eurTotalValue) / eurTotalValue * 100;
    return {percentage, profitloss}
}