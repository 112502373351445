import React, { useEffect, useState } from 'react'
import { calculateFee, formatDate, roundDecimal } from '../../lib/number';
import { ActivityStatus, ActivityType } from '../../static/Activity';
import validationOrderImg from '../../assets/images/validation_order.svg'
import './BuyAssetComponent.css'
import { getPriceOfCoin } from '../../static/Coins';
import { AddNewActivity } from '../../lib/parseTools';
import Parse from 'parse';
import { notifyError } from '../../lib/notification';
import LoadingComponent from '../LoadingComponent/LoadingComponent'

const BuyAssetComponent = ({ coin, setCurrentInterval }) => {
    const [step, setStep] = useState(0);
    const [pair, setPair] = useState({
        price: 0
    });
    const [amount, setAmount] = useState(0);
    const [wallet, setWallet] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(true);
    const [refresh, setRefresh] = useState(null);
    const [minimalBuy, setMinimalBuy] = useState(25);

    useEffect(() => {
        async function getPriceCoin() {
            let coinpricevalue = await getPriceOfCoin(coin);
            setPair(coinpricevalue);
            setMinimalBuy(roundDecimal(coin.orderMin * coinpricevalue.price, 2) + 1)
        }
        getWallet()
        getPriceCoin()
    }, [coin])


    useEffect(() => {
        if (step === 1) {
            let interval = setInterval(async () => {
                let coinpricevalue = await getPriceOfCoin(coin);
                setPair(coinpricevalue);
            }, 1000);
            setRefresh(interval);
        }
    }, [step, coin])

    async function getWallet() {
        const currentUser = Parse.User.current();
        if (currentUser) {
            setCurrentUser(currentUser);
            const Wallet = Parse.Object.extend("Wallet");
            const query = new Parse.Query(Wallet);
            query.equalTo("objectId", currentUser.get('wallet').id);
            const results = await query.find();
            setWallet(results[0])
            setIsLoading(false)
        } else {
            console.log('pas connecter')
        }
    }

    function amountToWantbuy() {
        const eurWallet = wallet.get('EUR')
        if (amount <= 0) {
            notifyError('❌ Achat insuffisant ❌');
            return
        } if (amount > eurWallet) {
            notifyError('❌ Fond insuffisant ❌');
            return
        } if (amount < minimalBuy) {
            notifyError('❌ Achat insuffisant ❌');
            return
        }
        else {
            finishInterval()
            setStep(step + 1)
        }
    }

    function finishInterval() {
        if (step === 2) {
            clearInterval(refresh);
        } else { 
            clearInterval(refresh);
        }
    }

    async function subtractToWallet() {
        const eurWallet = wallet.get('EUR')
        let newAmountWallet = eurWallet - amount;
        let fee = calculateFee(amount, Parse.User.current().get('subscription'));
        let buy = amount - fee;
        let coinAmount = roundDecimal(buy / pair.price, 7)
        let transaction = await Parse.Cloud.run('KrakenBuy', {messages: 'salut les potes', pair: coin.krakenPair, volume: coinAmount})
        .catch(error => {
            notifyError('une erreur est survenue !')
            console.log('Error update wallet: ', error)
            throw new Error('Une erreur est survenue')
        });
        wallet.set('EUR', roundDecimal(newAmountWallet, 2))
        wallet.set(coin.symbol, roundDecimal(coinAmount + wallet.get(coin.symbol), 7))
        await wallet.save().catch(error => console.log('Error update wallet: ', error));
        setStep(step + 1)
        finishInterval()
        let eurAmount = buy;
        let totalAmount = amount;
        let activity = await AddNewActivity(
            ActivityStatus.VALIDATE,
            currentUser,
            ActivityType.BUY,
            coin,
            coinAmount,
            pair.price,
            fee,
            eurAmount,
            totalAmount,
            transaction.buy.result.txid[0],
        );
        Parse.Cloud.run("emailTransaction", {
            activityId: activity.id,
            currency: activity.get('currency'),
            payment: 'SOLDE',
            date: formatDate(activity.get('createdAt')),
            totalAmount: activity.get('totalAmount'),
            type: activity.get('type'),
            fee: fee,
            eurAmount: activity.get('eurAmount'),
            email: currentUser.get('email')
        }).then(test =>
            console.log('email send')
        ).catch(error => console.log("error =>", error));
    }

    // const notifyError = () => toast.error(`🦄 Une erreur c'est produite lors de l'achat`, {
    //     position: "bottom-center",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    // });

    const buyAsset = () => {
        if (step === 0) {
            return (amountTobuy())
        } else if (step === 1) {
            return (previewOrder())
        } else if (step === 2) {
            return (validationOrder())
        }
    }

    const amountTobuy = () => {
        return (
            <>
                <div className='amount-container' style={{ paddingBottom: '1rem' }}>
                    <div className='flex-center'>
                        <input onChange={(e) => setAmount(e.target.value)} className='amount amount-input' placeholder='0' type='number' /><span className='amount'>€</span>
                    </div>
                    <p>Vous pouvez acheter jusqu’à {isLoading ? ' ...' : wallet.get('EUR')}€</p>
                    <p>achat minimum {roundDecimal((coin.orderMin * pair.price) + 1, 2)} €  ≈ {coin.orderMin} {coin.symbol}</p>
                    {/* <p>achat minimum {roundDecimal(coin.orderMin * pair.price, 2) + 1} €  ≈ {coin.orderMin} {coin.symbol}</p> */}
                    <button onClick={() => amountToWantbuy()} className='custom-btn flex-center padding-btn' style={{ textAlign: 'center', fontSize: '20px', marginTop: '2rem' }}>Suivant</button>
                </div>
            </>
        )
    }

    const previewOrder = () => {
        let buy = amount - calculateFee(amount, Parse.User.current().get('subscription'));
        return (
            <>
                <div className='order-container'>
                    <div className='flex-center'>
                        <p className='order__total-coin' style={{ backgroundImage: `linear-gradient(45deg,${coin.color.start}, ${coin.color.end})` }}>{roundDecimal(buy / pair.price, 7)}{coin.symbol}</p>
                    </div>
                    <div className="flex justify-space">
                        <p className='order__description'>Payer avec</p>
                        <p className='order__value'>{wallet.get('EUR')} €</p>
                    </div>
                    <div className="flex justify-space">
                        <p className='order__description'>Prix</p>
                        <p className='order__value'>{pair.price} € / {coin.symbol}</p>
                    </div>
                    <div className="flex justify-space">
                        <p className='order__description'>Achat</p>
                        <p className='order__value'>{buy} €</p>
                    </div>
                    <div className="flex justify-space">
                        <p className='order__description'>Frais Laverdy.io</p>
                        <p className='order__value'>{calculateFee(amount, Parse.User.current().get('subscription'))} €</p>
                    </div>
                    <div className="flex justify-space">
                        <p className='order__description'>Total</p>
                        <p className='order__value'>{amount} €</p>
                    </div>
                    <div className='flex-center'>
                        <button onClick={() => subtractToWallet()} className='custom-btn flex-center padding-btn' style={{ textAlign: 'center', fontSize: '20px', marginTop: '2rem' }}>Acheter maintenant</button>
                    </div>
                </div>
            </>
        )
    }

    const validationOrder = () => {
        let buy = amount - calculateFee(amount, Parse.User.current().get('subscription'))
        return (
            <>
                <div className='validation-order-container flex-center column'>
                    <span className="validation-text">Votre compte a été crédité de</span>
                    <p className='order__total-coin' style={{ backgroundImage: `linear-gradient(45deg,${coin.color.start}, ${coin.color.end})` }}>{roundDecimal(buy / pair.price, 7)}{coin.symbol}</p>
                    <img style={{ width: '35%' }} alt='validation order' src={validationOrderImg} />
                </div>
            </>
        )
    }

    return (
        <>
            {
                isLoading ? <div><LoadingComponent height={35}/></div> :
                    <div style={{ padding: '1rem' }}>
                        {buyAsset()}
                    </div>
            }
        </>
    )
}

export default BuyAssetComponent