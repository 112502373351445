import React, { useEffect, useState } from 'react'
import './MarketplaceCardComponent.css'
import { useHistory } from 'react-router';
import { roundDecimal } from '../../lib/number';
import GraphCoinComponent from '../GraphCoinComponent/GraphCoinComponent';
import { useWindowSize } from '../../lib/responsive';
import BuyAssetModal from '../BuyAssetModal/BuyAssetModal';
import SellAssetModal from '../SellAssetModal/SellAssetModal';
import { notifyError } from '../../lib/notification';

const MarketplaceCardComponent = ({ coin, validateUser }) => {
    const [pair, setPair] = useState({
        price: 0
    });

    const history = useHistory();
    let windowSize = useWindowSize();
    const [isValidate, setIsValidate] = useState(false);
    const [openBuy, setOpenBuy] = useState(false);
    const [openSell, setOpenSell] = useState(false);

    useEffect(() => {
        setIsValidate(validateUser)
        let currentCoin = coin
        function extractTickers(json) {
            var tickers = Object.keys(json.result).map(key => createTicker(key, json.result[key]))
            tickers.sort((a, b) => b.last24TradeCount - a.last24TradeCount)
            return tickers
        }

        function createTicker(key, value) {
            return {
                assetPair: assetPair(key),
                price: roundDecimal(value.c[0], 2)
            }
        }

        function assetPair(s) {
            return s.replace(/[XZ]([A-Z]{3})[XZ]([A-Z]{3})/g, '$1/$2')
        }

        fetch(`https://api.kraken.com/0/public/Ticker?pair=${currentCoin.krakenId}EUR`)
            .then(response => response.json())
            .then(data => {
                setPair(extractTickers(data))
            });
    }, [coin, validateUser])

    function closeModal() {
        setOpenBuy(false)
        setOpenSell(false)
    }

    function navigateToWallet() {
        isValidate ? history.push(`/wallet/${coin.symbol}`) : notifyError('Vous ne pouvez pas effectuer cette action, votre compte est en cours de vérification.')
    }

    return (
        <>
            {
                windowSize.width > 1000 ?
                    <div className='market-list-container'>
                        <div className='coin-wallet marketplace-card'>
                            <div className='flex' style={{ position: 'relative', padding: '1rem' }}>
                                {
                                    pair[0] && <p className='current-asset-price-market value' style={{ color: coin.color.end }}>  ≈  {pair[0].price}€</p>
                                }
                                <div style={{ width: '10%' }}>
                                    <div className='flex'>
                                        <img onClick={() => { history.push(`/wallet/${coin.symbol}`) }} style={{ marginRight: '1rem', cursor: 'pointer' }} src={coin.logo} alt='eth icon' />
                                        <div>
                                            <h1 onClick={() => { history.push(`/wallet/${coin.symbol}`) }} style={{ color: coin.color.end, cursor: 'pointer' }}>{coin.symbol}</h1>
                                            <h2 onClick={() => { history.push(`/wallet/${coin.symbol}`) }} style={{ cursor: 'pointer' }}>{coin.name}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center" style={{ marginTop: '1rem', width: '70%', overflow: 'hidden' }}>
                                    <GraphCoinComponent coin={coin} width={1000} height={150} />
                                </div>
                                {
                                    isValidate ? <div className='flex-center column' style={{ width: '20%' }}>
                                        <button className='button-market-gestion' style={{ background: `linear-gradient(270deg, ${coin.color.start} 0%, ${coin.color.end} 100%),  ${coin.color.start}` }} onClick={() => setOpenBuy(true)}>Acheter</button>
                                        <button className='button-market-gestion' style={{ background: `linear-gradient(270deg, ${coin.color.start} 0%, ${coin.color.end} 100%),  ${coin.color.start}` }} onClick={() => setOpenSell(true)}>Vendre</button>
                                        <button onClick={() => { history.push(`/wallet/${coin.symbol}`) }} className='button-market-gestion' style={{ background: `linear-gradient(270deg, ${coin.color.start} 0%, ${coin.color.end} 100%),  ${coin.color.start}` }}>Gérer mon portefeuille</button>
                                    </div> : <div className='flex-center column' style={{ width: '20%' }}>
                                        <button className='button-market-gestion' style={{ background: '#666666' }} onClick={() => notifyError('Vous ne pouvez pas effectuer cette action, votre compte est en cours de vérification.')}>Acheter</button>
                                        <button className='button-market-gestion' style={{ background: '#666666' }} onClick={() => notifyError('Vous ne pouvez pas effectuer cette action, votre compte est en cours de vérification.')}>Vendre</button>
                                        <button onClick={() => { notifyError('Vous ne pouvez pas effectuer cette action, votre compte est en cours de vérification.') }} className='button-market-gestion' style={{ background: '#666666' }}>Gérer mon portefeuille</button>
                                    </div>
                                }
                            </div>
                        </div>
                        <BuyAssetModal open={openBuy} coin={coin} close={closeModal}></BuyAssetModal>
                        <SellAssetModal open={openSell} coin={coin} close={closeModal}></SellAssetModal>
                    </div>
                    :
                    <div className='coin-wallet marketplace-card' onClick={() => { navigateToWallet() }}>
                        <div className='flex column' style={{ position: 'relative' }}>
                            {
                                pair[0] && <p className='current-asset-price-market value' style={{ color: coin.color.end }}>  ≈  {pair[0].price}€</p>
                            }
                            <div>
                                <div className='flex' style={{ marginLeft: '1rem', marginTop: '1rem' }}>
                                    <img onClick={() => { history.push(`/wallet/${coin.symbol}`) }} style={{ marginRight: '1rem', cursor: 'pointer' }} src={coin.logo} alt='eth icon' />
                                    <div>
                                        <h1 onClick={() => { history.push(`/wallet/${coin.symbol}`) }} style={{ color: coin.color.end, cursor: 'pointer' }}>{coin.symbol}</h1>
                                        <h2 onClick={() => { history.push(`/wallet/${coin.symbol}`) }} style={{ cursor: 'pointer' }}>{coin.name}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-center" style={{ marginTop: '1rem', overflow: 'hidden' }}>
                                <GraphCoinComponent coin={coin} width={1000} height={120} />
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default MarketplaceCardComponent